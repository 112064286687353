import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Caption1, Flex, H4, H6 } from '@components/atoms'
import {
  Image,
  LatestAlbumItem,
  RewardItem,
  RewardItemSkeleton,
} from '@components/molecules'
import { Observer, inject, observer } from 'mobx-react'
import {
  POST_MESSAGES,
  getIsWebApp,
  sendWTAPostMessage,
} from '@utils/postMessage'
import React, { useEffect, useState } from 'react'

import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import Slider from 'react-slick'
import { move_btn_img } from '@images/'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import { useStore } from '@utils/hooks'
import { convertToPricingComma } from '@utils/format'
import { default_album_img, point_img, reward_fill_24px } from '@images/index'
import { colors } from '@colors'
import { handleAlbumImgError } from '@utils/handler'
import AppDownloadPopup from '../Popup/AppDownloadPopup'

const ListBox = styled(Flex)`
  flex-direction: column;
  margin-top: -12px;
`

const ListContent = styled(Slider)`
  width: calc(100% + 40px);
  margin-left: -20px;

  .slick-slide:first-child {
    padding-left: 20px;
  }

  .slick-slide:last-child {
    padding-right: 20px;
  }

  .slick-slide {
    padding: 0 10px;
  }
`

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  width: '100%',
}

const ProgressingRewardList = ({
  progressingRewardList = [],
  setShowDownloadPopup = () => {},
  showIndex = 0,
}) => {
  const item = progressingRewardList?.[Math.floor(showIndex)]

  if (!item) {
    return null
  }

  return (
    <ListBox
      style={
        {
          background: '#ffffff',
        }
      }
    >
      <Flex
        style={
          {
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '11px',
            cursor: 'pointer',
            height: '13px',
            padding: '8px',
            borderRadius: '4px',
            background: '#f7f7f7',
          }
        }
        onClick={
          () => {
            pixelTrack(PIXEL_EVENTS.홈.노래듣기, {})
            const isWebApp = getIsWebApp({ navigator })
            if (isWebApp) {
              sendWTAPostMessage({
                window,
                navigator,
                postMessageData: POST_MESSAGES.WTA_DATA.MOVE_REWARD_LIST,
              })
            }
            else {
              setShowDownloadPopup(true)
            }
          }
        }
      >
        <Flex align={'center'}>
          <Image
            src={reward_fill_24px}
            size={['12px', '12px']}
            style={{ marginRight: '2px' }}
          />
          <Caption1 color={'#949494'} align="left">
            노래 듣고 포인트 적립
          </Caption1>
        </Flex>
        <Caption1 color={'#646464'}>더보기</Caption1>
      </Flex>

      <Flex
        type="raw"
        style={{ width: '100%', cursor: 'pointer' }}
        align={'center'}
        onClick={
          () => {
            pixelTrack(PIXEL_EVENTS.홈.노래듣기_아이템, {
              rewardId: item?._id,
              rewardTitle: item?.trackInfo?.title,
              rewardArtist: item?.trackInfo?.releaseArtistList[0]?.name,
              rewardAlbum: item?.trackInfo?.albumInfo?.title,
              rewardAlbumImage: item?.trackInfo?.albumInfo?.image128Uri,
            })
            const isWebApp = getIsWebApp({ navigator })
            if (isWebApp) {
              const data = {
                code: 'WTA_REWARD_ITEM',
                data: { rewardId: item?._id },
              }
              sendWTAPostMessage({ window, navigator, postMessageData: data })
            }
            else {
              setShowDownloadPopup(true)
            }
          }
        }
      >
        <Image
          src={
            item?.trackInfo?.albumInfo?.image128Uri
              ? item?.trackInfo?.albumInfo?.image128Uri
              : default_album_img
          }
          onError={e => handleAlbumImgError(e)}
          size={['70px', '70px']}
          style={
            {
              borderRadius: '4px',
              border: `solid 0.5px ${colors.inActive}`,
            }
          }
        />
        <Flex type={'column'} style={{ flex: 1, marginLeft: '12px' }}>
          <H6
            align="left"
            style={{ lineHeight: '24px', ...ellipsisStyle }}
            color={'#242424'}
          >
            {item?.trackInfo?.title}
          </H6>
          <H6
            align="left"
            color="#949494"
            style={{ lineHeight: '24px', ...ellipsisStyle }}
          >
            {
item?.trackInfo?.releaseArtistList.map(
  (artist, index) =>
    `${artist.name}
              ${
                !(index === item?.trackInfo?.releaseArtistList?.length - 1)
                  ? ',\u00A0'
                  : ''
              }`,
)
            }
          </H6>
          <H6
            style={
              {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '2px',
                lineHeight: '24px',
              }
            }
            align="left"
          >
            <Image
              src={point_img}
              size={['20px', '20px']}
              style={{ marginRight: '2px' }}
            />
            {`${convertToPricingComma(item?.rewardOncePoint || 0)}`}
          </H6>
        </Flex>
        <img src={move_btn_img} style={{ width: '24px', height: '24px' }} />
      </Flex>
      <Br />
    </ListBox>
  )
}

export default ProgressingRewardList

const Br = styled(Flex)`
  margin-left: 0px;
  width: calc(100% + 0px);
  border-bottom: solid 1px ${colors.whitef4};
  margin-top: 24px;
`

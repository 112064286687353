import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Flex, BodyWrapper, H6, Caption1, Caption2 } from '@components/atoms'
import TextareaAutosize from 'react-textarea-autosize'
import { comment_img, check_empty_img, check_img } from '@images/'
import {
  BurgerMenu,
  CommentCard,
  LikePopup,
  LoginPopup,
  OkCancelPopup,
  ReportPopup,
  useSnackBar,
} from '@components/organisms'
import { colors } from '@colors/'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { useObserver } from 'mobx-react'
import { useStore } from '@utils/hooks'

interface CommmentTemplateProps {
  currentUser: any;
  isLike: boolean;
  dataObjectId: string;
  dataCollectionName: string;
  contentTitle: string;
  offset: Number;
  setOffset: Function;
  commentList: Array<any>;
  commentListCount: Number;
  createComment: Function;
  removeComment: Function;
  _fetchCommentList: Function;
  updateCommentLike: Function;
  reportComment: Function;
  updateLike: Function;
  updateComment: Function;
}

const CommentTemplate = ({
  currentUser,
  isLike,
  dataObjectId,
  dataCollectionName,
  offset,
  setOffset,
  contentTitle,
  commentList,
  commentListCount,
  createComment,
  removeComment,
  _fetchCommentList,
  updateCommentLike,
  reportComment,
  updateLike,
  updateComment,
}: CommmentTemplateProps) => {
  const { authStore } = useStore()

  const [inputValue, setInputValue] = useState('')
  const [isOpen, setIsOpen] = useState(false) // 햄버거
  const [showLoginPopup, setShowLoginPopup] = useState(false) // 로그인 요구 팝업
  const [focusInput, setFocusInput] = useState(false) // 댓글입력창 포커스 유무
  const [isAnonymous, setIsAnonymous] = useState(true) // 댓글 입력 익명 유무

  const [showLikePopup, setShowLikePopup] = useState(false) // 좋아요 알림 팝업

  /**
   * 삭제할 타겟 댓글 ID 저장
   * useState에 저장하면 불필요한 리렌더가 일어나므로, useRef 사용
   * 삭제 팝업 댓글 컴포넌트에서 현(리스트) 컴포넌트로 분리 (댓글당 렌더되던 팝업 => 하나의 팝업 렌더 )
   */
  const commentIdRef = useRef(null)

  const {
    isShowing: likeShowing,
    toggle: likeToggle,
  } = OkCancelPopup.usePopup()

  const {
    isShowing: deleteShowing,
    toggle: deleteToggle,
  } = OkCancelPopup.usePopup()

  const {
    isShowing: reportShowing,
    toggle: reportToggle,
  } = ReportPopup.usePopup()
  const {
    toggleSnackBar: toggleMineLike,
    View: MineLikeSnackBar,
  } = useSnackBar()
  const {
    toggleSnackBar: toggleDuplicateReport,
    View: DuplicateReportSnackBar,
  } = useSnackBar()
  const { toggleSnackBar: toggleReport, View: ReportSnackBar } = useSnackBar()

  const countOffset = () => {
    setOffset(offset + 1)
  }

  const confirmAuth = () => {
    if (!currentUser) {
      setShowLoginPopup(true)
      return
    }

    if (currentUser && !isLike) {
      likeToggle()
    }
  }

  const _createComment = async () => {
    const completed = await createComment({
      dataObjectId,
      dataCollectionName,
      text: inputValue,
      isAnonymous,
    })

    if (completed) {
      setInputValue('')
      _fetchCommentList()
      setIsAnonymous(true)
    }
  }

  const _updateComment = async (text, _isAnonymous) => {
    const completed = await updateComment({
      commentId: commentIdRef.current,
      text,
      isAnonymous: _isAnonymous,
    })

    if (completed) {
      _fetchCommentList()
    }
  }

  const _createReply = async (value, parentId) => {
    const completed = await createComment({
      dataObjectId,
      dataCollectionName,
      text: value,
      parentId,
    })

    return completed
  }

  const targetingComment = async (id, type: 'delete' | 'report' | 'update') => {
    commentIdRef.current = await id
    if (type === 'delete') {
      deleteToggle()
    }
    else if (type === 'report') {
      reportToggle()
    }
    else if (type === 'update') {
    }
  }

  const _removeComment = async () => {
    const deleted = await removeComment(commentIdRef.current, currentUser._id)
    if (deleted) _fetchCommentList()
  }

  const _reportComment = async reason => {
    if (!currentUser) {
      confirmAuth()
      return
    }
    const reported = await reportComment({
      commentId: commentIdRef.current,
      reason,
    })
    if (reported === 'completed') {
      // alert('신고가 접수되었습니다.')
      toggleReport()
      _fetchCommentList()
    }
    else if (reported === 'conflicted') {
      // alert('이미 신고하신 댓글입니다.')
      toggleDuplicateReport()
    }
  }

  const _handlelike = async () => {
    updateLike(
      'add',
      currentUser._id,
      dataObjectId,
      dataCollectionName.substring(0, dataCollectionName.length - 1),
    )

    if (dataCollectionName !== 'albums') {
      setShowLikePopup(true)
    }
  }

  return useObserver(() => (
    <>
      <BurgerMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        pageWrapId={'comment_template'}
        outerContainerId={'App'}
        // pageTitle={album && album.title}
        pageTitle={contentTitle}
        pageId={dataObjectId}
        pageType={
          dataCollectionName.substring(
            0,
            dataCollectionName.length - 1,
          )
        }
      />
      <BodyWrapper
        id="comment_template"
        style={{ paddingTop: 0, paddingBottom: 64, minHeight: '100vh' }}
      >
        <InputBox onClick={confirmAuth}>
          <Flex
            style={{ width: '100%', alignItems: 'flex-start', minHeight: 56 }}
          >
            <CommentIcon>
              <img
                src={comment_img}
                alt="comment_icon"
                style={{ width: 24, height: 24 }}
              />
              <Caption1>{commentListCount || 0}</Caption1>
            </CommentIcon>
            <CustomInput
              maxLength={300}
              placeholder={
                !currentUser
                  ? '로그인을 해주세요'
                  : !isLike
                    ? '좋아요를 눌러주세요'
                    : '댓글을 입력해주세요.'
              }
              disabled={(!currentUser || !isLike) && true}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              maxRows={3}
              minRows={focusInput ? 3 : 1}
              onFocus={() => setFocusInput(true)}
              autoCorrect="off"
              autoCapitalize="off"
            />
            <Flex
              type="column"
              align="center"
              style={{ alignSelf: 'flex-end' }}
            >
              {
                focusInput && (
                  <Flex
                    style={
                      {
                        curosor: 'pointer',
                      }
                    }
                    align="center"
                    onClick={() => setIsAnonymous(!isAnonymous)}
                  >
                    <img
                      src={isAnonymous ? check_img : check_empty_img}
                      alt="check_box"
                      style={{ width: 16, height: 16, marginRight: 2 }}
                    />
                    <Caption1>익명</Caption1>
                  </Flex>
                )
              }
              <SubmitBtn
                onClick={_createComment}
                disabled={
                  !currentUser || !isLike || inputValue === '' ? true : false
                }
              >
                등록
              </SubmitBtn>
            </Flex>
          </Flex>
        </InputBox>
        <InfiniteScroll
          style={{ overflow: 'visible' }}
          dataLength={commentList.length}
          scrollThreshold={0.8}
          next={countOffset}
          hasMore={commentListCount > commentList.length}
          loader={
            <ReactLoading
              type="spin"
              color="black"
              style={
                {
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 50px auto',
                }
              }
            />
          }
          // className="scroll_div"
        >
          {
            commentList && commentListCount && commentListCount > 0 ? (
              commentList.map((comment, index) => (
                <CommentCard
                  key={`${comment._id}_${index}`}
                  item={comment}
                  currentUser={currentUser && currentUser}
                  currentUserId={currentUser && currentUser._id}
                  targetingComment={targetingComment}
                  isLike={isLike}
                  _createReply={_createReply}
                  updateCommentLike={updateCommentLike}
                  setShowLoginPopup={setShowLoginPopup}
                  confirmAuth={confirmAuth}
                  toggleMineLike={toggleMineLike}
                  _updateComment={_updateComment}
                />
              ))
            ) : (
              <Flex
                type="column"
                align="center"
                justify="center"
                style={{ marginTop: 136 }}
              >
                <img
                  src={comment_img}
                  style={{ width: 40, height: 40 }}
                  alt="no_comment_icon"
                />
                <H6>
                댓글이 없습니다
                  <br />
                첫번째 댓글을 남겨주세요
                </H6>
              </Flex>
            )
          }
        </InfiniteScroll>

        <OkCancelPopup.View
          isShowing={likeShowing}
          toggle={likeToggle}
          title={
            `${
              dataCollectionName === 'albums' ? '앨범' : '트랙'
            } 좋아요를 눌러주세요`
          }
          okText="좋아요"
          okColor={colors.primary}
          onOk={_handlelike}
        />
        <OkCancelPopup.View
          isShowing={deleteShowing}
          toggle={deleteToggle}
          title="댓글을 삭제합니다"
          okText="삭제"
          okColor={colors.lightish_red}
          onOk={_removeComment}
        />
        <ReportPopup.View
          isShowing={reportShowing}
          toggle={reportToggle}
          okColor={colors.lightish_red}
          onOk={_reportComment}
        />
        {
          showLoginPopup && (
            <LoginPopup handleClose={() => setShowLoginPopup(false)} />
          )
        }
        {
          showLikePopup && !authStore?.isNeverShowLikeTrackPopup && (
            <LikePopup
              handleClose={() => setShowLikePopup(false)}
              type={'track'}
            />
          )
        }
        <MineLikeSnackBar text={'내 댓글에는 좋아요를 누를 수 없습니다'} />
        <ReportSnackBar text={'신고처리가 완료되었습니다'} />
        <DuplicateReportSnackBar text={'이미 신고한 댓글입니다'} />
      </BodyWrapper>
    </>
  ))
}

export default CommentTemplate

const InputBox = styled(Flex)`
  flex-direction: column;
  margin-left: -20px;
  width: calc(100% + 40px);
  margin-top: 56px;
  height: fit-content;
  background-color: white;
  border: solid 1px #f4f4f4;
`

const CustomInput = styled(TextareaAutosize)`
  font-family: Pretendard-Regular;
  resize: none;
  width: calc(100% - 40px);
  font-size: 14px;
  margin: 7px 0 7px 5px;
  padding: 10px;
  border-radius: unset;
  min-height: 20px;
  line-height: 1.4;

  border: ${props =>
    props.value === '' ? 'solid 1px #f4f4f4' : 'solid 1px #242424'};

  :disabled {
    background-color: #ffffff;
    border: solid 1px #f4f4f4;
  }

  ::placeholder {
    background-color: trasparent;
    color: #949494;
  }
`

const CommentIcon = styled(Flex)`
  flex-direction: column;
  margin-left: 10px;
  width: 50px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
`

const SubmitBtn = styled(Flex)`
  font-size: 12px;
  margin-right: 10px;
  min-width: 48px;
  height: 40px;
  border: ${props =>
    props.disabled ? 'solid 1px #f4f4f4' : 'solid 1px black'};
  color: ${props => (props.disabled ? '#d4d4d4' : 'white')};
  background-color: ${props => (props.disabled ? 'white' : 'black')};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 7px;
`

import React, { useEffect, useState } from 'react'
import { InPlamArticleTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import qs from 'qs'

const InPlamArticlePage = ({ match, location, history }) => {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const fromActivity = location.state && location.state.fromActivity

  const { articleStore, authStore, inplamStore } = useStore()
  const [showLoginPopup, setShowLoginPopup] = useState(false) // 로그인 요구 팝업

  const handleMoveDetail = async item => {
    if (!item?._id) return
    // await history.push(
    //   `/fanspot/${match.params.uniqueName}/${id}?offset=${query.offset}&sortBy=${query.sortBy}`,
    // )

    await history.push({
      pathname: `/fanspot/total/${item?._id}`,
      search: `?offset=${query.offset}&sortBy=${query.sortBy}&category=${item.articleCategoryId}`,
      state: {
        fromActivity: match.params.uniqueName === 'total' ? 'all' : 'normal',
      },
    })
  }

  const handleMoveList = () => {
    history.push(
      `/fanspot/${articleStore?.artistInfo?.uniqueName}?offset=${query.offset}&sortBy=${query.sortBy}&category=${query.category}`,
    )
  }

  const handleArticleLike = () => {
    if (!(authStore.currentUser && authStore.currentUser._id)) {
      setShowLoginPopup(true)
      return
    }

    if (articleStore.articleDetail.isLike) {
      articleStore.updateArticleLike('delete', articleStore.articleDetail)
    }
    else if (!articleStore.articleDetail.isLike) {
      articleStore.updateArticleLike('add', articleStore.articleDetail)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await articleStore.fetchArticleDetail(
        match.params.dataObjectId,
        query.sortBy,
        fromActivity || null,
      )
      if (res === 'PageNotFound') {
        history.push(`/error/fanspot/${match.params.uniqueName}`)
      }
    }
    fetchData()
  }, [location.pathname])

  useEffect(() => {
    if (authStore.currentUser && articleStore.artistInfo) {
      authStore.fetchLike(
        articleStore.artistInfo._id,
        authStore.currentUser._id,
      )
    }
  }, [articleStore.artistInfo, authStore.currentUser])

  return (
    <InPlamArticleTemplate
      handleArticleLike={handleArticleLike}
      removeArticle={articleStore.removeArticle || (() => {})}
      reportArticle={articleStore.reportArticle || (() => {})}
      blockUser={authStore.blockUser || (() => {})}
      article={articleStore.articleDetail || null}
      artistInfo={articleStore.artistInfo || null}
      currentUser={authStore.currentUser || null}
      isLike={articleStore.articleDetail && articleStore.articleDetail.isLike}
      artistIsLike={authStore.likeInfo && authStore.likeInfo.isLike}
      showLoginPopup={showLoginPopup}
      setShowLoginPopup={setShowLoginPopup}
      query={query && query}
      nextArticleList={articleStore.nextArticleList || null}
      inplamFavoritesInfo={articleStore?.inplamFavoritesInfo || null}
      addFavoritesInplam={inplamStore.addFavoritesInplam}
      handleMoveDetail={handleMoveDetail}
      handleMoveList={handleMoveList}
    />
  )
}

export default observer(InPlamArticlePage)

import styled from 'styled-components'

/**
 * 정해놓은 H1 ~ H6, Caption1,2 에 맞지않는 폰트 사이즈 사용시
 * will deprecated
 */
const Typography = styled.span`
  font-family: ${props => props.type && `Pretendard-${props.type}`};
  font-size: ${props => props.size && props.size};
  color: ${props => props.color && props.color};
`

export default Typography

import ReactPixel from 'react-facebook-pixel'
import { twitterTrack } from '@utils/twitter/xPixel'

const PIXEL_ID = '887537865326335'

const advancedMatching = { em: 'test@email.com' } // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}
ReactPixel.init(PIXEL_ID, advancedMatching, options)
console.log('ReactPixel.init')
// ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
// ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.

export const pixelTrack = (event, data) => {
  try {
    const _event = event
    console.log('pixelTrack', _event, data)
    ReactPixel.trackCustom(_event, data)
    // twitterTrack(_event, data)
  } catch (e) {
    console.log('pixelTrack error', e)
  }
}

import React, { useEffect, useState } from 'react'
import {
  BodyWrapper,
  Caption1,
  Caption2,
  Flex,
  H5,
  H6,
} from '@components/atoms'
import { plam_mbti_loading_gif } from '@images/'
import styled, { keyframes } from 'styled-components'
import { MbtiBtn } from '@components/molecules'
import { useHistory } from 'react-router-dom'
import {
  app_icon,
  apple_logo,
  download_qr,
  playstore_logo,
} from '@images/index'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { ConfirmPopup } from '@components/organisms'
import QRCode from 'react-qr-code'
import { ANDROID_STORE_URL, IOS_STORE_URL, STORAGE_URL } from '../../resources'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const QrContainer = styled(Flex)`
  marginBottom: 32px;
  marginTop: 8px

  width: 112px;
  background: #eaeaea;
  border-radius: 8px;

  padding: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  `

const RewardShareTemplate = ({ rewardDetail }) => {
  const DEEP_LINK = `https://plamin.page.link/?link=https://plamin.page.link/reward?${encodeURIComponent(
    `rewardId=${rewardDetail?._id}&screenName=RewardScreen/RewardScreen`,
  )}&apn=com.naivy.plam&isi=6446476110&ibi=com.naivy.plam&efr=1`

  const {
    isShowing: confirmShowing,
    toggle: confirmToggle,
  } = ConfirmPopup.usePopup()

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('android') > -1 || ua.indexOf('ios') > -1) {
      confirmToggle()
    }
  }, [])

  const onPressQRcode = () => {
    window.location.href = DEEP_LINK
  }

  const onPressDownloadButton = ({ type = 'android' }) => {
    window.location.href =
      type === 'android' ? ANDROID_STORE_URL : IOS_STORE_URL
  }

  return (
    <BodyWrapper style={{ padding: 0, height: '100vh' }}>
      <Flex
        type="column"
        style={
          {
            height: '100%',
            alignItems: 'center',
            padding: '24px 28px',
          }
        }
      >
        <Flex
          type={'column'}
          style={
            {
              margin: '0 0 20px',
              padding: '12px 16px',
              border: 'solid 1px #eaeaea',
              width: 'calc(100% - 56px)',
            }
          }
        >
          <Link to={'/'} style={{ margin: 0, padding: 0, height: '24px' }}>
            <Flex type={'row'} align={'center'}>
              <img
                src={app_icon}
                alt="app_icon"
                style={
                  {
                    width: 24,
                    height: 24,
                  // alignSelf: 'center',
                  }
                }
              />
              <Caption1>플램 - 플레이 음감회, PLAM</Caption1>
            </Flex>
          </Link>

          <div
            style={
              {
                height: '1px',
                margin: '12px 0',
                backgroundColor: '#eaeaea',
              }
            }
          />

          <Flex
            type={'row'}
            align={'center'}
            style={{ width: 'calc(100% - 32px)', cursor: 'pointer' }}
            onClick={onPressQRcode}
          >
            <img
              src={`${STORAGE_URL}${rewardDetail?.trackInfo?.albumInfo?.image64Path}`}
              alt="album_image"
              style={
                {
                  width: 40,
                  height: 40,
                // alignSelf: 'center',
                }
              }
            />
            <Flex
              type={'column'}
              style={
                {
                  marginLeft: '8px',
                  width: 'calc(100% - 8px)',
                  gap: '2px',
                }
              }
            >
              <Caption1 align={'left'} style={ellipsisStyle}>
                {rewardDetail?.trackInfo?.title}
              </Caption1>
              <Caption1
                align={'left'}
                type={'Regular'}
                color={'#646464'}
                style={ellipsisStyle}
              >
                {rewardDetail?.trackInfo?.releaseArtistNameList?.join(', ')}
              </Caption1>
            </Flex>
          </Flex>
        </Flex>
        <Caption1
          style={
            {
              whiteSpace: 'pre-line',
            }
          }
          color={'#646464'}
          type={'Regular'}
        >
          {
            '플레이 음감회에 참여하고\n현금처럼 쓸 수 있는 리워드를 받아 보세요!'
          }
        </Caption1>

        <Caption2
          color={'#949494'}
          type={'Regular'}
          style={{ whiteSpace: 'pre-wrap', marginTop: '12px' }}
        >
          {'*해당 기능은 플램 앱을 통해 제공하고 있습니다.'}
        </Caption2>

        <QrContainer
          style={
            {
              marginBottom: '24px',
              marginTop: '20px',
              cursor: 'pointer',
            }
          }
          type={'column'}
          onClick={onPressQRcode}
        >
          {
rewardDetail?._id ? (
  <QRCode
    size={100}
    style={{ background: 'white', padding: 6, borderRadius: 6 }}
    value={DEEP_LINK}
    viewBox={`0 0 256 256`}
    bgColor={'white'}
    level={'L'}
  />
) : (
  <img
    src={download_qr}
    alt="download_qr"
    style={
      {
        width: 110,
        height: 110,
        // margin: '8px 0px 32px 0px',
        alignSelf: 'center',
        borderRadius: '8px',
      }
    }
  />
)
          }
        </QrContainer>

        <Caption1
          onClick={
            () => {
              onPressDownloadButton({ type: 'android' })
            }
          }
          color="#242424"
          style={
            {
              width: '160px',
              height: '40px',
              justifyContent: 'center',
              borderRadius: '4px',
              borderColor: '#eaeaea',
              borderWidth: '1px',
              borderStyle: 'solid',
              alignItems: 'center',
              display: 'flex',
              cursor: 'pointer',
              flexDirection: 'row',
              marginBottom: '12px',
            }
          }
        >
          <img
            src={playstore_logo}
            alt="playstore_down"
            style={
              {
                width: 16,
                height: 16,
                // margin: '8px 0px 32px 0px',
                alignSelf: 'center',
                marginRight: '4px',
              }
            }
          />
          구글 플레이 스토어
        </Caption1>

        <Caption1
          onClick={
            () => {
              onPressDownloadButton({ type: 'ios' })
            }
          }
          color="#242424"
          style={
            {
              width: '160px',
              height: '40px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              borderColor: '#eaeaea',
              borderStyle: 'solid',
              borderWidth: '1px',
              display: 'flex',
              cursor: 'pointer',
              flexDirection: 'row',
              marginBottom: '12px',
            }
          }
        >
          <img
            src={apple_logo}
            alt="apple_down"
            style={
              {
                width: 16,
                height: 16,
                // margin: '8px 0px 32px 0px',
                alignSelf: 'center',
                marginRight: '4px',
              }
            }
          />
          앱 스토어
        </Caption1>

        <Link to={'/'}>
          <Caption1
            color="#242424"
            style={
              {
                width: '160px',
                height: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
                borderColor: '#eaeaea',
                borderStyle: 'solid',
                borderWidth: '1px',
                display: 'flex',
                cursor: 'pointer',
                flexDirection: 'row',
                marginBottom: '24px',
              }
            }
          >
            홈으로 이동
          </Caption1>
        </Link>
      </Flex>
      <ConfirmPopup.View
        isShowing={confirmShowing}
        isShowCancel
        toggle={confirmToggle}
        onConfirm={onPressQRcode}
        title="PLAM 앱 또는 스토어로 이동합니다."
      />
    </BodyWrapper>
  )
}

export default RewardShareTemplate

import 'mobx-react-lite/batchingForReactDom'
import './utils/facebook/pixel'

import { Helmet } from 'react-helmet'
import React, { useState } from 'react'
import { getAnalytics } from 'firebase/analytics'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import TabBar from '@components/organisms/Layout/TabBar'
import { getIsWebApp } from '@utils/postMessage'
import { AppDownloadPopup } from '@components/organisms'
import Routes from './pages/Routes'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyALWN6cNGve5SO5LzsaXPkb41nMagpJP0U',
  authDomain: 'plamin-app.firebaseapp.com',
  projectId: 'plamin-app',
  storageBucket: 'plamin-app.appspot.com',
  messagingSenderId: '313274659037',
  appId: '1:313274659037:web:2437f0ed07353a318deb34',
  measurementId: 'G-9WPNSTDQNQ',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

function App() {
  // window.onload = function () {
  //   setTimeout(function () {
  //     window.scrollTo(0, 0)
  //   }, 1)
  // }

  const isWebApp = getIsWebApp({ navigator })

  const [showDownloadPopup, setShowDownloadPopup] = useState(false) // 앱 다운로드 팝업

  return (
    // 모바일 웹 좌우 스크롤 방지
    <div
      className="App"
      id="App"
      style={{ maxWidth: '420px', overflow: 'hidden' }}
    >
      <Helmet>
        <title>플램 PLAM</title>
        <meta
          name="description"
          content="음악 팬을 위한 공간, 플램 ~ 새로운 음악과 뮤지션을 발견하는 즐거움과 애정하는 아티스트를 덕질하는 즐거움 ~ 새로운 음악과 뮤지션을 발견하는 '플레이 음감회' ~ 팬을 위한 아티스트 커뮤니티 '팬스팟'"
        />
      </Helmet>
      <div style={{ paddingBottom: isWebApp ? 0 : '44px' }}>
        <Routes />
      </div>
      {
        !isWebApp && (
          <TabBar
            isWebApp={isWebApp}
            setShowDownloadPopup={setShowDownloadPopup}
          />
        )
      }
      {
        showDownloadPopup && (
          <AppDownloadPopup handleClose={() => setShowDownloadPopup(false)} />
        )
      }
    </div>
  )
}

export default App

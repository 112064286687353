import { ANDROID_STORE_URL, IOS_STORE_URL, STORAGE_URL } from '@consts/'
import { Caption1, Caption2, Flex, H5, H6 } from '@components/atoms'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { POST_MESSAGES, sendWTAPostMessage } from '@utils'
import React, { useEffect, useState } from 'react'
import {
  alarm_icon,
  burger_menu_img,
  burger_menu_img_black,
  circle_facebook_img,
  circle_google_img,
  circle_kakao_img,
  edit_img_black,
  favorite_img,
  favorite_img_empty,
  no_next_page_img,
  plamin_circle_logo_img,
  plamin_logo_img,
  x_btn_img,
} from '@images'
import {
  arrow_right_outline_24px,
  copy_img,
  fluent_arrow_24,
  home_btn_img_black,
  no_prev_page_img,
  point_img,
  star_fill_24,
} from '@images/index'
import { inject, Observer, observer } from 'mobx-react'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { slide as Menu } from 'react-burger-menu'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { colors } from '@colors'
import copy from 'copy-to-clipboard'
import { getIsWebApp } from '@utils/postMessage'
import { handleArtistImgError } from '@utils/handler'
import { pixelTrack } from '@utils/facebook/pixel'
import styled, { css } from 'styled-components'
import { Image } from '@components/molecules'
import { convertToPricingComma } from '@utils/format'
import SearchArea from './SearchArea'
import {
  AppDownloadPopup,
  ConfirmPopup,
  LinkCopiedPopup,
  ReferralCopiedPopup,
} from '..'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const Header = styled(Flex)`
  position: fixed;
  align-items: center;
  top: 0;
  width: 100%;
  min-width: 320px;
  max-width: 420px;
  height: 56px;
  box-sizing: border-box;
  z-index: 9000;
  background-color: ${props =>
    props.isHome || props.pageWrapId === 'comment_template'
      ? '#ffffff'
      : props.isActive > 152
        ? 'rgba(36, 36, 36, 0.8);'
        : 'transparent'};

  ${props =>
    (props.isSearch === 'ing'
      || props.isSearch === 'on'
      || props.isSearch === 'searched')
    && 'box-shadow: 0 1px 2px 0 rgba(36, 36, 36, 0.16);'}
  overflow: ${props =>
    props.isSearch === 'ing' || props.isSearch === 'searched'
      ? 'visible'
      : props.isSearch === 'on' && 'hidden'};
`

const MenuList = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: calc(100% - 40px);
  padding: 0 20px;
  /* > * {
    margin: 30px 0;
    cursor: pointer;
  }

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  } */
`

const AppDownContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  width: calc(100% - 40px);
  padding: 0 20px;
`

const PageTitle = styled(Flex)`
  top: 10px;
  width: 100%;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
  margin-right: 16px;
  cursor: pointer;
`

const Br = styled(Flex)`
  width: calc(100% - 40px);
  border-top: solid 1px #f4f4f4;
`

const FavoriteInplamList = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;

  img {
    box-sizing: border-box;
    border: 0 solid transparent;
    border-width: 5px;
    /* width: calc(100% / 5);
    height: 100%;
    border-radius: 100%; */
    width: 56px;
    height: 56px;
    border-radius: 28px;
  }
`

const DotBadge = styled.span`
  position: absolute;
  top: 8px;
  left: -8px;
  height: 4px;
  width: 4px;
  background-color: ${colors.lightish_red};
  border-radius: 50%;
  display: inline-block;
`

const PrevButton = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  margin-left: -12px;

  ${({ isArtistPage }) =>
    isArtistPage
    && css`
      filter: invert(1);
    `}
`

const BurgerMenu = ({
  authStore,
  searchStore,
  articleStore,
  inplamStore,
  isHome,
  isOpen,
  setIsOpen,
  pageTitle,
  pageWrapId,
  pageId,
  pageType,
}) => {
  const currentUser = authStore.currentUser || null
  const logout = async () => {
    sendWTAPostMessage({
      window,
      navigator,
      postMessageData: POST_MESSAGES.WTA_DATA.LOGOUT,
    })

    return authStore.logout && authStore.logout()
  }

  const isSearchMode = searchStore.isSearchMode || null
  const updateIsSearchMode = searchStore.updateIsSearchMode || (() => {})
  const isSearched = searchStore.isSearched || null
  const searchKeyword = searchStore.searchKeyword || ''

  const updateIsSearched = searchStore.updateIsSearched || (() => {})
  const updateSearchKeyword = searchStore.updateSearchKeyword || (() => {})

  const autocompleteKeyword = searchStore.autocompleteKeyword || null

  const fetchSearchKeyword = searchStore.fetchSearchKeyword || (() => {})
  const fetchSearchAutoComplete =
    searchStore.fetchSearchAutoComplete || (() => {})
  const updateSearchCount = searchStore.updateSearchCount || (() => {})

  const beforeSearchPage = searchStore.beforeSearchPage || null
  const setBeforeSearchPage = searchStore.setBeforeSearchPage || (() => {})

  const inplamFavoritesInfo = articleStore.inplamFavoritesInfo || null

  const isWebApp = getIsWebApp({ navigator })

  const [position, setPosition] = useState(null)
  const history = useHistory()
  const location = useLocation()

  const bodyScrollLock = require('body-scroll-lock')
  const { disableBodyScroll } = bodyScrollLock
  const { enableBodyScroll } = bodyScrollLock

  // const targetElement = document.querySelector('#App')

  const [isCopied, setIsCopied] = useState(false) // 추천 코드 복사 유무
  const [isCopiedLink, setIsCopiedLink] = useState(false) // 초대 링크 복사 유무

  const [showDownloadPopup, setShowDownloadPopup] = useState(false) // 앱 다운로드 팝업

  const {
    isShowing: confirmShowing,
    toggle: confirmToggle,
  } = ConfirmPopup.usePopup()

  const onScroll = e => {
    // 스크롤 할때마다 state에 scroll한 만큼 scrollTop 값 증가하므로 이를 업데이트해줌,
    // 따라서 스크롤 시점에 따라 특정액션을 추후에 state를 활용하여 구현 가능
    const scrollTop = ('scroll', e.srcElement.scrollingElement.scrollTop)
    setPosition(scrollTop)
  }

  const linkCopy = (isLink = false) => {
    setIsCopied(true)
    if (isLink) {
      setIsCopiedLink(true)
    }
    setTimeout(() => {
      setIsCopied(false)
      if (isLink) {
        setIsCopiedLink(false)
      }
    }, 2500)
  }

  const handleOpen = () => {
    pixelTrack(PIXEL_EVENTS.홈.메뉴, {})
    setIsOpen(true)
    document.querySelector('#bm_menu').style.visibility = 'visible'
  }

  const handleClose = () => {
    setIsOpen(false)
    document.querySelector('#bm_menu').style.visibility = 'hidden'
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    if (isOpen) {
      // overflow:hidden disables the scrolling on a desktop browser
      // position: fixed is additionally needed for mobile devices
      // document.body.setAttribute('style', 'overflow: hidden;')
      // document.body.classList.add('no-scroll')

      // disableBodyScroll(targetElement)
      document.body.style.overflow = 'hidden'

      document.querySelector('#bm_menu').style.visibility = 'visible'
    }
    else {
      document.querySelector('#bm_menu').style.visibility = 'hidden'
      // document.body.classList.remove('no-scroll')

      // enableBodyScroll(targetElement)
      document.body.style.overflow = ''

      // document.body.setAttribute('style', 'overflow: visible;')
    }

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [isOpen])

  const styles = {
    bmBurgerButton: {
      width: '32px',
      height: '32px',
      marginRight: '20px',
    },
    bmBurgerBars: {
      background: '#373a47',
    },
    bmBurgerBarsHover: {
      background: '#a90000',
    },
    bmCrossButton: {
      height: '16px',
      width: '16px',
      right: '16px',
      top: '14px',
    },
    bmCross: {
      background: 'white',
    },
    bmMenuWrap: {
      position: 'absolute',
      height: '100vh',
      visibility: 'hidden',
      top: 0,
    },
    bmMenu: {
      background: 'white',
      padding: '48px 0 0 0',
      overflow: 'auto',
    },
    bmMorphShape: {
      fill: '#373a47',
    },
    bmItemList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    bmItem: {
      display: 'inline-block',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      top: 0,
      position: 'absolute',
      height: '100vh',
      left: 0,
    },
  }

  useEffect(() => {
    return () => {
      // enableBodyScroll(targetElement)
      document.body.style.overflow = ''

      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Header
      id="header"
      isActive={
        pageWrapId === 'inplam_template'
        || pageWrapId === 'comment_template'
        || pageWrapId === 'notification_template'
          ? 153
          : position
      }
      isHome={isHome}
      isSearch={isSearchMode}
      pageWrapId={pageWrapId}
    >
      <PageTitle>
        {
          isHome ? (
            <img
              src={plamin_logo_img}
              style={{ width: '70px', height: '20px', cursor: 'pointer' }}
              alt="home"
              onClick={
                () => {
                  window.location.href = '/'
                  pixelTrack(PIXEL_EVENTS.홈.로고, {})
                }
              }
            />
          ) : (
            <Flex align={'center'}>
              {
              // isWebApp && (
                <PrevButton
                  src={fluent_arrow_24}
                  onClick={() => history.goBack()}
                  isArtistPage={
                    pageWrapId === 'artist_template'
                  || pageWrapId !== 'comment_template'
                  }
                />
              // )
              }
              <H6
                onClick={
                  () =>
                    isHome
                      ? (window.location.href = '/')
                      : (pageWrapId === 'comment_template'
                      || pageWrapId === 'inplam_template')
                    && history.push(`/${pageType}${pageId ? '/' : ''}${pageId}`)
                }
                color={pageWrapId === 'comment_template' ? '#242424' : 'white'}
                align="left"
                style={
                  {
                    ...ellipsisStyle,
                    cursor: 'pointer',
                  }
                }
              >
                {
                  (position > 152
                || pageWrapId === 'inplam_template'
                || pageWrapId === 'comment_template')
                && pageTitle
                }
              </H6>
              {
                pageWrapId !== 'inplam_template'
            && pageWrapId !== 'comment_template'
                  ? false
                  : articleStore?.inplamFavoritesInfo && (
                  <img
                    src={star_fill_24}
                    style={
                      {
                        width: '20px',
                        height: '20px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                      }
                    }
                    alt="Favorites"
                    onClick={
                      () =>
                        inplamStore.removeFavoritesInplam(inplamFavoritesInfo._id)
                    }
                  />
                )
              }
            </Flex>
          )
        }
      </PageTitle>

      {
        !isHome
        && pageWrapId !== 'artist_template'
        && pageWrapId !== 'track_template'
        && pageWrapId !== 'album_template'
        && pageWrapId !== 'comment_template' && (
          <img
            src={home_btn_img_black}
            alt="home_button"
            style={
              {
                widht: '32px',
                height: '32px',
                cursor: 'pointer',
                marginRight:
                pageWrapId === 'notification_template'
                || pageWrapId === 'inplam_template'
                || pageWrapId === 'comment_template'
                  ? '16px'
                  : 0,
              }
            }
            onClick={() => history.push('/')}
          />
        )
      }

      {
        isHome && currentUser && (
          <a href={'/notification'}>
            <Flex
              align="center"
              justify="center"
              style={
                {
                  width: '32px',
                  height: '32px',
                  position: 'relative',
                  marginRight:
                pageWrapId !== 'fanspot_template'
                && !(isHome && pageType === 'fanspot')
                  ? 0
                  : '16px',
                }
              }
            >
              <img src={alarm_icon} alt="burger" width={'24px'} height={'24px'} />
            </Flex>
          </a>
        )
      }
      {
        pageWrapId !== 'artist_template'
        && pageWrapId !== 'track_template'
        && pageWrapId !== 'notification_template'
        && pageWrapId !== 'fanspot_template'
        && pageWrapId !== 'album_template'
        && pageWrapId !== 'inplam_template'
        && pageWrapId !== 'comment_template' && (
          <SearchArea
            isHome={isHome}
            isSearchMode={isSearchMode}
            isSearched={isSearched}
            searchKeyword={searchKeyword}
            updateIsSearchMode={updateIsSearchMode}
            updateIsSearched={updateIsSearched}
            updateSearchKeyword={updateSearchKeyword}
            autocompleteKeyword={autocompleteKeyword}
            fetchSearchKeyword={fetchSearchKeyword}
            fetchSearchAutoComplete={fetchSearchAutoComplete}
            updateSearchCount={updateSearchCount}
            setBeforeSearchPage={setBeforeSearchPage}
            beforeSearchPage={beforeSearchPage}
          />
        )
      }
      {
        currentUser
        && (currentUser.isArticlesNotifications
          || currentUser.isCommentsNotifications) && (
          <DotBadge style={{ right: 50, left: 'auto', top: 12 }} />
        )
      }
      <Menu
        right
        id="bm_menu"
        styles={styles}
        slide
        isOpen={isOpen}
        width={'100%'}
        // pageWrapId={pageWrapId}
        // outerContainerId={'header'}
        // onStateChange={() => setIsOpen(!isOpen)}
        onStateChange={
          state => {
            return state.isOpen
          }
        }
        onOpen={handleOpen}
        onClose={handleClose}
        customBurgerIcon={
          <Flex align={'center'} justify={'center'}>
            <img
              src={
                isHome || pageWrapId === 'comment_template'
                  ? burger_menu_img_black
                  : burger_menu_img
              }
              alt="burger"
              style={
                {
                  width: '24px',
                  height: '24px',
                }
              }
              // width={'24px'}
              // height={'24px'}
            />
          </Flex>
        }
        customCrossIcon={<img src={x_btn_img} alt="close_btn" />}
        //   burgerButtonClassName={'my-class'}
        //   burgerBarClassName={'my-class'}
        //   crossButtonClassName={'my-class'}
        //   crossClassName={'my-class'}
        //   menuClassName={'my-class'}
        //   morphShapeClassName={'my-class'}
        //   itemListClassName={'my-class'}
        //   overlayClassName={'my-class'}
        bodyClassName={'app'}
      >
        {
          currentUser ? (
            <>
              <img
                src={
                  currentUser.image128Uri
                    ? currentUser.image128Uri
                    : currentUser.socialType === 'facebook'
                      ? circle_facebook_img
                      : currentUser.socialType === 'kakao'
                        ? circle_kakao_img
                        : currentUser.socialType === 'google'
                          ? circle_google_img
                          : currentUser.type === 'artist'
                            ? currentUser.artistInfo.image128Uri
                            : null
                }
                alt="logo"
                style={
                  {
                    borderRadius: 28,
                    width: '56px',
                    height: '56px',
                  }
                }
              />
              <Flex
                style={{ margin: '16px 0 32px 0', position: 'relative' }}
                align="center"
                onClick={
                  () => {
                    if (currentUser.type !== 'artist')
                      history.push('/activity/update')
                  }
                }
              >
                <H5>
                  {
                    currentUser.type === 'artist'
                      ? currentUser.artistInfo.name
                      : currentUser && currentUser.nickname
                        ? currentUser.nickname
                        : currentUser.name
                  }
                </H5>
                {
                  currentUser.type !== 'artist' && (
                    <img
                      src={edit_img_black}
                      style={
                        {
                          width: 16,
                          height: 16,
                          position: 'absolute',
                          right: '-20px',
                        }
                      }
                      alt="edit_icon"
                    />
                  )
                }
              </Flex>
            </>
          ) : (
            <Link to="/login" style={{ textAlign: 'center' }}>
              <img
                src={plamin_circle_logo_img}
                alt="logo"
                style={
                  {
                    width: '56px',
                    height: '56px',
                  }
                }
              />
              <H5 style={{ margin: '16px 0 32px 0' }}>로그인을 해주세요</H5>
            </Link>
          )
        }
        <Observer>
          {
            () => {
              if (authStore?.jsonWebToken) {
                return (
                  <Flex
                    type={'column'}
                    style={{ width: 'calc(100% - 40px)', marginBottom: '16px' }}
                  >
                    <UserCurrentPointBox
                      type={'row'}
                      justify={'space-between'}
                      align={'center'}
                      onClick={
                        () => {
                          if (isWebApp) {
                            sendWTAPostMessage({
                              window,
                              navigator,
                              postMessageData:
                            POST_MESSAGES.WTA_DATA.MOVE_POINT_HISTORY,
                            })
                          }
                          else {
                            setShowDownloadPopup(true)
                          }
                        }
                      }
                    >
                      <Flex align={'center'}>
                        <Image
                          src={point_img}
                          size={['20px', '20px']}
                          style={{ marginRight: '4px' }}
                        />
                        <H6
                          style={
                            {
                              display: 'flex',
                              flexDirection: 'row',
                              whiteSpace: 'pre-wrap',
                            }
                          }
                          color={'#646464'}
                        >
                          {`보유 포인트`}
                        </H6>
                      </Flex>
                      <Flex align={'center'}>
                        <H6 type={'Bold'}>
                          {
                            ` ${convertToPricingComma(
                          currentUser?.currentPoint || 0,
                            )}P`
                          }
                        </H6>
                        <img
                          src={arrow_right_outline_24px}
                          alt={'move_point_btn'}
                          style={
                            {
                              width: '20px',
                              height: '20px',
                              marginLeft: '2px',
                            }
                          }
                        />
                      </Flex>
                    </UserCurrentPointBox>
                  </Flex>
                )
              }
              return null
            }
          }
        </Observer>
        {
          currentUser && (
            <Flex
              type={'column'}
              style={{ width: 'calc(100% - 40px)', marginBottom: '16px' }}
            >
              <ReferralContainer
                style={{ display: 'flex' }}
                align={'center'}
                justify={'space-between'}
              >
                <Flex align={'center'}>
                  <Caption2 type={'Regular'} color={'#646464'}>
                  내 추천 코드
                  </Caption2>
                  <CopyToClipboard
                    style={
                      {
                        cursor: 'pointer',
                        marginLeft: 10,
                      }
                    }
                    text={`추천 코드 : ${currentUser?.referralCode}`}
                    onCopy={
                      (text, result) => {
                        linkCopy()
                      }
                    }
                  >
                    <Flex align={'center'}>
                      <Caption1 color={'#242424'}>
                        {currentUser?.referralCode}
                      </Caption1>
                      <img
                        src={copy_img}
                        alt="copy_img"
                        style={{ width: 12, height: 12, marginLeft: 4 }}
                      />
                    </Flex>
                  </CopyToClipboard>
                </Flex>
                <ShareButton
                  onClick={
                    () => {
                      const bodyText = `[플램 - 플레이 음감회, PLAM]\n음악을 감상하면 돈이 벌리는 음악 앱테크 리워드 서비스, 플레이 음감회로 여러분을 초대합니다.\n지금 다운로드 받고, 새로운 음악을 발견하며 돈도 벌어보세요.\n플레이 음감회 앱(https://plam.in/download)\n추천인 코드: ${currentUser?.referralCode}`
                      if (typeof navigator.share !== 'undefined') {
                        navigator.share({
                          title: '',
                          text: bodyText,
                          // url: 'https://plam.in/download',
                        })
                      }
                      else {
                        const result = copy(`${bodyText}`)
                        if (result) {
                          linkCopy(true)
                        }
                      }
                    }
                  }
                >
                공유하기
                </ShareButton>
              </ReferralContainer>

              <Caption2 type={'Regular'} color={'#646464'} align={'left'}>
              *내 추천인 코드를 신규 회원이 입력하고 플레이 음감회에 참여 시
              1,000 포인트가 적립됩니다.
              </Caption2>
            </Flex>
          )
        }
        <Br style={{ marginBottom: 4 }} />

        <MenuList>
          <Flex
            style={
              {
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '4px 0',
                height: 48,
                cursor: 'pointer',
              }
            }
            onClick={
              () => {
                !(
                  window.location.href === 'https://plam.in'
                || window.location.href.match(
                  /^http:\/\/localhost\:[0-9][0-9][0-9][0-9][0-9]?.?$/,
                )
                )
                  ? (window.location.href = window.location.href.match(
                    /^http:\/\/localhost\:[0-9][0-9][0-9][0-9][0-9]?.?/,
                  )
                    ? window.location.href
                      ?.split('/')
                      ?.slice(0, 3)
                      .join('/') || 'https://plam.in'
                    : 'https://plam.in')
                  : handleClose()
              }
            }
          >
            <H6 align="left">홈</H6>
            <img
              src={arrow_right_outline_24px}
              style={{ width: 16, height: 16 }}
              alt="move_icon"
            />
          </Flex>
          {
            currentUser && (
              <>
                <Flex
                  style={
                    {
                      position: 'relative',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '4px 0',
                      height: 48,
                      backgroundColor: 'white',
                      cursor: 'pointer',
                    }
                  }
                  onClick={
                    () => {
                      if (location.pathname !== '/activity/comments') {
                        history.push('/activity/comments')
                        setTimeout(() => {
                          // handleClose()
                        }, 200)
                      }
                      else {
                        handleClose()
                      }
                    }
                  }
                >
                  {
                    currentUser && currentUser.isCommentsNotifications && (
                      <DotBadge />
                    )
                  }
                  <H6 align="left">댓글 단 글</H6>
                  <img
                    src={arrow_right_outline_24px}
                    style={{ width: 16, height: 16 }}
                    alt="move_icon"
                  />
                </Flex>
                <Flex
                  style={
                    {
                      position: 'relative',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '4px 0',
                      height: 48,
                      backgroundColor: 'white',
                      cursor: 'pointer',
                    }
                  }
                  onClick={
                    () => {
                      if (location.pathname !== '/activity/articles') {
                        history.push('/activity/articles')
                        setTimeout(() => {
                          // handleClose()
                        }, 200)
                      }
                      else {
                        handleClose()
                      }
                    }
                  }
                >
                  {
                    currentUser && currentUser.isArticlesNotifications && (
                      <DotBadge />
                    )
                  }
                  <H6 align="left">작성한 글</H6>
                  <img
                    src={arrow_right_outline_24px}
                    style={{ width: 16, height: 16 }}
                    alt="move_icon"
                  />
                </Flex>
              </>
            )
          }
          {/* {
            currentUser && (
              <Flex type="column" style={{ margin: '20px 0' }}>
                <H6 style={{ margin: '0px 0 16px 0' }} align="left">
                나의 팬스팟
                </H6>
                <FavoriteInplamList>
                  {
                    currentUser
                  && currentUser.inplamFavorites
                  && currentUser.inplamFavorites.length > 0
                  && currentUser.inplamFavorites.map(item => (
                    <img
                      key={item._id}
                      src={STORAGE_URL + item.image64Path}
                      alt="favorite_inplam"
                      style={{ cursor: 'pointer' }}
                      onClick={
                        () => {
                          history.push(`/fanspot/${item.uniqueName}`)
                          setTimeout(() => {
                            handleClose()
                          }, 200)
                        }
                      }
                      onError={handleArtistImgError}
                    />
                  ))
                  }
                </FavoriteInplamList>
              </Flex>
            )
          } */}
          <a
            href="https://plam.kr/marketing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Flex
              style={
                {
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '4px 0',
                  height: 48,
                }
              }
            >
              <H6 align="left">광고 문의</H6>
              <img
                src={arrow_right_outline_24px}
                style={{ width: 16, height: 16 }}
                alt="move_icon"
              />
            </Flex>
          </a>
          <Link to="/privacy/termsOfUse">
            <Flex
              style={
                {
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '4px 0',
                  height: 48,
                }
              }
            >
              <H6 align="left">이용약관</H6>
              <img
                src={arrow_right_outline_24px}
                style={{ width: 16, height: 16 }}
                alt="move_icon"
              />
            </Flex>
          </Link>
          <Link to="/privacy/policy">
            <Flex
              style={
                {
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '4px 0',
                  height: 48,
                }
              }
            >
              <H6 align="left">개인정보처리방침</H6>
              <img
                src={arrow_right_outline_24px}
                style={{ width: 16, height: 16 }}
                alt="move_icon"
              />
            </Flex>
          </Link>
          {/* <Br style={{ width: '100%', marginTop: 4 }} /> */}
          {
            currentUser && (
              <>
                {/* <Br style={{ width: '100%', margin: '24px 0 22px 0' }} /> */}
                <Button onClick={logout}>로그아웃</Button>
                {
                  !currentUser?.artistId && (
                    <H6
                      align="left"
                      style={{ marginTop: 34, cursor: 'pointer' }}
                      color="#bfbfbf"
                      type="medium"
                      onClick={() => history.push('/auth/secession')}
                    >
                  회원탈퇴
                    </H6>
                  )
                }
              </>
            )
          }
        </MenuList>
        <Flex
          type={'column'}
          style={
            {
              flex: 1,
              width: '100%',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              display: 'flex',
            }
          }
        >
          {
            !isWebApp && (
              <AppDownContainer
                style={
                  {
                    display: 'flex',
                    backgroundColor: '#242424',
                    height: '72px',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    justifyContent: 'space-between',
                  }
                }
              >
                <Flex type={'column'} style={{ display: 'flex' }}>
                  <H6
                    align={'left'}
                    type={'Regular'}
                    style={
                      {
                        color: '#eaeaea',
                        display: 'flex',
                        whiteSpace: 'break-spaces',
                        fontWeight: 'normal',
                      }
                    }
                  >
                  앱 설치하고
                    {' '}
                    <H6
                      align={'left'}
                      type={'Medium'}
                      style={{ color: '#ffffff', fontWeight: 500 }}
                    >
                    쉽고 편리하게!
                    </H6>
                  </H6>
                  <H6
                    align={'left'}
                    type={'Regular'}
                    style={{ color: '#eaeaea', fontWeight: 'normal' }}
                  >
                  플램 서비스를 활용 해보세요.
                  </H6>
                </Flex>

                <DownButton
                  onClick={
                    () => {
                      const ua = navigator.userAgent.toLowerCase()

                      window.location.href =
                    ua.indexOf('android') > -1
                      ? ANDROID_STORE_URL
                      : IOS_STORE_URL
                    }
                  }
                >
                앱 다운로드
                </DownButton>
              </AppDownContainer>
            )
          }
        </Flex>
        {isCopied && <ReferralCopiedPopup isLink={isCopiedLink} />}
      </Menu>

      <ConfirmPopup.View
        isShowing={confirmShowing}
        toggle={confirmToggle}
        title="즐겨찾는 팬스팟은 5개까지 추가 가능합니다"
      />
      {
        showDownloadPopup && (
          <AppDownloadPopup handleClose={() => setShowDownloadPopup(false)} />
        )
      }
    </Header>
  )
}
export default inject(
  'authStore',
  'searchStore',
  'articleStore',
  'inplamStore',
)(observer(BurgerMenu))

const Button = styled.button`
  width: 100%;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaeaea;
  background-color: white;

  font-size: 14px;
  font-weight: 500;
  color: #949494;
`

const DownButton = styled.button`
  width: 84px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: '#f4f4f4';
  border: 0px;
  cursor: pointer;

  font-size: 12px;
  font-weight: 500;
  color: #242424;
`

const ShareButton = styled.button`
  width: 64px;
  height: 28px;
  border-radius: 16px;
  background-color: #242424;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border 0;

  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
`

const ReferralContainer = styled(Flex)`
  height: 48px;
  margin: 0 0 8px;
  padding: 0 12px;
  border: solid 1px #f4f4f4;
`

const UserCurrentPointBox = styled(Flex)`
  padding: 14px 16px;

  height: 48px;
  overflow: hidden;
  box-sizing: border-box;

  border-radius: 6px;
  border: solid 1px #f4f4f4;
  background: #ffffff;
  /* margin: 16px 20px; */
  cursor: pointer;
`

import { Image } from '@components/molecules'
import { home_rounded_black, reward_fill_24px_gray } from '@images/index'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 420px;
  height: 44px;
  border-top: 1px solid #eee;
  background: #fff;
  z-index: 1000;

  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Tab = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export default function TabBar({ setShowDownloadPopup }) {
  return (
    <Wrapper>
      <Tab
        onClick={
          () => {
            window.location.href = '/'
          }
        }
      >
        <Image src={home_rounded_black} size={['24px', '24px']} />
      </Tab>
      <Tab
        onClick={
          () => {
            setShowDownloadPopup(true)
          }
        }
      >
        <Image src={reward_fill_24px_gray} size={['24px', '24px']} />
      </Tab>
    </Wrapper>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import { Title_img } from '@images'
import { play_img } from '@images/index'
import { handleTrackImgError } from '@utils/handler'
import { Flex, H6, Typography } from '../../atoms'
import Image from './Image'

const onelineStyle = {
  display: 'inline-block',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  // width: '280px',
  flex: 1,
}

const TrackItem = ({ item, index, artistName, lang }) => {
  return (
    // <Link to={'/track/' + item._id}>
    <Link
      to={
        `/track/${
          item.customUrlInfo ? item.customUrlInfo.autoUrl : item._id
        }`
      }
    >
      <Flex style={{ paddingBottom: '24px' }}>
        <Flex
          style={
            {
              paddingRight: '21px',
              justifyContent: 'center',
              alignItems: 'center',
            }
          }
        >
          <Typography size="16px" color="#646464">
            {item && item.no}
          </Typography>
        </Flex>
        <Flex
          type="column"
          justify={'center'}
          style={
            {
              flex: 1,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              wordWrap: 'break-word',
            }
          }
        >
          <Flex
            style={
              {
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '2px',
              }
            }
          >
            {
              item && item.isTitle && (
                <img
                  src={Title_img}
                  alt="title"
                  style={{ width: '34px', height: '16px', paddingRight: '6px' }}
                />
              )
            }
            <H6 style={onelineStyle} type="Medium" size="14px" align={'left'}>
              {lang === 'ko' ? item?.title : item?.titleEng || item?.title}
            </H6>
          </Flex>
          <Typography
            type="Medium"
            size="12px"
            color="#949494"
            style={{ ...onelineStyle, flex: 'none' }}
          >
            {
              item
              && item.releaseArtistList
              && item.releaseArtistList.map(artist => artist['name']).join(', ')
            }
          </Typography>
        </Flex>
        <Flex>
          <Link
            to={
              {
                pathname: `/track/${
                  item.customUrlInfo ? item.customUrlInfo.autoUrl : item._id
                }`,
                state: {
                  isPlayFirstYoutube: false,
                },
              }
            }
            style={{ padding: '12px', paddingRight: 0 }}
          >
            <img
              src={play_img}
              alt="play"
              style={{ width: '16px', height: '16px' }}
            />
          </Link>
        </Flex>
      </Flex>
    </Link>
  )
}

export default TrackItem

import React from 'react'
import { SUPPORTED_LANGUAGES } from '@consts'
import { Redirect, useParams } from 'react-router-dom'

const LanguageLayout = ({ children }) => {
  const { lang } = useParams()

  if (!SUPPORTED_LANGUAGES.includes(lang)) {
    return <Redirect to={`/en${window.location.pathname}`} />
  }

  return <div lang={lang}>{children}</div>
}

export default LanguageLayout

import React from 'react'
import styled from 'styled-components'
import { Flex } from '@components/atoms'

const Content = styled(Flex)`
  flex-direction: column;
`

const Title = styled.span`
  font-family: Pretendard-Bold;
  font-size: 14px;
  width: 100%;
  margin: 16px 0 17px 0;
`

const List = styled.p`
  font-family: Pretendard-Regular;
  font-size: 12px;
  width: 100%;
  line-height: 1.5;
  margin: 1px 0 8px 0;
`

const PrivacyInfo = () => {
  return (
    <Content>
      <List>
        플램(PLAM) 은 정보통신망 이용촉진 및 정보보호 등에 개인정보처리방침을
        정하여 고객 권익 보호에 최선을 다하고 있습니다. 이 개인정보처리방침은
        플램(PLAM) 에서 수집하는 정보의 유형과 수집 방법, 정보를 수집하는 이유,
        개인정보 보호 설정 등에 대한 이해를 돕기 위한 것입니다
      </List>
      <Title>
        본 개인정보처리방침에서 사용하는 주요한 용어의 정의는 다음과 같습니다.
      </Title>
      <List>
        ·서비스: 플램(PLAM) 솔루션이 제공하는 인터넷 상의 모든 서비스
        <br />
        ·회원: 솔루션과 서비스 이용 계약을 체결하고 아이디를 부여 받아 솔루션이
        제공하는 서비스를 이용하는 자
        <br />
        회원등급: 솔루션이 제공하는 서비스에 대해 특정 기능 접근 여부 등의
        서비스 이용 경험에서 차이를 갖고 있는 솔루션 내의 회원 분류 체계
        <br />
        ·콘텐츠: 오디오 또는 서비스를 통하여 기고할 수 있는 텍스트, 소프트웨어,
        스크립트, 그래픽, 사진, 이미지 파일, 사운드, 음악, 비디오, 선곡 리스트,
        시청각 혼합, 상호작용 내용과 기타 자료
      </List>
      <Title>발효일: 2023년 01월 01일</Title>
      <Title>플램(PLAM) 의 정책이 적용되는 형태는 아래와 같습니다.</Title>
      <List>플램 WEB, 플램 APP(Android, Ios), 사이트, 기기</List>
      <Title>플램(PLAM) 에서 수집하는 정보의 유형</Title>
      <List>
        플램(PLAM) 은 모든 회원에게 더 나은 서비스를 제공하기 위해 회원의 언어와
        같은 기본적인 정보와 회원이 가장 유용하다고 생각할 콘텐츠 등과 같은
        복합적인 정보를 수집합니다.
        <br />
        수집하는 정보와 그 정보가 이용되는 방식은 사용자가 서비스를 어떻게
        이용하고 개인정보 보호 설정을 어떻게 관리하는지에 따라 다릅니다.
      </List>
      <Title>회원이 생성하거나 제공하는 정보</Title>
      <List>
        ·필수 제공 정보
        <br />
        회원은 계정을 만들 때 아이디 (연동 로그인 계정 시 Kakao, Google,
        Apple아이디), , 이메일 주소, 비밀번호, 전화번호, 성별, 생년월일 등을
        포함한 개인정보를 플램(PLAM) 에 제공합니다.
        <br />
        <br />
        ·선택 제공 정보
        <br />
        계정에 성명, 프로필 이미지, 닉네임, 주소 등을 추가하도록 선택할 수
        있습니다. 계정에 로그인하지 않더라도, 서비스 업데이트를 받기 위해 이메일
        주소를 제공하는 것과 같이 정보를 제공하도록 선택할 수 있습니다.
        <br />
        <br />
        ·직접 생성한 콘텐츠 정보
        <br />
        플램(PLAM) 은 회원이 서비스를 이용하면서 생성하거나 다른 사람에게 받는
        콘텐츠를 수집합니다. 여기에는 회원이 저장하는 오디오, 이미지, 텍스트,
        댓글 등이 포함됩니다.
      </List>
      <Title>회원이 서비스를 이용할 때 플램(PLAM) 이 수집하는 정보</Title>
      <List>
        ·회원의 활동
        <br />
        서비스 상의 회원 활동 정보를 수집하여 좋아할 만한 콘텐츠를 추천하는
        목적으로 활용합니다. 회원은 플램(PLAM) 계정에 저장된 활동 정보를
        확인하고 관리할 수 있습니다. 수집하는 활동 정보에는 다음이 포함될 수
        있습니다. 
        {' '}
        <br />
        <br />
        ·회원의 앱, 브라우저, 기기
        <br />
        회원이 플램(PLAM) 서비스에 액세스할 때 사용하는 앱, 브라우저, 기기에
        대한 정보를 수집합니다. 이 정보를 이용하여 자동 업데이트 등을 제공할 수
        있습니다. 수집하는 정보에는 고유 식별자, 브라우저 유형 및 설정, 기기
        유형 및 설정, 운영체제, 통신사명과 전화번호를 포함한 모바일 네트워크
        정보, 애플리케이션 버전 번호가 포함될 수 있습니다. 또한 IP주소, 비정상
        종료 보고서, 시스템 활동, 요청 날짜와 시간, 리퍼러 URL 등 사용자의 앱,
        브라우저, 기기와 서비스의 플램(PLAM) 상호작용 등에 대한 정보를 수집할 수
        있습니다.
        <br />
        <br />
        ·회원의 상태 정보 (위치, 건강, 기상, 온도 등)
        <br />
        서비스를 이용할 때 사용자 위치에 관한 정보를 수집합니다. 플램(PLAM) 에서
        수집하는 위치 데이터의 유형은 기기 및 계정 설정에 따라 부분적으로
        달라집니다. 위치 정확도는 다음과 같은 여러 요인에 의해 결정됩니다. GPS,
        IP 주소, 기기의 센서 데이터, Wi-Fi 액세스 포인트, 기지국, 블루투스 지원
        기기, 건강정보 등 사용자의 상태 및 사용자의 기기 주변 사물에 대한 정보.
        <br />
        <br />
        ·업무 처리 과정에서 자동 생성, 추가 수집되는 정보
        <br />
        서비스 이용과정이나 서비스 제공 업무 처리 과정에서 다음과 같은 정보들이
        자동으로 생성되거나 추가로 수집될 수 있습니다. IP 주소, 쿠키, 접속로그,
        사용 일시, 서비스 이용 기록, 불량 이용 기록, 결제 기록.
      </List>
      <Title>특정 서비스의 이용 시 추가적으로 수집되는 정보</Title>
      <List>
        ·회원이 플램(PLAM) 에 일반 문의를 남기거나 알림(통보) 대상 추가 및
        회원등급 변경 시에 개인정보 수집에 대한 별도 동의를 구한 후 성명, 이메일
        주소, 휴대폰 번호 등의 추가적인 정보가 수집될 수 있습니다.
        <br />
        ·회원이 플램(PLAM) 에서 서비스를 제공받기 위해 제공하는 음악 감상
        데이터, 저작물 정산 데이터, 회원의 타 이용 서비스의 개인정보를 제외한
        데이터 (URI 등).
      </List>
      <span
        style={
          {
            fontFamily: 'Pretendard-Bold',
            fontSize: '14px',
            width: '100%',
          }
        }
      >
        개인정보 수집 방법
      </span>
      <Title>플램(PLAM) 은 다음과 같은 방법으로 개인정보를 수집합니다.</Title>
      <List>
        ·어플리케이션, 홈페이지 수집, 서면,  게시판, 이메일, 오프라인
        수집(이벤트 응모, 세미나 참석 등)
        <br />
        ·로그 분석 프로그램을 통한 생성정보 수집
        <br />
        ·‘쿠키(cookie)’에 의한 정보 수집
      </List>
      <span
        style={
          {
            fontFamily: 'Pretendard-Bold',
            fontSize: '14px',
            width: '100%',
          }
        }
      >
        정보를 수집하는 이유 및 목적
      </span>
      <List>
        수집된 정보를 사용하여 더 나은 서비스를 구축합니다. 플램(PLAM) 은
        회원들의 개인정보를 다음에서 고지하는 범위 내에서 사용하며, 고객의 사전
        동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 회원의 개인정보를
        외부에 공개하지 않습니다. 고객들이 사전에 동의한 경우, 법령의 규정에
        의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의
        요구가 있는 경우에는 예외로 합니다.
      </List>
      <Title> 서비스 제공</Title>
      <List>
        ·회원이 검색하는 단어를 처리하여 결과를 제공하거나 다른 회원과의 콘텐츠
        공유를 돕는 등의 서비스 제공을 위해 회원 정보를 이용합니다.
      </List>
      <Title>서비스 유지 및 개선</Title>
      <List>
        ·문제 발생 시 원인을 추적하거나 회원이 신고하는 문제를 해결하는 등
        서비스 정상 운영을 위해 회원 정보를 이용합니다.
      </List>
      <Title>새 서비스 개발</Title>
      <List>
        ·기존 서비스에서 수집하는 정보를 이용하여 새 서비스를 개발합니다.
      </List>
      <Title>맞춤 서비스 제공</Title>
      <List>
        ·수집하는 정보를 이용하여 추천, 맞춤 콘텐츠 및 검색결과를 제공하는 등
        사용자를 위해 서비스를 맞춤 설정합니다.
      </List>
      <Title>실적 측정</Title>
      <List>
        ·플램(PLAM) 서비스가 어떻게 이용되는지 분석하고 측정하는데 데이터를
        이용합니다.
      </List>
      <Title>사용자와 커뮤니케이션</Title>
      <List>
        ·이메일 주소 등 수집 정보를 이용하여 회원과 직접 상호 작용합니다. 이메일
        주소와 같은 정보는 불만처리 등의 민원을 처리, 이벤트 및 광고성 정보 제공
        및 참여기회 제공 등을 위한 소통 창구로 이용됩니다.
      </List>
      <Title>플램(PLAM)과 회원, 그리고 대중을 보호</Title>
      <List>
        ·서비스의 안정성과 신뢰성을 개선하기 위해 정보를 이용합니다. 여기에는
        회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별,
        불량회원의 부정 이용 방지와 비인가 사용방지, 가입의사 확인, 가입 및
        가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존,
        고지사항 전달 등이 포함됩니다.
      </List>
      <Title>개인정보 보호 설정</Title>
      <List>
        플램(PLAM) 서비스 내에서 플램(PLAM) 에게 공유되는 정보와 다른 회원에게
        표시되는 내 정보를 관리할 수 있습니다.
      </List>
      <Title>개인 정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</Title>
      <List>
        플램(PLAM) 은 쿠키를 통해 회원들의 이용형태, 인기 검색어, 고객 규모 등을
        파악합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 회원의
        브라우저에게 보내는 아주 작은 텍스트 파일로 회원 컴퓨터의 하드디스크에
        저장됩니다. 이후 회원이 서비스를 활용할 때 하드 디스크에 저장되어 있는
        쿠키의 내용을 읽어 회원의 환경설정을 유지하고 맞춤화된 서비스를 제공하기
        위해 이용됩니다. 쿠키는 개인을 식별하는 정보를 자동적/능동적으로
        수집하지 않으며, 회원은 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할
        수 있습니다. 회원은 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
        허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의
        저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 일부
        서비스는 이용에 어려움이 있을 수 있습니다.
      </List>
      <Title>정보 관리 및 업데이트</Title>
      <List>
        ·회원 정보 관리
        <br />
        회원은 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 수정할 수
        있습니다. 회원의 개인정보에 대한 열람 또는 수정을 하고자 할 경우에는
        솔루션의 서비스 종류에 따른 회원정보 수정에 해당하는 기능을 통해 본인
        확인 절차를 거친 후 직접 열람 또는 수정하거나, 개인정보 관련 담당자에게
        이메일로 연락하면 지체 없이 수정할 수 있습니다. 만일 회원의 대리인이
        방문하여 열람 또는 정정을 요구하는 경우에는 회원의 진정한 대리인인지
        여부를 확인하기위해, 대리관계를 나타내는 증표를 제시하도록 요구할 수
        있습니다. 회원이 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을
        완료하기 전까지 개인정보를 이용 또는 제공하지 않습니다. 또한 프로필
        이미지, 닉네임 등 선택 정보를 추가 기입할 수 있습니다.
        <br />
        ·활동 기록 관리
        <br />
        회원은 검색 기록 등의 활동 내용을 관리할 수 있습니다. 서비스가 정보에
        대한 권리를 보유하고 있지 않은 내용의 일부 또는 전부를 삭제할 수
        있습니다
      </List>
      <span
        style={
          {
            fontFamily: 'Pretendard-Bold',
            fontSize: '14px',
            width: '100%',
          }
        }
      >
        개인정보의 보유, 이용기간 및 파기 절차
      </span>
      <List>
        회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체
        없이 파기합니다. 단, 다음과 같이 데이터의 유형에 따라 보관되는 기간이
        다릅니다.
      </List>
      <Title>사용자가 삭제할 때까지 보관되는 정보</Title>
      <List>
        ·회원 가입 시 기입하는 필수 개인 정보
        <br />
        ·회원의 활동 내역
        <br />
        ·회원이 업로드한 콘텐츠
        <br />
        ·회원의 계정
      </List>
      <Title>특정 기간이 지나면 만료되는 데이터</Title>
      <List>
        ·회원이 검색하는 단어를 처리하여 결과를 제공하거나 다른 회원과의 콘텐츠
        공유를 돕는 등의 서비스 제공을 위해 회원 정보를 이용합니다.
      </List>
      <Title>계정을 삭제할 때까지 보관되는 정보</Title>
      <List>
        ·회원이 플램(PLAM) 이 제공하는 기능과 어떻게 상호작용하는지, 어떻게 하면
        플램(PLAM) 서비스를 개선할 수 있을지 이해하는 데 도움이 되는 일부
        데이터는 회원이 계정을 삭제할 때까지 보관됩니다.
      </List>
      <Title>서비스가 권리를 보유하고 있는 정보</Title>
      <List>
        ·회원이 동의한 이용약관에 따라 플램(PLAM)의 서비스를 이용하기 위해
        업로드하거나 게시하여 서비스가 권리를 보유한 개인정보를 제외한 데이터는
        서비스가 보유하며, 서비스가 종료될 때까지 보관됩니다.
      </List>
      <List>
        회원이 플램(PLAM) 계정을 삭제하면 위 약관의 범위에 따라 플램(PLAM) 은
        해당 데이터를 제거하는 절차를 즉시 시작합니다. 우선 정보가 더 이상
        표시되지 않도록 즉시 삭제하는 것을 목표로 하며, 이 정보는 더 이상
        사용자의 플램(PLAM) 환경을 맞춤설정하는 데 사용되지 않습니다. 이후
        플램(PLAM) 보관 시스템에서 데이터를 안전하고 완전하게 삭제합니다. 종이에
        출력된 정보의 경우 분쇄기로 분쇄하며 전자적 파일 형태로 저장된
        개인정보는 기록을 재생할 수 없는 기술적 방법으로 삭제합니다. 또한
        관계법령의 규정에 의하여 보존할 필요가 있는 데이터는 지정된 기간 동안
        회원 정보를 보관합니다.
      </List>

      <Title>정보 보안 유지</Title>
      <List>
        플램(PLAM) 은 회원 정보를 지속적으로 보호하는 보안 기능이 구축되어
        있습니다. 서비스를 유지하면서 얻는 유용한 정보는 보안 위협을 감지하고
        자동으로 차단하는 데 도움이 됩니다. 회원이 알아야 한다고 생각되는 위험
        요소를 감지하면 사용자에게 알리고 보호 기능을 강화할 수 있는 단계를
        안내합니다.
        <br />
        다음과 같은 조치를 통해 정보 무단 액세스, 변경, 공개, 파기로부터
        사용자와 플램(PLAM) 을 보호하기 위해 노력하고 있습니다.
      </List>
      <List>
        ·암호화를 사용하여 전송 중에 데이터를 비공개로 유지합니다.
        <br />
        ·여러 단계의 인증, 내부 보안 진단 등 다양한 보안 기능을 통하여 계정
        보호를 지원합니다.
        <br />
        ·시스템 무단 액세스를 방지하기 위해 물리적 보안 조치를 포함하여
        플램(PLAM) 의 정보 수집, 저장 및 처리 관행을 검토합니다.
        <br />
        ·개인정보 액세스 권한을 개인정보를 처리하기 위해 액세스가 필요한
        플램(PLAM) 직원 및 대리인으로 제한됩니다. 액세스 권한을 가진 사람은
        계약을 통해 엄격한 기밀 유지의 의무를 갖게 되며 이러한 의무를 어길 경우
        제재를 받거나 계약이 해지될 수 있습니다.
        <br />
      </List>
      <Title>방침 정보</Title>
      <List>
        이 개인정보처리방침은 플램(PLAM) 솔루션 내에서 적용됩니다. 다음 항목에는
        이 개인정보처리방침이 적용되지 않습니다.
        <br />
        ·플램(PLAM) 솔루션을 광고하는 다른 회사 및 조직의 정보 관행
        <br />
        ·타사 또는 개인이 제공하는 서비스
        <br />
      </List>
      <List>
        플램(PLAM) 은 이 개인정보처리방침을 수시로 변경할 수 있습니다.
        플램(PLAM) 은 회원의 명시적인 동의 없이 이 개인정보처리방침에 설명된
        회원의 권한을 축소하지 않습니다. 항상 마지막 변경 사항이 게시된 날짜를
        표시하고 회원이 검토를 위해 보관 처리된 버전에 액세스할 수 있도록
        합니다. 변경 사항이 중대할 경우에는 개인정보처리방침과 관련한 변경 고지
        이메일을 발송하는 등 적극적으로 알립니다.
      </List>
    </Content>
  )
}

export default PrivacyInfo

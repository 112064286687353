import {
  BodyWrapper,
  Caption1,
  Caption2,
  Flex,
  H6,
  Input,
} from '@components/atoms'
import {
  FanTestMelonPopup,
  FanTestOkPopup,
  FanTestPhonePopup,
  FanTestResultPopup,
} from '@components/organisms'
import React, { useState } from 'react'
import {
  default_profile_img,
  fantest_description,
  fantest_description_2,
  fantest_question,
  fantest_question_2_1,
  fantest_question_2_2,
  search_outline_24px,
} from '@images/index'
import { event_description, event_search } from '@images/'
import styled, { css } from 'styled-components'

import { Image } from '@components/molecules'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { STORAGE_URL } from '@consts'
import YouTube from 'react-youtube'
import { colors } from '@colors'
import { handleArtistImgError } from '@utils/handler'
import { pixelTrack } from '@utils/facebook/pixel'

const FanTestEvent2Template = ({ history, authStore }) => {
  const [inputValue, setInputValue] = useState('')
  const [isCorrect, setIsCorrect] = useState('')

  const ANSWER = '영파씨'

  const {
    isShowing: openPopupIsShowing,
    toggle: openPopupToggle,
  } = FanTestMelonPopup.usePopup()

  const {
    isShowing: openResultPopupIsShowing,
    toggle: openResultPopupToggle,
  } = FanTestResultPopup.usePopup()

  const {
    isShowing: openPhonePopupIsShowing,
    toggle: openPhonePopupToggle,
  } = FanTestPhonePopup.usePopup()

  const requestEvent = async phone => {
    const result = await authStore.requestFanTestEvent({
      eventTitle: 'EVENT_YPS_001',
      phoneNumber: phone,
    })
  }

  return (
    <BodyWrapper style={{ padding: 0, minHeight: '100vh' }}>
      <Flex type="column">
        <Flex style={{ position: 'relative', width: '100%' }} type={'column'}>
          <TitleImage src={fantest_question_2_2} />
          <TitleImage src={fantest_question_2_1} />

          <SearchInplamInputContainer align={'center'} style={{}}>
            <SearchInplamInput
              placeholder="한글로 입력해주세요."
              value={inputValue}
              onFocus={() => {
                if (typeof window.twq === 'function') {
                  window.twq('event', 'tw-owc18-p7mpu')
                }
              }}
              onChange={e => {
                setInputValue(e.target.value)
              }}
            />
          </SearchInplamInputContainer>
        </Flex>

        <TitleImage src={fantest_description_2} />
      </Flex>
      <Flex
        style={{
          gap: 8,
          background: '#fff',
          padding: '10px 20px',
          position: 'sticky',
          bottom: 0,
        }}
      >
        <HintButton
          onClick={() => {
            pixelTrack(PIXEL_EVENTS.덕력고사2.오픈북)
            if (typeof window.twq === 'function') {
              window.twq('event', 'tw-owc18-p7mpt')
            }
            history.push('/event/fantest2/hint')
          }}
        >
          <H6>오픈북 보기</H6>
        </HintButton>

        <SubmitButton
          disabled={inputValue?.length === 0}
          onClick={() => {
            pixelTrack(PIXEL_EVENTS.덕력고사2.제출)
            if (typeof window.twq === 'function') {
              window.twq('event', 'tw-owc18-p7mps')
            }
            if (inputValue === ANSWER) {
              setIsCorrect(true)
              setInputValue('')
              openPhonePopupToggle()
            } else {
              setIsCorrect(false)
              openResultPopupToggle()
            }
          }}
        >
          <H6 color={'#fafafa'}>시험지 제출하기</H6>
        </SubmitButton>
      </Flex>

      <FanTestMelonPopup.View
        isShowing={openPopupIsShowing}
        toggle={openPopupToggle}
        onOk={async () => {
          // 멜론으로 이동
          window.open('https://kko.kakao.com/TwWPRr6AyU')
        }}
        onCancel={() => {
          // 유튜브로 이동
          window.open('https://youtu.be/2HZbPz-r2UA?si=napXPiQ3Y8l_8qFz')
        }}
      />
      <FanTestResultPopup.View
        isShowing={openResultPopupIsShowing}
        toggle={openResultPopupToggle}
        onOk={async () => {
          if (isCorrect) {
            pixelTrack(PIXEL_EVENTS.덕력고사2.정답확인)
            if (typeof window.twq === 'function') {
              window.twq('event', 'tw-owc18-p7mpi')
            }
            openPopupToggle()
          }
        }}
        isCorrect={isCorrect}
      />
      <FanTestPhonePopup.View
        isShowing={openPhonePopupIsShowing}
        toggle={openPhonePopupToggle}
        toggleComplte={openResultPopupToggle}
        requestOpen={requestEvent}
      />
    </BodyWrapper>
  )
}

export default FanTestEvent2Template

const TitleImage = styled.img`
  width: '100%';
  height: 'auto';
`

const Description = styled(Flex)`
  background-image: url(${fantest_description});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; /* 오버레이를 위해 필요 */

  padding: 28px 20px;
  gap: 3px;

  white-space: pre-line;
  /* 오버레이 추가 */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(211, 211, 211, 0.5);
    mix-blend-mode: multiply;
    pointer-events: none; /* 클릭 방해 방지 */
  }
`

const SearchImage = styled.img`
  width: 51.6vw; /* 186px */
  max-width: 216px;
  margin: 32px 0 27px 0;
  align-self: center;
  height: auto;
`
const SearchInplamInputContainer = styled(Flex)`
  width: calc(100% - 72px);
  height: 40px;

  border: none !important;
  border-bottom: 2px solid #242424 !important;
  background-color: transparent;

  padding: 6px 5px;

  position: absolute;
  bottom: 60px;

  margin: 0px 36px;

  box-sizing: border-box;
`
const SearchInplamInput = styled(Input)`
  flex: 1;
  font-family: Pretendard-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  border: none !important;
  background-color: transparent;
  padding: 0;
`

const HintButton = styled(Flex)`
  width: 96px;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid var(--grey-grey_80, #242424);
  box-sizing: border-box;
  cursor: pointer;
`

const SubmitButton = styled(Flex)`
  flex: 1;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--grey-grey_80, #242424);
  box-sizing: border-box;
  cursor: pointer;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.16;
    `}
`

const YoutubeWrapper = styled(Flex)`
  width: 100%;
  height: 0;
  position: relative;
  /* padding: 16px 0; */
  padding-bottom: 56.25%;
`

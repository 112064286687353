export const IS_DEV =
  process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'development'

export const API_VERSION = 'v1'

export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL
export const DOMAIN_URL_LENGTH = process.env.REACT_APP_DOMAIN_URL_LENGTH
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL
export const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const ANDROID_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.naivy.plam'
export const IOS_STORE_URL =
  'https://apps.apple.com/kr/app/%ED%94%8C%EB%9E%A8-%EB%A6%AC%EC%9B%8C%EB%93%9C-%EC%9D%8C%EA%B0%90%ED%9A%8C-plam/id6446476110'
// 'https://apps.apple.com/us/app/%ED%94%8C%EB%9E%A8-plam-in/id6446476110'

export const COMMENT_REPORT_REASON = [
  { value: '영리 목적/홍보성', label: '영리 목적/홍보성' },
  { value: '음란성/선정성', label: '음란성/선정성' },
  { value: '욕설/인신공격', label: '욕설/인신공격' },
  { value: '개인정보노출', label: '개인정보노출' },
  { value: '같은 내용 반복 게시 (도배)', label: '같은 내용 반복 게시 (도배)' },
  { value: '기타', label: '기타' },
]

export const ItemTypes = {
  FILEITEM: 'fileItem',
}

export const SUPPORTED_LANGUAGES = ['ko', 'en']

export const ARTIST_PAGE_TEXT = {
  ko: {
    tab_track: '트랙',
    tab_album: '앨범',
    tab_video: '영상',
    filter_all: '전체',
    filter_release: '발매',
    filter_feature: '참여',
    order_popular: '인기순',
    order_recent: '최신순',
    no_released_song: '발매한 곡이 없습니다.',
    no_featured_song: '참여한 곡이 없습니다.',
    no_released_album: '발매한 앨범이 없습니다.',
    no_featured_album: '참여한 앨범이 없습니다.',
    no_video: '동영상이 없습니다.',
    video_views: '조회수',
    video_like: '좋아요',
  },
  en: {
    tab_track: 'Tracks',
    tab_album: 'Albums',
    tab_video: 'Videos',
    filter_all: 'All',
    filter_release: 'Released',
    filter_feature: 'Featured',
    order_popular: 'Popular',
    order_recent: 'Latest',
    no_released_song: 'There are no released songs.',
    no_featured_song: 'There are no featured songs.',
    no_released_album: 'There are no released albums.',
    no_featured_album: 'There are no featured albums.',
    no_video: 'There are no videos.',
    video_views: 'views',
    video_like: 'likes',
  },
}

export const TRACK_PAGE_TEXT = {
  ko: {
    lyrics: '가사',
    credit: 'Credit',
    lyrics_by: '작사',
    composed_by: '작곡',
    arranged_by: '편곡',
    genre: '장르',
    more: '더보기',
    no_lyrics: '등록된 가사가 없습니다.',
    no_info: '등록된 정보가 없습니다.',
  },
  en: {
    lyrics: 'Lyrics',
    credit: 'Credit',
    lyrics_by: 'Lyrics by',
    composed_by: 'Composed by',
    arranged_by: 'Arranged by',
    genre: 'Genre',
    more: 'more',
    no_lyrics: 'There are no registered lyrics.',
    no_info: 'There is no data.',
  },
}

export const ALBUM_PAGE_TEXT = {
  ko: {
    tab_track_list: '수록곡',
    tab_details: '상세정보',
    type: '유형',
    genre: '장르',
    release_date: '발매일',
    distributor: '유통사',
    rights_company: '권리사',
    album_description: '앨범소개',
  },
  en: {
    tab_track_list: 'Tracklist',
    tab_details: 'Details',
    type: 'Type',
    genre: 'Genre',
    release_date: 'Release Date',
    distributor: 'Distributors',
    rights_company: 'Rights Holders',
    album_description: 'Album Description',
  },
}

export const WEB_APP_PACKAGE_NAME = 'com.naivy.plam'
export const POST_MESSAGES = {
  WTA_DATA: {
    LOGOUT: {
      code: 'WTA_LOGOUT',
      data: {},
    },
    MOVE_REWARD_LIST: {
      code: 'WTA_MOVE_REWARD_LIST',
      data: {},
    },
    MOVE_POINT_HISTORY: {
      code: 'WTA_MOVE_POINT_HISTORY',
      data: {},
    },
  },

  ATW_CODE: {
    LOGOUT: 'ATW_LOGOUT',
    LOGIN: 'ATW_LOGIN',
    HOME_FETCH: 'ATW_HOME_FETCH',
    MOVE_ARTIST_INPLAM: 'ATW_MOVE_ARTIST_INPLAM',
    MOVE_ARTIST_PLAMIN: 'ATW_MOVE_ARTIST_PLAMIN',
    MOVE_TRACK: 'ATW_MOVE_TRACK',
    MOVE_ALBUM: 'ATW_MOVE_ALBUM',
    MOVE_NOTIFEE: 'ATW_MOVE_NOTIFEE',
    MOVE_FANSPOT: 'ATW_MOVE_FANSPOT',
    PLAYBAR_STATE: 'ATW_PLAYBAR_STATE',
  },
}

export const getIsWebApp = ({
  navigator,
  requiredUserAgentStr = WEB_APP_PACKAGE_NAME,
}) => {
  try {
    if (!window || !requiredUserAgentStr) {
      console.error('[getIsWebApp]', 'require params. navigator')
      return
    }

    const isWebApp = navigator.userAgent.indexOf(requiredUserAgentStr)
    return isWebApp > -1
  }
  catch (error) {
    console.error('[getIsWebApp]', error)
  }
}

export const sendWTAPostMessage = ({
  window,
  navigator,
  postMessageData,
  requiredUserAgentStr = WEB_APP_PACKAGE_NAME,
}) => {
  try {
    if (!window || !navigator || !postMessageData) {
      console.error(
        '[sendWTAPostMessage]',
        'require params. window, navigator, postMessageData',
      )
      return
    }

    const isWebApp = getIsWebApp({ navigator, requiredUserAgentStr })
    if (isWebApp) {
      window.ReactNativeWebView.postMessage(JSON.stringify(postMessageData))
    }
  }
  catch (error) {
    console.error('[sendWTAPostMessage]', error)
  }
}

export const getJWTFromUserAgent = ({
  navigator,
  initJWT = '',
  requiredUserAgentStr = WEB_APP_PACKAGE_NAME,
}) => {
  try {
    if (!navigator) {
      console.error('[getJWTFromUserAgent]', 'require params. navigator')
      return
    }

    const isWebApp = getIsWebApp({ navigator, requiredUserAgentStr })
    if (isWebApp) {
      const prefix = ' jwt '
      const index = navigator.userAgent.indexOf(prefix)
      let jwt = ''
      if (index > -1) {
        jwt = navigator.userAgent.substring(index + prefix.length) || ''
      }
      return jwt
    }
  }
  catch (error) {
    console.error('[getJWTFromUserAgent]', error)
  }

  return initJWT
}

export const addATWPostMessageListener = ({
  navigator,
  document,
  window,
  requiredUserAgentStr = WEB_APP_PACKAGE_NAME,
  listeners = {},
}) => {
  try {
    if (!navigator || !document || !window) {
      console.error(
        '[addATWPostMessageListener]',
        'require params. navigator, document, window',
      )
      return
    }

    const isWebApp = getIsWebApp({ navigator, requiredUserAgentStr })
    if (isWebApp) {
      const eventListener = async event => {
        try {
          const data = JSON.parse(event.data)
          switch (data?.code) {
            case POST_MESSAGES.ATW_CODE.LOGOUT:
              // logout 로직
              listeners[POST_MESSAGES.ATW_CODE.LOGOUT]
                && (await listeners[POST_MESSAGES.ATW_CODE.LOGOUT]())
              break
            case POST_MESSAGES.ATW_CODE.LOGIN:
              listeners[POST_MESSAGES.ATW_CODE.LOGIN]
                && (await listeners[POST_MESSAGES.ATW_CODE.LOGIN](data?.data?.jwt))
              break
            case POST_MESSAGES.ATW_CODE.HOME_FETCH:
              listeners[POST_MESSAGES.ATW_CODE.HOME_FETCH]
                && (await listeners[POST_MESSAGES.ATW_CODE.HOME_FETCH]())
              break
            case POST_MESSAGES.ATW_CODE.MOVE_ARTIST_INPLAM:
              listeners[POST_MESSAGES.ATW_CODE.MOVE_ARTIST_INPLAM]
                && (await listeners[POST_MESSAGES.ATW_CODE.MOVE_ARTIST_INPLAM](
                  data?.data?.artistId,
                ))
              break
            case POST_MESSAGES.ATW_CODE.MOVE_ARTIST_PLAMIN:
              listeners[POST_MESSAGES.ATW_CODE.MOVE_ARTIST_PLAMIN]
                && (await listeners[POST_MESSAGES.ATW_CODE.MOVE_ARTIST_PLAMIN](
                  data?.data?.artistId,
                ))
              break
            case POST_MESSAGES.ATW_CODE.MOVE_TRACK:
              listeners[POST_MESSAGES.ATW_CODE.MOVE_TRACK]
                && (await listeners[POST_MESSAGES.ATW_CODE.MOVE_TRACK](
                  data?.data?.trackId,
                ))
              break
            case POST_MESSAGES.ATW_CODE.MOVE_ALBUM:
              listeners[POST_MESSAGES.ATW_CODE.MOVE_ALBUM]
                && (await listeners[POST_MESSAGES.ATW_CODE.MOVE_ALBUM](
                  data?.data?.albumId,
                ))
              break
            case POST_MESSAGES.ATW_CODE.MOVE_NOTIFEE:
              listeners[POST_MESSAGES.ATW_CODE.MOVE_NOTIFEE]
                && (await listeners[POST_MESSAGES.ATW_CODE.MOVE_NOTIFEE]())
              break
            case POST_MESSAGES.ATW_CODE.MOVE_FANSPOT:
              listeners[POST_MESSAGES.ATW_CODE.MOVE_FANSPOT]
                && (await listeners[POST_MESSAGES.ATW_CODE.MOVE_FANSPOT](
                  data?.data?.uniqueName,
                ))
              break
            case POST_MESSAGES.ATW_CODE.PLAYBAR_STATE:
              listeners[POST_MESSAGES.ATW_CODE.PLAYBAR_STATE]
                && (await listeners[POST_MESSAGES.ATW_CODE.PLAYBAR_STATE](
                  data?.data?.state,
                ))
              break
            default:
              break
          }
        }
        catch (error) {}
      }
      /* android */
      document.addEventListener('message', eventListener)

      /* ios */
      window.addEventListener('message', eventListener)
    }
  }
  catch (error) {
    console.error('[addATWPostMessageListener]', error)
  }
}

import { Caption1, Caption2, Flex } from '@components/atoms'
import React, { useEffect, useState } from 'react'

import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import TextLoop from 'react-text-loop'
import TextareaAutosize from 'react-textarea-autosize'
import { colors } from '@colors'
import { comment_img } from '@images'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { getIsWebApp } from '@utils/postMessage'

interface CommentInputProps {
  dataCollectionName: string;
  dataObjectId: string;
  isLike: boolean;
  commentList: Array<any>;
}

const CommentInput = ({
  isLike,
  dataObjectId,
  dataCollectionName,
  commentList,
  commentListCount,
  track,
}: CommentInputProps) => {
  const { authStore } = useStore()

  const [inputValue, setInputValue] = useState('')

  const isWebApp = getIsWebApp({ navigator })

  const history = useHistory()

  const handleMoveCommentPage = () => {
    history.push(`/comment/${dataCollectionName}/${dataObjectId}`)
    pixelTrack(PIXEL_EVENTS.트랙.댓글목록, {
      trackId: track?._id,
      trackTitle: track?.title,
      artistId: track?.artistId,
      artistName: track?.releaseArtistList?.[0]?.name,
    })
  }

  return (
    <InputBox onClick={handleMoveCommentPage} isWebApp={isWebApp}>
      {
        commentList && commentList.length > 0 && (
          <MarqueCommentList>
            <Caption2
              type="Regular"
              style={
                {
                  minWidth: 26,
                  maxWidth: 26,
                  padding: '3px 7px',
                  border: '1px solid white',
                }
              }
            >
            댓글
            </Caption2>
            <RollingBox>
              <TextLoop
                interval={1800}
                delay={500}
                springConfig={{ stiffness: 180, damping: 8 }}
              >
                {
                  commentList.map((comment, index) => (
                    <Caption1
                      key={`${comment._id}_${index}`}
                      id={`comment_${index}`}
                    >
                      {comment.text}
                    </Caption1>
                  ))
                }
              </TextLoop>
            </RollingBox>
          </MarqueCommentList>
        )
      }

      <Flex style={{ width: '100%', alignItems: 'flex-end', minHeight: 56 }}>
        <CommentIcon>
          <img
            src={comment_img}
            alt="comment_icon"
            style={{ width: 24, height: 24 }}
          />
          <Caption1>{commentListCount || 0}</Caption1>
        </CommentIcon>
        <CustomInput
          disabled
          placeholder={
            !authStore.currentUser
              ? '로그인을 해주세요'
              : !isLike
                ? '좋아요를 눌러주세요'
                : '댓글을 입력해주세요.'
          }
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          maxRows={3}
          autoCorrect="off"
          autoCapitalize="off"
          style={{ pointerEvents: 'none' }}
        />
        <SubmitBtn>등록</SubmitBtn>
      </Flex>
    </InputBox>
  )
}

export default CommentInput

const InputBox = styled(Flex)`
  position: fixed;
  flex-direction: column;
  bottom: ${props => (props.isWebApp ? 0 : '44px')};
  margin-left: -20px;
  width: 100%;
  max-width: 420px;
  min-width: 320px;
  height: fit-content;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.38);
  cursor: pointer;
`

const CustomInput = styled(TextareaAutosize)`
  font-family: Pretendard-Regular;
  resize: none;
  width: calc(100% - 40px);
  font-size: 14px;
  margin: 7px 0 7px 5px;
  padding: 10px;
  border-radius: unset;
  min-height: 20px;
  line-height: 1.4;

  :disabled {
    background-color: #ffffff;
    border: solid 1px #f4f4f4;
  }

  ::placeholder {
    background-color: trasparent;
    color: #949494;
  }
`

const CommentIcon = styled(Flex)`
  flex-direction: column;
  margin-left: 10px;
  width: 50px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
`

const SubmitBtn = styled(Flex)`
  font-size: 12px;
  margin-right: 10px;
  min-width: 48px;
  height: 40px;
  border: 1px solid #f4f4f4;
  color: #d4d4d4;
  background-color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 7px;
`

const MarqueCommentList = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-items: center;
  height: 32px;
  background-color: ${colors.black};
  color: ${colors.white};
  padding: 7px 8px;
  box-sizing: border-box;
  overflow: hidden;
`

const RollingBox = styled(Flex)`
  margin-top: -4px;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  margin-left: 8px;

  > p {
    min-height: 28px;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

import React from 'react'
import { Typography, Flex } from '@components/atoms'
import styled from 'styled-components'
import Textarea from 'react-textarea-autosize'
import { dateYMD } from '@utils/format'
import { ALBUM_PAGE_TEXT } from '@consts'

const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0px;

  span:first-child {
    width: fit-content;
    white-space: nowrap;
    margin-right: 20px;
  }
`

const AlbumInfo = ({
  albumType,
  albumGenre,
  releaseDate,
  releaseCompanyName,
  albumText,
  planningCompanyName,
  lang,
}) => {
  return (
    <Flex type="column">
      <InfoItem>
        <Typography size="14px" color="#646464">
          {ALBUM_PAGE_TEXT[lang]['type']}
        </Typography>
        <Typography size="14px">{albumType}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography size="14px" color="#646464">
          {ALBUM_PAGE_TEXT[lang]['genre']}
        </Typography>
        <Typography size="14px">{albumGenre}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography size="14px" color="#646464">
          {ALBUM_PAGE_TEXT[lang]['release_date']}
        </Typography>
        <Typography size="14px">{dateYMD(releaseDate)}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography size="14px" color="#646464">
          {ALBUM_PAGE_TEXT[lang]['distributor']}
        </Typography>
        <Typography size="14px">{releaseCompanyName}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography size="14px" color="#646464">
          {ALBUM_PAGE_TEXT[lang]['rights_company']}
        </Typography>
        <Typography size="14px">{planningCompanyName}</Typography>
      </InfoItem>

      <hr
        style={
          {
            margin: '24px 0px 30px 0px',
            borderColor: '#f4f4f4',
            width: '100%',
            borderWidth: '0.5px',
          }
        }
      />

      <Typography type="Medium" size="20px" style={{ paddingBottom: '6px' }}>
        {ALBUM_PAGE_TEXT[lang]['album_description']}
      </Typography>
      {
        albumText ? (
          <Textarea
            readOnly
            value={albumText}
            className="textarea"
            style={{ fontSize: '14px', color: '#646464' }}
            minRows={1}
          />
        ) : (
          <Typography type="Medium">등록된 소개가 없습니다.</Typography>
        )
      }
    </Flex>
  )
}

export default AlbumInfo

import { BodyWrapper, Flex, H5, H6 } from '@components/atoms'
import React, { useEffect, useRef, useState } from 'react'
import {
  prev_page_img,
  circle_facebook_img,
  circle_kakao_img,
  circle_google_img,
  x_circle_black_img,
  camera_img,
} from '@images/'
import styled from 'styled-components'
import { colors } from '@colors/'
import { useSnackBar } from '@components/organisms'

const EditProfileTemplate = ({
  history,
  currentUser,
  updateUser,
  editProfile,
  beforePostImage,
  hanldeUpload,
  croppedImageUrl,
  fetchAuthUser,
  useLoading,
  nickname,
  setNickname,
}) => {
  const [errorMsg, setErrorMsg] = useState('')

  const inputRef = useRef()

  const {
    View: VallidationView,
    toggleSnackBar: toggleValidation,
  } = useSnackBar()

  const handleSubmit = async () => {
    useLoading(async () => {
      if (!nickname && !beforePostImage) return

      let pathName = false
      if (croppedImageUrl) {
        pathName = await hanldeUpload()
      }

      const nicknameReg = /^[가-힣a-zA-Z0-9]{1,8}$/

      if (nickname && !nicknameReg.test(nickname)) {
        setErrorMsg('최대 8글자의 한글, 영문자, 숫자만 입력 가능합니다')
        toggleValidation()
        return
      }

      let result
      if (nickname && pathName) {
        result = await updateUser({
          nickname,
          profileImageOriginalPath: `/${pathName}`,
        })
      }
      else if (nickname) {
        result = await updateUser({
          nickname,
        })
      }
      else if (pathName) {
        result = await updateUser({
          profileImageOriginalPath: `/${pathName}`,
        })
      }

      if (result.error && result.error.type === 'Conflict') {
        setErrorMsg('이미 존재하는 닉네임입니다')
        toggleValidation()
        return
      }
      if (result.error && result.error.type === 'BadRequest') {
        setErrorMsg('최대 8글자의 한글, 영문자, 숫자만 입력 가능합니다')
        toggleValidation()
        return
      }
      if (!result.error) {
        if (pathName) {
          setTimeout(async () => {
            await fetchAuthUser()
          }, 2000)
        }
        else {
          await fetchAuthUser()
        }
        history.goBack()
      }
    })
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <BodyWrapper
      style={{ paddingTop: 0, paddingBottom: 64, minHeight: '100vh' }}
    >
      <TopBar>
        <PrevButton src={prev_page_img} onClick={() => history.goBack()} />
        <H5>프로필 수정</H5>
      </TopBar>
      <Br />
      <Flex type="column" style={{ marginTop: 80 }} align="center">
        <Flex
          style={{ position: 'relative', cursor: 'pointer' }}
          onClick={() => editProfile()}
        >
          <img
            src={
              beforePostImage
                ? beforePostImage
                : currentUser.image128Uri
                  ? currentUser.image128Uri
                  : currentUser.socialType === 'facebook'
                    ? circle_facebook_img
                    : currentUser.socialType === 'kakao'
                      ? circle_kakao_img
                      : circle_google_img
            }
            alt="profile_img"
            style={
              {
                borderRadius: 36,
                width: 72,
                height: 72,
              }
            }
          />
          <EditProfileBtn>
            <img src={camera_img} alt="camera_icon" />
          </EditProfileBtn>
        </Flex>
        <NicknameInputBox
          nicknameLength={
            nickname === ''
              ? currentUser.nickname && currentUser.nickname.length
              : nickname.length
          }
        >
          <input
            ref={inputRef}
            value={nickname}
            onChange={e => setNickname(e.target.value)}
            placeholder={currentUser.nickname || ''}
          />
          <img
            src={x_circle_black_img}
            alt="x_btn_icon"
            onClick={() => setNickname('')}
          />
        </NicknameInputBox>
        <SubmitBtn
          onClick={handleSubmit}
          disable={!nickname && !beforePostImage}
        >
          수정
        </SubmitBtn>
      </Flex>
      <VallidationView text={errorMsg} backgroundColor="#ea4653" />
    </BodyWrapper>
  )
}

export default EditProfileTemplate

const TopBar = styled(Flex)`
  padding-top: 16px;
  padding-bottom: 16px;
  height: 24px;
  background-color: white;
  align-items: center;
`

const PrevButton = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: solid 1px ${colors.whitef4};
`

const NicknameInputBox = styled(Flex)`
  position: relative;
  margin-top: 24px;
  border-bottom: solid 1px #d4d4d4;
  justify-content: center;
  width: 100%;

  input {
    font-family: Pretendard-Medium;
    font-size: 20px;
    border: none;
    width: ${props => props.nicknameLength * 20}px;
    padding-bottom: 10px;
  }

  img {
    position: absolute;
    right: 0;
    bottom: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`

const SubmitBtn = styled(H6)`
  margin-top: 16px;
  width: 100%;
  height: 48px;
  background-color: ${props => (props.disable ? '#f4f4f4' : '#242424')};
  color: ${props => (props.disable ? '#d4d4d4' : '#f4f4f4')};
  border: none;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
`

const EditProfileBtn = styled(Flex)`
  position: absolute;
  width: 32px;
  height: 32px;
  right: -16px;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 16px;

  img {
    width: 20px;
    height: 20px;
  }
`

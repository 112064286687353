import React, { useEffect, useRef, useState } from 'react'
import { useLastLocation } from 'react-router-last-location'
import { inject, observer } from 'mobx-react'
import qs from 'qs'

import { HomeTemplate } from '@components/templates'
import { useScrollRestoration, useStore } from '@utils/hooks'

import { IS_DEV, DOMAIN_URL_LENGTH } from '@consts'
import Axios from 'axios'
import { getRandomSubset } from '@utils/array'

interface HomePageProps {
  recordStore: RecordStore;
  authStore: AuthStore;
  statsStore: StatsStore;
  rewardStore: RewardStore;
  articleStore: ArticleStore;
  inplamStore: InplamStore;
  match: Any;
  history: Any;
  location: Any;
}

const HomePage = ({
  match,
  history,
  location,
  recordStore,
  authStore,
  statsStore,
  rewardStore,
  articleStore,
  dataMapStore,
  inplamStore,
}: HomePageProps) => {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const lastLocation = useLastLocation()

  /* store */
  const { useInitialLoading } = useStore()

  const enterRecord = recordStore.enterRecord || (() => {})
  const recorded = recordStore.recorded || false

  const { currentUser, likedAristList } = authStore

  const fetchUserLikeArtistList =
    authStore.fetchUserLikeArtistList || (() => {})

  const fetchUserLikeTrackList = authStore.fetchUserLikeTrackList || (() => {})

  const fetchPopularTrackList = authStore.fetchPopularTrackList || (() => {})

  const fetchBannerList = authStore.fetchBannerList || (() => {})
  const bannerList = authStore.bannerList || []

  const fetchNoticePopupList = authStore.fetchNoticePopupList || (() => {})
  const noticePopupList = authStore.noticePopupList || (() => {})

  const plamChartList = statsStore.plamChartList || null
  const latestAlbumList = statsStore.latestAlbumList || null
  const rewardList = rewardStore.rewardList || null

  const fetchPlamChart = statsStore.fetchPlamChart || (() => {})
  const fetchLatestAlbum = statsStore.fetchLatestAlbum || (() => {})
  const fetchRewardList = rewardStore.fetchRewardList || (() => {})

  const fetchPopularArticleList =
    articleStore.fetchPopularArticleList || (() => {})
  const popularArticleList = articleStore.popularArticleList || null
  const fetchArticleCategoryList = articleStore.fetchArticleCategoryList || null
  const fetchTotalArticleList = articleStore.fetchTotalArticleList || (() => {})

  const totalArticleList = articleStore.totalArticleList || null
  const totalArticleListCount = articleStore.totalArticleListCount || null

  const totalInplamList = inplamStore.totalInplamList || null
  const fetchTotalInplamList = inplamStore.fetchTotalInplamList || (() => {})

  const fetchArticleList = articleStore.fetchArticleList || (() => {})

  const [isMounted, setIsMounted] = useState(false)

  const [myFanSpotList, setMyFanSpotList] = useState([])
  const [totalInplamoffset, setTotalInplamOffset] = useState(0)

  const [notice, setNotice] = useState(null)

  const [isChanged, setIsChanged] = useState(false)
  const [offset, setOffset] = useState(query.offset ? Number(query.offset) : 0)
  const [isFinish, setIsFinish] = useState(false)
  const [activeTab, setActiveTab] = useState(
    query.activeTab ? query.activeTab : 'popular',
  )
  const [sortBy, setSortBy] = useState(
    query.sortBy ? query.sortBy : 'createdAt',
  )

  const [searchWord, setSearchWord] = useState(null)

  const contentRef = useRef()

  // 🔥 최초 접근 여부 (`isFirstLoad` 초기화)
  if (!sessionStorage.getItem('isFirstLoad')) {
    sessionStorage.setItem('isFirstLoad', 'true')
  }
  const isFirstLoad = sessionStorage.getItem('isFirstLoad') === 'true'

  // ✅ 새로고침 감지 (Navigation API 사용, 일부 브라우저에서는 `reload`를 감지 못할 수도 있음)
  const isReload = !lastLocation

  const handlePagination = _offset => {
    setOffset(Number(_offset))
  }

  // const handleChangeSortBy = _sortBy => {
  //   setSortBy(_sortBy)
  // }

  const handleChangeSortBy = _sortBy => {
    history.replace(`/?sortBy=${_sortBy}&activeTab=${activeTab}`)
  }

  const handleChangeActiveTap = _activeTab => {
    setOffset(0)
    setSearchWord(null)
    history.replace(`/?sortBy=${sortBy}&activeTab=${_activeTab}`)
    // setActiveTab(_activeTab)
  }

  const handleChangeSearchWord = _searchWord => {
    if (!_searchWord) {
    }
    else {
      history.push(
        `/fanspot/free-spot?offset=0&sortBy=createdAt&searchWord=${_searchWord}&category=all`,
      )
    }
  }

  useEffect(() => {
    if (query.sortBy) setSortBy(query.sortBy)
    if (query.activeTab) {
      setActiveTab(query.activeTab)
    }
    setIsChanged(true)
  }, [query.sortBy, query.activeTab])

  const fetchLatestNotice = async () => {
    const options = {
      limit: 1,
      sortBy: 'createdAt',
      offset: 0,
      isNotice: true,
    }

    const result = await fetchArticleList(options)

    setNotice(result)
  }

  useEffect(() => {
    const fetchDatas = async () => {
      await Promise.all([
        fetchArticleCategoryList(),
        fetchNoticePopupList({}),
        useInitialLoading(fetchBannerList()),
        useInitialLoading(
          fetchRewardList({
            __offset: 0,
            __limit: 20,
            isExpired: false,
          }),
        ),
        // useInitialLoading(
        //   fetchLatestAlbum({
        //     limit: 30,
        //   }),
        // ),
        // useInitialLoading(fetchPopularArticleList()),
        // useInitialLoading(fetchArticleData()),
        useInitialLoading(fetchLatestNotice()),

        // useInitialLoading(fetchLikedTrackList()),
        // useInitialLoading(fetchPopularList()),
      ])

      setIsMounted(true)
    }

    fetchDatas()

    if (!currentUser) {
      localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      useInitialLoading(
        fetchUserLikeArtistList({ loginUserId: currentUser?._id }),
      )
    }
  }, [currentUser, fetchUserLikeArtistList])

  const fetchArticleData = async () => {
    const options = {
      limit: 20,
      sortBy,
      offset: 0,
      isNotice: activeTab === 'notice',
    }

    if (activeTab === 'notice') {
      options.sortBy = 'createdAt'
    }

    if (activeTab === 'popular') {
      options.isPopularArticle = 1
      options.sortBy = 'viewCount'
    }

    if (activeTab === 'info') {
      options.articleCategoryId = articleStore?.articleCategoryList?.find(
        val => val.name === '정보',
      )?._id

      // options.sortBy = 'createdAt'
    }

    if (activeTab === 'free') {
      // 자유스팟 아이디로 패치하기

      options.uniqueName = 'free-spot'
    }

    await fetchTotalArticleList(options)
  }

  const fetchMoreArticleData = async () => {
    const options = {
      limit: 20,
      sortBy,
      offset,
      isNotice: activeTab === 'notice',
    }

    if (activeTab === 'popular') {
      options.isPopularArticle = 1
      options.sortBy = 'viewCount'
    }

    if (activeTab === 'info') {
      options.articleCategoryId = articleStore?.articleCategoryList?.find(
        val => val.name === '정보',
      )?._id
      // options.sortBy = 'createdAt'
    }

    if (activeTab === 'free') {
      // 자유스팟 아이디로 패치하기
      options.uniqueName = 'free-spot'
    }

    const result = await fetchTotalArticleList(options)

    if (!result || result?.length === 0) {
      setIsFinish(true)
    }
  }

  useEffect(() => {
    if (
      isFirstLoad
      || isReload
      || isChanged
      || (!isReload && totalArticleList?.length === 0)
    ) {
      setIsFinish(false)
      fetchArticleData()
      sessionStorage.setItem('isFirstLoad', 'false')
      setIsChanged(false)
      contentRef?.current?.scrollTo(0, 0)
    }
    fetchLatestNotice()
  }, [activeTab, sortBy])

  useEffect(() => {
    if (offset > 0) {
      fetchMoreArticleData()
    }
    // fetchLatestNotice()
  }, [offset])

  // useEffect(() => {
  //   const source = Axios.CancelToken.source()

  //   const fetchTotalList = async ({
  //     __limit = 20,
  //     __isFavorite = 1,
  //     __offset = 0,
  //   }) => {
  //     const options = {
  //       offset: __offset,
  //       limit: __limit,
  //       isFavorite: __isFavorite,
  //       noNeedStore: true,
  //     }

  //     const result = await fetchTotalInplamList(options, {
  //       cancelToken: source.token,
  //     })

  //     if (__isFavorite === 0 || __offset > 0) {
  //       setMyFanSpotList(val => [...val, ...result])
  //     }
  //     else {
  //       setMyFanSpotList(result)
  //     }

  //     if (__isFavorite === 1 && result?.length < __limit) {
  //       const options2 = {
  //         offset: 0,
  //         limit: 40,
  //         isFavorite: 0,
  //         noNeedStore: true,
  //       }

  //       const result2 = await fetchTotalInplamList(options2, {
  //         cancelToken: source.token,
  //       })

  //       const shuffledData = getRandomSubset(result2, 8)

  //       setMyFanSpotList(val => [...val, ...shuffledData])
  //     }
  //   }
  //   fetchTotalList({
  //     __limit: 500,
  //     __isFavorite: 1,
  //     __offset: 0,
  //   })

  //   return () => {
  //     source.cancel('Operation canceled by the user.')
  //   }
  // }, [authStore?.currentUser])

  return (
    <>
      <HomeTemplate
        currentUser={currentUser}
        rewardList={rewardList}
        fetchRewardList={fetchRewardList}
        fetchPopularArticleList={fetchPopularArticleList}
        articleList={totalArticleList}
        articleListCount={totalArticleListCount}
        myFanSpotList={myFanSpotList}
        noticePopupList={noticePopupList}
        history={history}
        bannerList={bannerList}
        notice={notice}
        handlePagination={handlePagination}
        offset={offset}
        handleChangeSortBy={handleChangeSortBy}
        sortBy={sortBy}
        handleChangeActiveTap={handleChangeActiveTap}
        activeTab={activeTab}
        setTotalInplamOffset={setTotalInplamOffset}
        totalInplamoffset={totalInplamoffset}
        fetchTotalInplamList={fetchTotalInplamList}
        contentRef={contentRef}
        isFinish={isFinish}
        searchWord={searchWord}
        handleChangeSearchWord={handleChangeSearchWord}
      />
    </>
  )
}

export default inject(
  'recordStore',
  'authStore',
  'statsStore',
  'articleStore',
  'inplamStore',
  'dataMapStore',
  'rewardStore',
)(observer(HomePage))

import React from 'react'
import './Popup.css'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Typography, Flex } from '../../atoms'

const MailPopupInner = styled.div`
  position: absolute;
  /* width: 320px; */
  width: calc(100% - 40px);
  height: 114px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.78);
  left: 5%;
  top: 200px;
  background: black;
  z-index: 1000;
  border-radius: 6px;
  color: white;
`

const AddressBox = styled.div`
  display: flex;
  width: 85%;
  height: 42px;
  margin-right: 17px;
  background: white;
  border-radius: 7px;
  font-size: 12px;
  font-family: Pretendard-Medium;
  color: #242424;
`

const ArtistName = styled.span`
  display: inline-block;
  width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-faimly: Pretendard-Medium;
  font-size: 16px;
  padding-right: 10px;
`

const Address = styled.span`
  padding: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-faimly: Pretendard-Medium;
  font-size: 12px;
`

const MailPopup = ({ name, mail, setShowMail, onCopy }) => {
  return (
    <>
      <div className="popup" onClick={() => setShowMail(false)} />
      <MailPopupInner>
        <div style={{ padding: '16px 12px' }}>
          <ArtistName>{`${name} 의 메일주소`}</ArtistName>
          <Flex style={{ alignItems: 'center', paddingTop: '16px' }}>
            <AddressBox>
              <Address>{mail}</Address>
            </AddressBox>
            <CopyToClipboard text={mail} onCopy={() => onCopy()}>
              <Typography
                color="#0087ff"
                type="Medium"
                style={{ cursor: 'pointer' }}
              >
                복사
              </Typography>
            </CopyToClipboard>
          </Flex>
        </div>
      </MailPopupInner>
    </>
  )
}

export default MailPopup

import {
  BodyWrapper,
  Caption1,
  Caption2,
  Flex,
  H6,
  Input,
} from '@components/atoms'

import React, { useState } from 'react'
import { fantest_hint_2 } from '@images/index'
import { event_description, event_search } from '@images/'
import styled, { css } from 'styled-components'

const FanTestEvent2HintTemplate = ({ history, authStore }) => {
  return (
    <BodyWrapper style={{ padding: 0, minHeight: '100vh' }}>
      <Flex type="column">
        <Flex style={{ position: 'relative', width: '100%' }} type={'column'}>
          <TitleImage src={fantest_hint_2} />
        </Flex>
      </Flex>
      <Flex
        style={
          {
            gap: 8,
            background: '#fff',
            padding: '10px 20px',
            position: 'sticky',
            bottom: 0,
          }
        }
      >
        <SubmitButton
          onClick={
            () => {
              history.replace('/event/fantest2')
            }
          }
        >
          <H6 color={'#fafafa'}>정답 맞히러 가기</H6>
        </SubmitButton>
      </Flex>
    </BodyWrapper>
  )
}

export default FanTestEvent2HintTemplate

const TitleImage = styled.img`
  width: '100%';
  height: 'auto';
`

const SubmitButton = styled(Flex)`
  flex: 1;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--grey-grey_80, #242424);
  box-sizing: border-box;
  cursor: pointer;
`

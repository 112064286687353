import { Caption1, Caption2, Flex, H5, H6 } from '@components/atoms'
import React, { useState } from 'react'
import { check_empty_img, check_img_blue, open_alarm_talk_img } from '@images/'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import ReactDOM from 'react-dom'
import { colors } from '@colors/'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import { useSnackBar } from '..'

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle, onCancel, onOk }) => {
  const handleCancel = () => {
    pixelTrack(PIXEL_EVENTS.덕력고사.참여취소)
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  const handleOk = async () => {
    pixelTrack(PIXEL_EVENTS.덕력고사.참여)
    if (typeof window.twq === 'function') {
      window.twq('event', 'tw-owc18-p5w9d')
    }
    onOk()
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
        <>
          <ModalOverlay />
          <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <Modal>
              <MobdlBody>
                <Flex
                  type="column"
                  style={{
                    padding: '24px 20px',
                  }}
                >
                  <H6 style={{ marginTop: 12 }}>이벤트에 참여하시겠습니까?</H6>
                </Flex>
                {/* footer */}
                <Flex style={{ justifyContent: 'flex-end' }}>
                  <CancelBtn onClick={handleCancel}>
                    <H6>취소</H6>
                  </CancelBtn>
                  <Okbtn onClick={handleOk}>
                    <H6>참여하기</H6>
                  </Okbtn>
                </Flex>
              </MobdlBody>
            </Modal>
          </ModalWrapper>
        </>,
        document.body,
      )
    : null
}

export default {
  View,
  usePopup,
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background: white;
  overflow: auto;
  max-width: 320px;
  min-width: 280px;
  width: calc(100% - 40px);
  /* min-height: 183px; */
  height: fit-content;
  top: 50%;
  border-radius: 2px;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const PhoneInput = styled.input`
  width: calc(100% - 12px);
  height: 48px;
  font-size: 16px;
  font-family: Pretendard-Regular;
  border: solid 1px #d4d4d4;
  padding-left: 12px;
  margin: 0px;
`

const CancelBtn = styled.button`
  display: flex;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  font-size: 14px;
  font-family: Pretendard-Regular;
  border: none;
  background: var(--grey-grey_40, #d4d4d4);
  color: #646464;
  cursor: pointer;
`

const Okbtn = styled.button`
  display: flex;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  font-size: 14px;
  font-family: Pretendard-Regular;
  border: none;
  border-radius: 0px 0px 2px 0px;
  background: var(--grey-grey_80, #242424);
  cursor: pointer;
  color: var(--grey-grey_10, #fafafa);
`

import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { H6, Caption1, Flex } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: white;
  overflow: auto;
  width: 340px;
  min-height: 104px;

  height: fit-content;
  top: 50%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const CancelBtn = styled.button`
  font-size: 14px;
  font-family: Pretendard-Regular;
  border: none;
  background-color: transparent;
  color: #646464;
  cursor: pointer;
  margin-right: 24px;
`

const Okbtn = styled.button`
  font-size: 14px;
  font-family: Pretendard-Regular;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${props => props.okColor};
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({
  isShowing,
  toggle,
  onCancel,
  onOk,
  okText = '확인',
  okColor = '#242424',
  title,
  body,
}) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  const handleOk = () => {
    if (onOk) {
      onOk()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H6
                type="Regular"
                align="left"
                style={{ whiteSpace: 'pre-line' }}
              >
                {title}
              </H6>
              <Caption1 color="#949494" style={{ whiteSpace: 'pre' }}>
                {body}
              </Caption1>
              <Flex style={{ justifyContent: 'flex-end', marginTop: '30px' }}>
                <CancelBtn onClick={handleCancel}>
                  <H6>취소</H6>
                </CancelBtn>
                <Okbtn onClick={handleOk} okColor={okColor}>
                  <H6>{okText}</H6>
                </Okbtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}

import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Flex, BodyWrapper, H6, Caption1, H2 } from '@components/atoms'
import { share_img_black } from '@images/'
import {
  LinkCopiedPopup,
  OpenInplamEmailPopup,
  OpenInplamPopup,
} from '@components/organisms'
import { colors } from '@colors/'
import { useObserver } from 'mobx-react'
import { converseCommentUnitEng } from '@utils/format'
import { useHistory } from 'react-router-dom'
import { action } from 'mobx'
import { Helmet } from 'react-helmet'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { DOMAIN_URL, STORAGE_URL } from '@consts/'
import {
  arrow_right_outline_24px,
  fluent_arrow_24,
  star_fill_24,
} from '@images/index'
import { useStore } from '@utils/hooks'
import copy from 'copy-to-clipboard'

interface CommmentTemplateProps {
  currentUser: any;
  isLike: boolean;
  uniqueName: string;
  artistInfo: object;
  offset: Number;
  setOffset: Function;
  sortBy: string;
  setSortBy: Function;
  isFromArtist: string;
  toggleFromArtist: Function;
  artistArticleList: Array<any>;
  popularArtistArticleList: Array<any>;
  artistArticleListCount: Number;
  createComment: Function;
  removeComment: Function;
  _fetchCommentList: Function;
  updateCommentLike: Function;
  updateLike: Function;
  showLoginPopup: boolean;
  setShowLoginPopup: Function;
  currentPath: any;
}

const InPlamArtistOpenTemplate = ({
  artistInfo,

  currentPath,
  isLoading,
}: CommmentTemplateProps) => {
  const history = useHistory()
  const { authStore, inplamStore } = useStore()

  const [isOpen, setIsOpen] = useState(false) // 햄버거
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const containerRef = useRef(null)

  const [scrollY, setScrollY] = useState(0) // 현재 스크롤 위치 저장
  const [isShowFloatingButton, setIsShowFloatingButton] = useState(0) // 배경색 투명도
  const [headerOpacity, setHeaderOpacity] = useState(0) // 배경색 투명도
  const [titleOpacity, setTitleOpacity] = useState(0) // 제목 투명도

  const [isFirst, setIsFirst] = useState(true)
  const [isDarkBackground, setIsDarkBackground] = useState(false)
  const imgContainerRef = useRef(null)

  const [imageHeight, setImageHeight] = useState(0)

  const linkCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
  }

  const [accountedInfo, setAccountedInfo] = useState(null)
  const [localInplamOpenRequest, setLocalInplamOpenRequest] = useState(false)

  const {
    isShowing: openPopupIsShowing,
    toggle: openPopupToggle,
  } = OpenInplamPopup.usePopup()

  const {
    isShowing: openEmailPopupIsShowing,
    toggle: openEmailPopupToggle,
  } = OpenInplamEmailPopup.usePopup()

  const handleAccountOpen = async () => {
    if (localInplamOpenRequest || artistInfo?.inplamOpenRequest) {
      return
    }

    // if (!authStore.currentUser) {
    //   needAuth()
    //   return
    // }

    if (authStore?.currentUser) {
      requestOpen()

      openPopupToggle()
    }
    else {
      openEmailPopupToggle()
    }
  }

  const requestOpen = async () => {
    const accounted = await inplamStore.accountOpenRequests(artistInfo?._id)
    if (!accounted) return

    setLocalInplamOpenRequest(true)
    setAccountedInfo(accounted)

    return accounted
  }

  const updatedOpen = async (email, accounted) => {
    const res = await inplamStore.updateOpenRequests(accounted._id, email)

    return res
  }

  useEffect(() => {
    if (!artistInfo) return
    const container = imgContainerRef.current
    if (!container) return

    // 배경 이미지 URL 추출
    const imageUrl = container.src

    if (!imageUrl) return

    // 이미지를 로드한 후 밝기 계산
    const img = new Image()
    img.crossOrigin = 'Anonymous' // CORS 문제 해결
    img.src = imageUrl

    img.onload = () => {
      const calculateBrightness = () => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const headerHeight = 52 // 헤더 높이
        const width = container.offsetWidth

        canvas.width = width
        canvas.height = headerHeight

        // 캔버스에 배경 이미지의 상단 52px만 그리기
        context.drawImage(img, 0, 0, width, headerHeight)

        // 픽셀 데이터 가져오기
        const imageData = context.getImageData(0, 0, width, headerHeight)
        const { data } = imageData
        let r
        let g
        let b
        let avg
        let colorSum = 0

        for (let i = 0; i < data.length; i += 4) {
          r = data[i]
          g = data[i + 1]
          b = data[i + 2]
          avg = (r + g + b) / 3
          colorSum += avg
        }

        const brightness = colorSum / (data.length / 4) // 평균 밝기 계산

        setIsDarkBackground(brightness < 128) // 밝기 기준(128)으로 판단
      }

      calculateBrightness()
    }
  }, [artistInfo])

  useEffect(() => {
    const container = containerRef.current

    const handleScroll = () => {
      const currentScrollY = container.scrollTop
      setScrollY(currentScrollY)

      // 배경색 투명도 계산 (0 ~ 1)
      const maxScroll = 136 // 136px에서 완전히 흰색
      setHeaderOpacity(Math.min(currentScrollY / maxScroll, 1))

      // 제목 투명도 계산 (100px에서 시작, 136px에서 완전히 보임)
      const titleStart = 100
      const titleEnd = 136
      if (currentScrollY >= titleEnd) {
        setTitleOpacity(1)
      }
      else if (currentScrollY <= titleStart) {
        setTitleOpacity(0)
      }
      else {
        setTitleOpacity((currentScrollY - titleStart) / (titleEnd - titleStart))
      }
    }

    container.addEventListener('scroll', handleScroll)

    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const updateOffset = () => {
      if (imgContainerRef.current) {
        setImageHeight(imgContainerRef.current.offsetHeight)
      }
    }

    // 초기 위치 설정
    updateOffset()

    // 창 크기 변경 시 위치 업데이트
    window.addEventListener('resize', updateOffset)
    return () => {
      window.removeEventListener('resize', updateOffset)
    }
  }, [])

  return useObserver(() => (
    <div
      style={{ position: 'relative', overflow: 'auto', height: '100vh' }}
      ref={containerRef}
    >
      <Helmet>
        <title>{`(${artistInfo && artistInfo.name}) 팬스팟`}</title>
        <meta
          name="description"
          content="나의 소중한 아티스트와 소통하는 공간, 팬스팟"
        />
      </Helmet>
      <Header
        className={'header'}
        style={
          {
            backgroundColor: `rgba(255, 255, 255, ${headerOpacity})`,
          }
        }
        justify="space-between"
        align="center"
      >
        <Flex
          style={{ width: '44px', height: '44px', cursor: 'pointer' }}
          align="center"
          justify="center"
          onClick={
            () => {
              history.goBack()
            }
          }
        >
          <PrevButton
            src={fluent_arrow_24}
            isDarkBackground={isDarkBackground}
            headerOpacity={headerOpacity}
          />
        </Flex>
        <HeaderTitle
          style={{ flex: 1, opacity: titleOpacity }}
          align={'center'}
        >
          <H6>{artistInfo?.name}</H6>
          {
artistInfo?.isInplamFavorite && (
              <img
                src={star_fill_24}
                style={{ width: '20px', height: '20px', marginLeft: '2px' }}
                alt="move_icon"
              />
            )
          }
        </HeaderTitle>
      </Header>
      <BodyWrapper
        id="inplam_template"
        style={
          {
            padding: 0,
            paddingBottom: 64,
            minHeight: '100vh',
            marginTop: '-52px',
          }
        }
      >
        <Flex
          type={'column'}
          style={
            {
              height: imageHeight || '420px',
              position: 'relative',
              width: '100%',
              overflow: 'hidden',
            }
          }
          justify={'flex-end'}
        >
          {
            isLoading && isFirst ? (
              <Flex
                style={
                  {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: 'auto',
                    zIndex: 0,
                    background: 'lightgray',
                  }
                }
              />
            ) : (
              <img
                src={`${STORAGE_URL}${artistInfo?.image512Path}`}
                ref={imgContainerRef}
                alt="background"
                style={
                  {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: 'auto',
                    zIndex: 0,
                  }
                }
                onLoad={
                  e => {
                    setImageHeight(e.currentTarget.offsetHeight)
                    setIsFirst(false)
                  }
                }
              />
            )
          }
          <Flex
            type={'column'}
            style={
              {
                height: '100%',
                justifyContent: 'flex-end',
                zIndex: 0,
                background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 1.1%, rgba(255, 255, 255, 0.08) 55.34%, rgba(255, 255, 255, 0.80) 81.37%, #FFF 100%)',
              }
            }
          >
            <ArtistInfoContainer>
              <Flex
                justify={'space-between'}
                align={'center'}
                style={{ width: '100%' }}
              >
                <Flex
                  align={'center'}
                  style={
                    {
                      gap: '4px',
                      marginBottom: '8px',
                      cursor: 'pointer',
                    }
                  }
                  onClick={
                    () => {
                      history.push(`/artist/${artistInfo?.uniqueName}`)
                    }
                  }
                >
                  <ArtistName style={{ fontSize: '28px', lineHeight: '42px' }}>
                    {artistInfo?.name}
                  </ArtistName>
                  <img
                    src={arrow_right_outline_24px}
                    style={{ width: '24px', height: '24px' }}
                    alt="move_icon"
                  />
                </Flex>
                {
artistInfo?.isInplamFavorite && (
                    <img
                      src={star_fill_24}
                      style={{ width: '32px', height: '32px' }}
                      alt="move_icon"
                    />
                  )
                }
              </Flex>
              {
                !isLoading && (
                  <Flex style={{}}>
                    <H6 type="Regular" align="left">
                      {
                        `팬스팟 오픈까지 ${10
                      - (artistInfo?.inplamOpenRequestCount >= 10
                        ? 10
                        : artistInfo?.inplamOpenRequestCount
                          || 0)}명 남았습니다.`
                      }
                    </H6>
                  </Flex>
                )
              }
            </ArtistInfoContainer>
          </Flex>
        </Flex>

        {
          !isLoading && (
            <Flex style={{ padding: '0 20px', marginTop: '8px', gap: '8px' }}>
              <CopyToClipboard
                text={
                  artistInfo && artistInfo.subdomain
                    ? `${artistInfo.subdomain}.${DOMAIN_URL}/fanspot`
                    : currentPath
                }
                onCopy={
                  () => {
                    linkCopy()
                  }
                }
              >
                <Flex
                  style={
                    {
                      width: '44px',
                      height: '44px',
                      cursor: 'pointer',
                      borderRadius: '8px',
                      border: '1px solid var(--grey-grey_30, #F4F4F4)',
                      background: '#FFF',
                    }
                  }
                  justify={'center'}
                  align="center"
                >
                  <ShareButton src={share_img_black} alt="share_icon" />
                </Flex>
              </CopyToClipboard>

              <RequestButton
                isRequested={
                  localInplamOpenRequest || artistInfo?.inplamOpenRequest
                }
                onClick={handleAccountOpen}
              >
                <H6 color={'#fafafa'}>팬스팟 오픈 요청</H6>
              </RequestButton>
            </Flex>
          )
        }
      </BodyWrapper>

      {isCopied && <LinkCopiedPopup />}
      <OpenInplamPopup.View
        isShowing={openPopupIsShowing}
        toggle={openPopupToggle}
        onOk={
          async () => {
            const result = copy(
              `${
                artistInfo && artistInfo.subdomain
                  ? `${artistInfo.subdomain}.${DOMAIN_URL}/fanspot`
                  : currentPath
              }`,
            )
            if (result) {
              linkCopy()
            }
          }
        }
      />
      <OpenInplamEmailPopup.View
        isShowing={openEmailPopupIsShowing}
        toggle={openEmailPopupToggle}
        updatedOpen={updatedOpen}
        toggleComplte={openPopupToggle}
        requestOpen={requestOpen}
      />
    </div>
  ))
}

export default InPlamArtistOpenTemplate

const Header = styled(Flex)`
  height: 52px;
  padding-right: 8px;
  position: sticky;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  transition: background-color 0.3s ease; /* 배경색 부드럽게 전환 */
`

const HeaderTitle = styled(Flex)`
  color: #000;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(0);
`

const ArtistInfoContainer = styled(Flex)`
  display: flex;
  padding: 20px 20px 16px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`

const FromArtist = styled(Flex)`
  font-size: 12px;
  align-items: center;
  padding: 7px 10px;
  background-color: ${colors.whitef4};
  color: ${colors.light_pink};
  cursor: pointer;
  width: 88px;
  box-sizing: border-box;
  height: 32px;

  ${props =>
    props.isFromArtist === 'artist'
    && css`
      background-color: ${colors.black};
      color: ${colors.white};
    `}
`

const ArtistName = styled(H2)`
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  width: 100%;
`

/* popular List */
const ListBox = styled(Flex)`
  margin: 10px 0px;
  flex-direction: column;
  padding: 14px 16px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
`

const PopularInPlamList = styled(Flex)`
  flex-direction: column;
  /* height: 90px; */
  height: fit-content;
  max-height: 90px;
  overflow: auto;
`

const InPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  min-height: 30px;
  align-items: center;
  box-sizing: border-box;
`

const TitleText = styled(Caption1)`
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 100%;
`

const CommentCountText = styled(Caption1)`
  font-family: Pretendard-Regular;
  margin-left: 5px;
  min-width: 48px;
  max-width: 48px;
  padding: 3px 9px;
  border: solid 1px ${colors.light_pink};
  border-radius: 12px;
  height: 24px;
  box-sizing: border-box;
  margin-left: 7px;
  line-height: 1.35;
`

const PrevButton = styled.img`
  width: 32px;
  height: 32px;

  filter: ${props =>
    props.isDarkBackground && props.headerOpacity < 0.2
      ? `invert(1)`
      : `invert(0)`};
`

const ShareButton = styled.img`
  width: 24px;
  height: 24px;
`
const RequestButton = styled(Flex)`
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #242424;
  flex: 1;
  box-sizing: border-box;
  cursor: pointer;

  ${({ isRequested }) =>
    isRequested
    && css`
      opacity: 0.16;
      background: #242424;
      pointer-events: none;
    `}
`

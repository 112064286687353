import React from 'react'
import { TrackItem } from '@components/molecules'

const TrackList = ({ list, lang }) => {
  return (
    <div>
      {
        list
        && list
          .filter(elem => !!elem)
          .map((item, index) => (
            <TrackItem
              item={item}
              index={index}
              key={index}
              artistName={item && item.releaseArtistName}
              lang={lang}
            />
          ))
      }
    </div>
  )
}

export default TrackList

import { Caption1, Flex, H6 } from '@components/atoms'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import { FanspotScoreRankingListItem } from '@components/molecules'
import { move_down_gray_img } from '@images/'
import Pagination from 'react-js-pagination'

const FanspotScoreRankingList = ({
  style,
  offset,
  handlePagination,
  sortBy,
  handleChangeSortBy,
  artistFanSpotPointRankList,
  artistInfo,
  handleMoveDetail,
}) => {
  return (
    <Flex type="column" style={style && style}>
      <Header>
        <Caption1
          style={{ width: '48px', paddingLeft: '7px' }}
          type="Medium"
          color={'#646464'}
          align="center"
        >
          {`순위`}
        </Caption1>
        <Caption1
          style={{ flex: 1, padding: '0 5px' }}
          type="Medium"
          color={'#646464'}
          align="left"
        >
          {`팬`}
        </Caption1>
        <Caption1
          style={{ width: '104px' }}
          type="Medium"
          color={'#646464'}
          align="center"
        >
          {`스코어`}
        </Caption1>
      </Header>
      <Br />
      {
artistFanSpotPointRankList?.map(item => (
  <FanspotScoreRankingListItem
    item={item}
    key={item._id}
    artistInfo={artistInfo}
  />
))
      }
      {/* <Pagination
        activePage={offset + 1}
        itemsCountPerPage={10}
        totalItemsCount={artistArticleListCount || 0}
        pageRangeDisplayed={5}
        onChange={
          page => {
            handlePagination(page - 1)
          }
        }
        innerClass="pagination-ul"
        activeClass="current-page"
        itemClass="pagination-li"
        // hideFirstLastPages
        hideNavigation
        // prevPageText={false}
        // nextPageText={false}
        firstPageText={
          <PrevNextPage
            src={offset + 1 !== 1 ? first_page_img : no_first_page_img}
          />
        }
        lastPageText={
          <PrevNextPage
            src={
              offset + 1 !== Math.ceil(artistArticleListCount / 10)
                ? last_page_img
                : no_last_page_img
            }
          />
        }
      /> */}
    </Flex>
  )
}

export default FanspotScoreRankingList

const Header = styled(Flex)`
  align-items: center;
  margin-left: -20px;
  width: calc(100% + 40px);
  /* justify-content: space-between; */
  height: 42px;
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: solid 1px ${colors.whitef4};
`

const Select = styled.select`
  font-size: 12px;
  font-family: Pretendard-Medium;
  -webkit-appearance: none;
  border: none;
  color: ${colors.brown_grey};

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-position-x: 38px;
  background-size: 12px;
  background-repeat: no-repeat;
  width: 50px;
  display: block;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`

const PrevNextPage = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`

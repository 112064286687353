import styled from 'styled-components'
import { mobile, smallMobile } from '@styles/media'

/**
 * default css 각각 디자인과 함께 맞춰놓은 font-size 별
 * H1 ~ H6, Caption1, 2
 * @props
 * align : text-align value;
 * type : font-family :`Pretendard-${type}`
 * color : color value;
 */
export const H1 = styled.h1`
  width: 100%;
  text-align: ${props => (props.align ? props.align : 'center')};
  word-break: keep-all;
  line-height: 1.47;
  letter-spacing: normal;
  margin: 0;
  padding: 0;

  font-family: ${props =>
    props.type ? `Pretendard-${props.type}` : 'Pretendard-Bold'};
  color: ${props => props.color};

  font-size: 40px;
`

export const H2 = styled.h2`
  width: 100%;
  text-align: ${props => (props.align ? props.align : 'center')};
  word-break: keep-all;
  line-height: 1.6;
  letter-spacing: normal;
  margin: 0;
  padding: 0;

  font-family: ${props =>
    props.type ? `Pretendard-${props.type}` : 'Pretendard-Bold'};
  color: ${props => props.color};

  font-size: 30px;
`

export const H3 = styled.h3`
  text-align: ${props => (props.align ? props.align : 'center')};
  margin: 0;
  padding: 0;
  font-family: ${props =>
    props.type ? `Pretendard-${props.type}` : 'Pretendard-Bold'};
  color: ${props => props.color};

  font-size: 26px;

  @media ${smallMobile} {
    font-size: 26px;
    white-space: nowrap;
  }
`

export const H4 = styled.h4`
  text-align: ${props => (props.align ? props.align : 'center')};
  margin: 0;
  padding: 0;
  font-family: ${props =>
    props.type ? `Pretendard-${props.type}` : 'Pretendard-Medium'};
  color: ${props => props.color};

  font-size: 20px;
  line-height: 28px;

  @media ${smallMobile} {
    font-size: 20px;
    white-space: nowrap;
  }
`

export const H5 = styled.h5`
  text-align: ${props => (props.align ? props.align : 'center')};
  word-break: keep-all;
  margin: 0;
  padding: 0;

  font-family: ${props =>
    props.type ? `Pretendard-${props.type}` : 'Pretendard-Medium'};
  color: ${props => props.color};

  font-size: 16px;
  line-height: 24px;
`

export const H6 = styled.h6`
  width: auto;
  text-align: ${props => (props.align ? props.align : 'center')};
  word-break: keep-all;
  font-weight: 500;
  margin: 0;
  padding: 0;

  font-family: ${props =>
    props.type ? `Pretendard-${props.type}` : 'Pretendard-Medium'};
  color: ${props => props.color};

  font-size: 14px;
  line-height: 24px;
`

export const Caption1 = styled.p`
  display: inline-block;
  text-align: ${props => (props.align ? props.align : 'center')};
  margin: 0;
  padding: 0;
  font-family: ${props =>
    props.type ? `Pretendard-${props.type}` : 'Pretendard-Medium'};
  color: ${props => props.color};

  font-size: 13px;
  line-height: 16px;
`

export const Caption2 = styled.p`
  display: inline-block;
  text-align: ${props => (props.align ? props.align : 'center')};
  margin: 0;
  padding: 0;
  font-family: ${props =>
    props.type ? `Pretendard-${props.type}` : 'Pretendard-Medium'};
  color: ${props => props.color};

  font-size: 11px;
`

import React, { useEffect, useState } from 'react'

import { Flex } from '@components/atoms'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { StramingButton } from '@components/molecules'
import YouTube from 'react-youtube'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import YoutubeUrlList from './YoutubeUrlList'
import NoYoutubePopup from '../Popup/NoYoutubePopup'

const LinkList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 332px;
  margin-left: -6px;
  flex-wrap: wrap;
  align-self: flex-start;

  div:first-child,
  div:nth-child(6n) {
    margin-left: 0px !important;
  }

  div:last-child,
  div:nth-child(5n) {
    margin-right: 0px !important;
  }
`

const StreamingList = ({
  youtube,
  youtubeUrlList,
  melonDeepLink,
  melon,
  genie,
  bugs,
  flo,
  vibe,
  youtubeMusic,
  appleMusic,
  spotify,
  amazonMusic,
  updateRecord,
  tidalUrl,
  deezerUrl,
  jooxUrl,
  mymusicUrl,
  kkboxUrl,
  linejpUrl,
  linetwUrl,
  yandexUrl,
  nctUrl,
  zingUrl,
  anghmiUrl,
  isPlayFirstYoutube,
  artistId,
  artistName,
  track,
  lang,
}) => {
  const [youtubeFirstPlay, setYoutubeFirstPlay] = useState(true) // youtube player 첫 재생 유무

  const [canPlay, setCanPlay] = useState(false) // 링크 복사 유무
  const opts = {
    playerVars: {
      autoplay: 0,
      controls: 1,
      autohide: 1,
      rel: 0,
      showinfo: 0,
      origin: document.domain,
      fs: 1,
      playsinline: 1,
    },
    host: 'https://www.youtube.com',
  }

  useEffect(() => {
    if (isPlayFirstYoutube && !youtube && youtubeUrlList?.length === 0) {
      setCanPlay(true)
    }
  }, [])

  const _onReady = (e, index) => {
    // access to player in all event handlers via e.target

    if (index === 0 && isPlayFirstYoutube) {
      e.target.playVideo()
    }
    else {
      e.target.pauseVideo()
      setYoutubeFirstPlay(false)
    }
  }

  const _onPlay = e => {
    if (!youtubeFirstPlay) {
      updateRecord('youtubePlay', youtube)
      setYoutubeFirstPlay(true)
    }
  }

  const _onPlayList = url => {
    if (!youtubeFirstPlay) {
      updateRecord('youtubePlay', url)
      setYoutubeFirstPlay(true)
    }
  }

  return (
    <>
      <Flex type="column" style={{ marginTop: '20px' }}>
        {
youtubeUrlList?.length > 0 ? (
  <YoutubeUrlList
    list={youtubeUrlList}
    opts={opts}
    onReady={_onReady}
    onPlay={_onPlayList}
  />
) : (
  youtube && (
    <div style={{ padding: '10px 0px' }}>
      <YouTube
        videoId={youtube.split('watch?v=')[1]}
        opts={opts}
        onReady={_onReady}
        onPlay={_onPlay}
        containerClassName="youtube_wrapper"
        className="youtube_player"
      />
    </div>
  )
)
        }
        <LinkList>
          {
            spotify && (
              <StramingButton
                type="spotify"
                link={spotify}
                onClick={
                  () => {
                    updateRecord('spotifyUrl', spotify)
                    console.log('track', track)
                    if (track) {
                      pixelTrack(PIXEL_EVENTS?.트랙?.스포티파이, {
                        trackId: track._id,
                        trackTitle: track.title,
                        artistId: track.artistId,
                        artistName: track.releaseArtistList?.[0]?.name,
                      })
                    }
                    else {
                      pixelTrack(PIXEL_EVENTS?.아티스트?.스포티파이, {
                        artistId,
                        artistName,
                      })
                    }
                  }
                }
              />
            )
          }
          {
            youtubeMusic && (
              <StramingButton
                type="youtube"
                link={youtubeMusic}
                onClick={
                  () => {
                    updateRecord('youtubeMusicUrl', youtubeMusic)
                    if (track) {
                      pixelTrack(PIXEL_EVENTS?.트랙?.유튜브뮤직, {
                        trackId: track._id,
                        trackTitle: track.title,
                        artistId: track.artistId,
                        artistName: track.releaseArtistList?.[0]?.name,
                      })
                    }
                    else {
                      pixelTrack(PIXEL_EVENTS?.아티스트?.유튜브뮤직, {
                        artistId,
                        artistName,
                      })
                    }
                  }
                }
              />
            )
          }
          {
            melon && lang === 'ko' && (
              <StramingButton
                type="melon"
                link={melonDeepLink ? melonDeepLink : melon}
                onClick={
                  () => {
                    updateRecord('melonUrl', melon)
                    if (track) {
                      pixelTrack(PIXEL_EVENTS?.트랙?.멜론, {
                        trackId: track._id,
                        trackTitle: track.title,
                        artistId: track.artistId,
                        artistName: track.releaseArtistList?.[0]?.name,
                      })
                    }
                    else {
                      pixelTrack(PIXEL_EVENTS?.아티스트?.멜론, {
                        artistId,
                        artistName,
                      })
                    }
                  }
                }
              />
            )
          }
          {
            genie && lang === 'ko' && (
              <StramingButton
                type="genie"
                link={genie}
                onClick={
                  () => {
                    updateRecord('genieUrl', genie)
                    if (track) {
                      pixelTrack(PIXEL_EVENTS?.트랙?.지니, {
                        trackId: track._id,
                        trackTitle: track.title,
                        artistId: track.artistId,
                        artistName: track.releaseArtistList?.[0]?.name,
                      })
                    }
                    else {
                      pixelTrack(PIXEL_EVENTS?.아티스트?.지니, {
                        artistId,
                        artistName,
                      })
                    }
                  }
                }
              />
            )
          }
          {
            bugs && lang === 'ko' && (
              <StramingButton
                type="bugs"
                link={bugs}
                onClick={
                  () => {
                    updateRecord('bugsUrl', bugs)
                    if (track) {
                      pixelTrack(PIXEL_EVENTS?.트랙?.벅스, {
                        trackId: track._id,
                        trackTitle: track.title,
                        artistId: track.artistId,
                        artistName: track.releaseArtistList?.[0]?.name,
                      })
                    }
                    else {
                      pixelTrack(PIXEL_EVENTS?.아티스트?.벅스, {
                        artistId,
                        artistName,
                      })
                    }
                  }
                }
              />
            )
          }
          {
            vibe && lang === 'ko' && (
              <StramingButton
                type="vibe"
                link={vibe}
                onClick={
                  () => {
                    updateRecord('vibeUrl', vibe)
                    if (track) {
                      pixelTrack(PIXEL_EVENTS?.트랙?.바이브, {
                        trackId: track._id,
                        trackTitle: track.title,
                        artistId: track.artistId,
                        artistName: track.releaseArtistList?.[0]?.name,
                      })
                    }
                    else {
                      pixelTrack(PIXEL_EVENTS?.아티스트?.바이브, {
                        artistId,
                        artistName,
                      })
                    }
                  }
                }
              />
            )
          }
          {
            flo && lang === 'ko' && (
              <StramingButton
                type="flo"
                link={flo}
                onClick={
                  () => {
                    updateRecord('floUrl', flo)
                    if (track) {
                      pixelTrack(PIXEL_EVENTS?.트랙?.플로, {
                        trackId: track._id,
                        trackTitle: track.title,
                        artistId: track.artistId,
                        artistName: track.releaseArtistList?.[0]?.name,
                      })
                    }
                    else {
                      pixelTrack(PIXEL_EVENTS?.아티스트?.플로, {
                        artistId,
                        artistName,
                      })
                    }
                  }
                }
              />
            )
          }
          {
            appleMusic && (
              <StramingButton
                type="applemusic"
                link={appleMusic}
                onClick={
                  () => {
                    updateRecord('appleMusicUrl', appleMusic)
                    if (track) {
                      pixelTrack(PIXEL_EVENTS?.트랙?.애플뮤직, {
                        trackId: track._id,
                        trackTitle: track.title,
                        artistId: track.artistId,
                        artistName: track.releaseArtistList?.[0]?.name,
                      })
                    }
                    else {
                      pixelTrack(PIXEL_EVENTS?.아티스트?.애플뮤직, {
                        artistId,
                        artistName,
                      })
                    }
                  }
                }
              />
            )
          }
          {
            amazonMusic && (
              <StramingButton
                type="amazon"
                link={amazonMusic}
                onClick={
                  () => {
                    updateRecord('amazonMusicUrl', amazonMusic)
                    if (track) {
                      pixelTrack(PIXEL_EVENTS?.트랙?.아마존뮤직, {
                        trackId: track._id,
                        trackTitle: track.title,
                        artistId: track.artistId,
                        artistName: track.releaseArtistList?.[0]?.name,
                      })
                    }
                    else {
                      pixelTrack(PIXEL_EVENTS?.아티스트?.아마존뮤직, {
                        artistId,
                        artistName,
                      })
                    }
                  }
                }
              />
            )
          }
          {
            tidalUrl && (
              <StramingButton
                type="tidalUrl"
                link={tidalUrl}
                onClick={
                  () => {
                    updateRecord('tidalUrl', tidalUrl)
                    if (track) {
                      pixelTrack(PIXEL_EVENTS?.트랙?.타이달, {
                        trackId: track._id,
                        trackTitle: track.title,
                        artistId: track.artistId,
                        artistName: track.releaseArtistList?.[0]?.name,
                      })
                    }
                    else {
                      pixelTrack(PIXEL_EVENTS?.아티스트?.타이달, {
                        artistId,
                        artistName,
                      })
                    }
                  }
                }
              />
            )
          }
          {
            deezerUrl && (
              <StramingButton
                type="deezerUrl"
                link={deezerUrl}
                onClick={
                  () => {
                    updateRecord('deezerUrl', deezerUrl)
                  }
                }
              />
            )
          }
          {
            jooxUrl && (
              <StramingButton
                type="jooxUrl"
                link={jooxUrl}
                onClick={
                  () => {
                    updateRecord('jooxUrl', jooxUrl)
                  }
                }
              />
            )
          }
          {
            mymusicUrl && (
              <StramingButton
                type="mymusicUrl"
                link={mymusicUrl}
                onClick={
                  () => {
                    updateRecord('mymusicUrl', mymusicUrl)
                  }
                }
              />
            )
          }
          {
            kkboxUrl && (
              <StramingButton
                type="kkboxUrl"
                link={kkboxUrl}
                onClick={
                  () => {
                    updateRecord('kkboxUrl', kkboxUrl)
                  }
                }
              />
            )
          }
          {
            linejpUrl && (
              <StramingButton
                type="linejpUrl"
                link={linejpUrl}
                onClick={() => updateRecord('linejpUrl', linejpUrl)}
              />
            )
          }
          {
            linetwUrl && (
              <StramingButton
                type="linetwUrl"
                link={linetwUrl}
                onClick={() => updateRecord('linetwUrl', linetwUrl)}
              />
            )
          }
          {
            yandexUrl && (
              <StramingButton
                type="yandexUrl"
                link={yandexUrl}
                onClick={() => updateRecord('yandexUrl', yandexUrl)}
              />
            )
          }
          {
            nctUrl && (
              <StramingButton
                type="nctUrl"
                link={nctUrl}
                onClick={() => updateRecord('nctUrl', nctUrl)}
              />
            )
          }
          {
            zingUrl && (
              <StramingButton
                type="zingUrl"
                link={zingUrl}
                onClick={() => updateRecord('zingUrl', zingUrl)}
              />
            )
          }
          {
            anghmiUrl && (
              <StramingButton
                type="anghmiUrl"
                link={anghmiUrl}
                onClick={() => updateRecord('anghmiUrl', anghmiUrl)}
              />
            )
          }
        </LinkList>
      </Flex>
      {canPlay && <NoYoutubePopup />}
    </>
  )
}

export default StreamingList

import { Flex, H5 } from '@components/atoms'
import React, { useEffect } from 'react'

import ArtistSmartLinkItem from '../Card/ArtistSmartLinkItem'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { observer } from 'mobx-react'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import { useStore } from '@utils/hooks'

const ArtistSmartLinkInfo = ({ updateRecord }) => {
  const { artistStore } = useStore()

  const fetchSmartLinkList = artistStore?.fetchSmartLinkList || (() => {})
  const { artistDetail, artistSmartLinkList } = artistStore

  const handleLinkClick = url => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    if (emailPattern.test(url)) {
      // 이메일 형식인 경우
      window.location.href = `mailto:${url}`
    } else {
      window.open(url)
    }
    updateRecord('smartLinkUrl', url)
  }

  useEffect(() => {
    if (artistDetail) fetchSmartLinkList()
  }, [artistDetail, fetchSmartLinkList])

  if (artistSmartLinkList?.length === 0) return <></>
  return (
    <Flex type="column" style={{ gap: 12, margin: '20px 0' }}>
      {artistSmartLinkList?.map(link => (
        <ArtistSmartLinkItem
          link={link}
          key={link?._id}
          onClick={() => {
            handleLinkClick(link?.linkUrl)
            pixelTrack(PIXEL_EVENTS?.아티스트?.스마트링크, {
              artistId: artistDetail?._id,
              title: link?.title,
              linkUrl: link?.linkUrl,
            })
          }}
        />
      ))}
    </Flex>
  )
}

export default observer(ArtistSmartLinkInfo)

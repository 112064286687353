import React from 'react'
import styled from 'styled-components'

import { Flex, H6, Caption1 } from '@components/atoms'
import { colors } from '@colors/'
import { converseCommentUnitEng } from '@utils/format'
import { useHistory } from 'react-router-dom'

const TotalInplamHeadList = ({
  popularTotalArticleList,
  noticeArticleList,
}) => {
  const history = useHistory()

  if (
    popularTotalArticleList
    && noticeArticleList
    && popularTotalArticleList.length > 0
    && noticeArticleList.length > 0
  ) {
    return (
      <Flex type="column">
        {
popularTotalArticleList?.length > 0 && (
            <ListBox>
              <Flex
                align="center"
                style={{ marginBottom: 10 }}
                justify="space-between"
              >
                <Flex>
                  <H6
                    align="left"
                    type="Bold"
                    color="#242424"
                    style={{ marginRight: 16 }}
                  >
                  인기 글
                  </H6>
                </Flex>
              </Flex>
              <PopularInPlamList>
                <PopularInPlamListItem
                  onClick={
                    () =>
                      history.push(
                        `/fanspot/total/${popularTotalArticleList[0]._id}`,
                      )
                  }
                >
                  <TitleText>{popularTotalArticleList[0].title}</TitleText>
                  <CommentCountText>
                    {
                      popularTotalArticleList[0].commentCount
                    && converseCommentUnitEng(
                      popularTotalArticleList[0].commentCount,
                    )
                    }
                  </CommentCountText>
                </PopularInPlamListItem>
                {
                  popularTotalArticleList[1] && (
                    <PopularInPlamListItem
                      onClick={
                        () =>
                          history.push(
                            `/fanspot/total/${popularTotalArticleList[1]._id}`,
                          )
                      }
                    >
                      <TitleText>{popularTotalArticleList[1].title}</TitleText>
                      <CommentCountText>
                        {
                          popularTotalArticleList[1].commentCount
                      && converseCommentUnitEng(
                        popularTotalArticleList[1].commentCount,
                      )
                        }
                      </CommentCountText>
                    </PopularInPlamListItem>
                  )
                }
                {
                  popularTotalArticleList[2] && (
                    <PopularInPlamListItem
                      onClick={
                        () =>
                          history.push(
                            `/fanspot/total/${popularTotalArticleList[2]._id}`,
                          )
                      }
                    >
                      <TitleText>{popularTotalArticleList[2].title}</TitleText>
                      <CommentCountText>
                        {
                          popularTotalArticleList[2].commentCount
                      && converseCommentUnitEng(
                        popularTotalArticleList[2].commentCount,
                      )
                        }
                      </CommentCountText>
                    </PopularInPlamListItem>
                  )
                }
              </PopularInPlamList>
            </ListBox>
          )
        }
        {
noticeArticleList?.length > 0 && (
            <ListBox>
              <Flex
                align="center"
                style={{ marginBottom: 10 }}
                justify="space-between"
              >
                <Flex>
                  <H6
                    align="left"
                    type="Bold"
                    color="#242424"
                    style={{ marginRight: 16 }}
                  >
                  공지 사항
                  </H6>
                </Flex>
                <Caption1
                  onClick={() => history.push('/fanspot/notice')}
                  align="right"
                  color={colors.brown_grey}
                  style={{ cursor: 'pointer' }}
                >
                더 보기
                </Caption1>
              </Flex>
              <PopularInPlamList>
                <PopularInPlamListItem
                  onClick={
                    () =>
                      history.push(`/fanspot/total/${noticeArticleList[0]._id}`)
                  }
                >
                  <TitleText>{noticeArticleList[0].title}</TitleText>
                </PopularInPlamListItem>
                {
                  noticeArticleList[1] && (
                    <PopularInPlamListItem
                      onClick={
                        () =>
                          history.push(`/fanspot/total/${noticeArticleList[1]._id}`)
                      }
                    >
                      <TitleText>{noticeArticleList[1].title}</TitleText>
                    </PopularInPlamListItem>
                  )
                }
                {
                  noticeArticleList[2] && (
                    <PopularInPlamListItem
                      onClick={
                        () =>
                          history.push(`/fanspot/total/${noticeArticleList[2]._id}`)
                      }
                    >
                      <TitleText>{noticeArticleList[2].title}</TitleText>
                    </PopularInPlamListItem>
                  )
                }
              </PopularInPlamList>
            </ListBox>
          )
        }
      </Flex>
    )
  }
  return <></>
}

export default TotalInplamHeadList

/* popular List */
const ListBox = styled(Flex)`
  margin: 10px 0px;
  flex-direction: column;
  padding: 14px 16px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
`

const PopularInPlamList = styled(Flex)`
  flex-direction: column;
  /* height: 90px; */
  height: fit-content;
  max-height: 90px;
  overflow: auto;
`

const PopularInPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  min-height: 30px;
  align-items: center;
  box-sizing: border-box;
`

const TitleText = styled(Caption1)`
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 100%;
`

const CommentCountText = styled(Caption1)`
  font-family: Pretendard-Regular;
  margin-left: 5px;
  min-width: 48px;
  max-width: 48px;
  padding: 3px 9px;
  border: solid 1px ${colors.light_pink};
  border-radius: 12px;
  height: 24px;
  box-sizing: border-box;
  margin-left: 7px;
  line-height: 1.35;
`

/* popular List end */

// import React, { useState } from 'react'
// import styled from 'styled-components'
// import SwipeableViews from 'react-swipeable-views'

// import { Flex, H6, Caption1 } from '@components/atoms'
// import { colors } from '@colors/'
// import { converseCommentUnitEng } from '@utils/format'
// import { useHistory } from 'react-router-dom'

// const TotalInplamHeadList = ({
//   popularTotalArticleList,
//   noticeArticleList,
// }) => {
//   const history = useHistory()
//   const [swipeIdx, setSwipeIdx] = useState(0)

//   if (
//     popularTotalArticleList
//     && noticeArticleList
//     && popularTotalArticleList.length > 0
//     && noticeArticleList.length > 0
//   ) {
//     return (
//       <ListBox>
//         <Flex
//           align="center"
//           style={{ marginBottom: 10 }}
//           justify="space-between"
//         >
//           <Flex>
//             <H6
//               align="left"
//               type={swipeIdx === 0 ? 'Bold' : 'Medium'}
//               color={swipeIdx === 0 ? '#242424' : '#d4d4d4'}
//               onClick={() => setSwipeIdx(0)}
//               style={{ marginRight: 16 }}
//             >
//               인기 글
//             </H6>
//             <H6
//               align="left"
//               type={swipeIdx === 1 ? 'Bold' : 'Medium'}
//               color={swipeIdx === 1 ? '#242424' : '#d4d4d4'}
//               onClick={() => setSwipeIdx(1)}
//             >
//               공지 사항
//             </H6>
//           </Flex>
//           {
//             swipeIdx === 1 && (
//               <Caption1
//                 onClick={() => history.push('/fanspot/notice')}
//                 align="right"
//                 color={colors.brown_grey}
//                 style={{ cursor: 'pointer' }}
//               >
//               더 보기
//               </Caption1>
//             )
//           }
//         </Flex>
//         <SwipeableViews
//           enableMouseEvents
//           // action={actions => setSwipeableActions(actions)}
//           index={swipeIdx}
//           onChangeIndex={setSwipeIdx}
//           resistance
//           animateHeight
//         >
//           <PopularInPlamList>
//             <PopularInPlamListItem
//               onClick={
//                 () =>
//                   history.push(`/fanspot/total/${popularTotalArticleList[0]._id}`)
//               }
//             >
//               <TitleText>{popularTotalArticleList[0].title}</TitleText>
//               <CommentCountText>
//                 {
//                   popularTotalArticleList[0].commentCount
//                   && converseCommentUnitEng(
//                     popularTotalArticleList[0].commentCount,
//                   )
//                 }
//               </CommentCountText>
//             </PopularInPlamListItem>
//             {
//               popularTotalArticleList[1] && (
//                 <PopularInPlamListItem
//                   onClick={
//                     () =>
//                       history.push(
//                         `/fanspot/total/${popularTotalArticleList[1]._id}`,
//                       )
//                   }
//                 >
//                   <TitleText>{popularTotalArticleList[1].title}</TitleText>
//                   <CommentCountText>
//                     {
//                       popularTotalArticleList[1].commentCount
//                     && converseCommentUnitEng(
//                       popularTotalArticleList[1].commentCount,
//                     )
//                     }
//                   </CommentCountText>
//                 </PopularInPlamListItem>
//               )
//             }
//             {
//               popularTotalArticleList[2] && (
//                 <PopularInPlamListItem
//                   onClick={
//                     () =>
//                       history.push(
//                         `/fanspot/total/${popularTotalArticleList[2]._id}`,
//                       )
//                   }
//                 >
//                   <TitleText>{popularTotalArticleList[2].title}</TitleText>
//                   <CommentCountText>
//                     {
//                       popularTotalArticleList[2].commentCount
//                     && converseCommentUnitEng(
//                       popularTotalArticleList[2].commentCount,
//                     )
//                     }
//                   </CommentCountText>
//                 </PopularInPlamListItem>
//               )
//             }
//           </PopularInPlamList>
//           <PopularInPlamList>
//             <PopularInPlamListItem
//               onClick={
//                 () =>
//                   history.push(`/fanspot/total/${noticeArticleList[0]._id}`)
//               }
//             >
//               <TitleText>{noticeArticleList[0].title}</TitleText>
//             </PopularInPlamListItem>
//             {
//               noticeArticleList[1] && (
//                 <PopularInPlamListItem
//                   onClick={
//                     () =>
//                       history.push(`/fanspot/total/${noticeArticleList[1]._id}`)
//                   }
//                 >
//                   <TitleText>{noticeArticleList[1].title}</TitleText>
//                 </PopularInPlamListItem>
//               )
//             }
//             {
//               noticeArticleList[2] && (
//                 <PopularInPlamListItem
//                   onClick={
//                     () =>
//                       history.push(`/fanspot/total/${noticeArticleList[2]._id}`)
//                   }
//                 >
//                   <TitleText>{noticeArticleList[2].title}</TitleText>
//                 </PopularInPlamListItem>
//               )
//             }
//           </PopularInPlamList>
//         </SwipeableViews>
//       </ListBox>
//     )
//   }
//   return <></>
// }

// export default TotalInplamHeadList

// /* popular List */
// const ListBox = styled(Flex)`
//   margin: 10px 0px;
//   flex-direction: column;
//   padding: 14px 16px;
//   border-radius: 10px;
//   box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
// `

// const PopularInPlamList = styled(Flex)`
//   flex-direction: column;
//   /* height: 90px; */
//   height: fit-content;
//   max-height: 90px;
//   overflow: auto;
// `

// const PopularInPlamListItem = styled(Flex)`
//   cursor: pointer;
//   width: 100%;
//   min-height: 30px;
//   align-items: center;
//   box-sizing: border-box;
// `

// const TitleText = styled(Caption1)`
//   width: 100%;
//   text-align: left;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
//   word-wrap: break-word;
//   width: 100%;
// `

// const CommentCountText = styled(Caption1)`
//   font-family: Pretendard-Regular;
//   margin-left: 5px;
//   min-width: 48px;
//   max-width: 48px;
//   padding: 3px 9px;
//   border: solid 1px ${colors.light_pink};
//   border-radius: 12px;
//   height: 24px;
//   box-sizing: border-box;
//   margin-left: 7px;
//   line-height: 1.35;
// `

// /* popular List end */

import {
  ArtistCard,
  ArtistInPlamList,
  ArtistInfo,
  BurgerMenu,
  LikePopup,
  LoginPopup,
  OpenInplamCard,
  StreamingList,
} from '@components/organisms'
import { BodyWrapper, Flex, H6 } from '@components/atoms'
import React, { useEffect, useState } from 'react'
import { observer, useObserver } from 'mobx-react'

import ArtistSmartLinkInfo from '@components/organisms/Info/ArtistSmartLinkInfo'
import ArtistSnsFollowerList from '@components/organisms/List/ArtistSnsFollowerList'
import { DetailCoverImage } from '@components/molecules'
import { Helmet } from 'react-helmet'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { STORAGE_URL } from '@consts/'
import { default_profile_img } from '@images'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import { toJS } from 'mobx'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'

const BackWrapper = styled.div`
  background-color: rgb(36, 36, 36);
  position: relative;
  width: calc(100% + 40px);
  margin: -20px 0 0 -20px;
  padding-bottom: calc(100% + 40px);
  z-index: 2;
`

const BackColor = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${({ background }) => background && `'${background}'`});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0px 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TopGradient = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 70%;
  background-image: linear-gradient(
    to bottom,
    rgba(36, 36, 36, 0.6) 0%,
    rgba(170, 170, 170, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
`

const BottomGradient = styled.div`
    position: absolute;
    top:30%;
    width: 100%;
    height: 70%;
  background-image: linear-gradient(
    to top,
    rgba(36, 36, 36, 0.6) 0%,
    rgba(170, 170, 170, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}
`

const ArtistTemplate = ({
  artist,
  currentPath,
  recordId,
  updateRecord,
  currentUser,
  logout,

  fetchLike,
  likeInfo,
  updateLike,

  artistFollowerInfo,
  lang,
}) => {
  const history = useHistory()

  const { trendStore, authStore } = useStore()
  const { artistTrend } = trendStore
  const fetchArtistTrend = trendStore.fetchArtistTrend || (() => {})

  const followersInfo = artistTrend?.followersInfo || null
  const listenerInfo = artistTrend?.listenerInfo || null
  // const isLoginedArtist = (authStore?.currentUser?.type === 'artist' || authStore?.currentUser?.type === 'company') || false
  const isLogined = !!authStore?.currentUser || false

  // const fetchLike = authStore.fetchLike || (() => { })
  // const updateLike = authStore.updateLike || (() => { })
  // const { likeInfo } = authStore

  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false) // 로그인 상태
  const [showLikePopup, setShowLikePopup] = useState(false) // 좋아요 알림 팝업

  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const [isDetailCover, setIsDetailCover] = useState(false) // 상세 이미지

  useEffect(() => {
    if (artist && !currentUser) {
      fetchLike(artist._id)
    }
    else if (artist && currentUser) {
      fetchLike(artist._id, currentUser._id)
    }
  }, [artist, currentUser])

  useEffect(() => {
    if (artist) {
      fetchArtistTrend(artist._id, { __periodDays: 7 })
    }
  }, [artist])

  return (
    <>
      <Helmet>
        <title>
          {artist && `${artist.stageName}(@${artist.uniqueName}) | 플램`}
        </title>
        <meta
          name="description"
          content={`${artist && artist.stageName}의 음악에 한걸음 더, 플램`}
        />
      </Helmet>
      <BurgerMenu
        isOpen={isOpen}
        setIsOpen={
          value => {
            setIsOpen(value)
            if (value) {
              pixelTrack(PIXEL_EVENTS.아티스트.옵션, {
                artistId: artist?._id,
                artistName: artist?.name,
              })
            }
          }
        }
        pageTitle={artist && artist.stageName}
        pageWrapId={'artist_template'}
      />
      <BodyWrapper>
        {
          isShowLoginPopup && (
            <LoginPopup handleClose={() => setIsShowLoginPopup(false)} />
          )
        }
        {
          showLikePopup && !authStore?.isNeverShowLikeArtistPopup && (
            <LikePopup
              handleClose={() => setShowLikePopup(false)}
              type={'artist'}
            />
          )
        }
        <BackWrapper onClick={() => setIsDetailCover(true)}>
          <BackColor
            background={(artist && artist.imageUri) || default_profile_img}
          />
          <TopGradient />
          <BottomGradient />
        </BackWrapper>
        <Flex type="column" style={{ marginTop: '-156px' }}>
          <ArtistCard
            artistImage={(artist && artist.image128Uri) || default_profile_img}
            name={artist && artist.name}
            nameEng={artist && artist.nameEng}
            uniqueName={artist && artist?.uniqueName}
            stageName={artist && artist.stageName}
            artistId={artist && artist._id}
            currentUser={currentUser}
            artistText={artist && artist.description}
            isDisplayDescription={artist && artist.isDisplayDescription}
            currentPath={currentPath}
            subdomainInfo={artist && artist.subdomainInfo}
            facebookUrl={artist && artist.facebookUrl}
            instagramUrl={artist && artist.instagramUrl}
            melonUrl={artist && artist.melonUrl}
            youtubeUrl={artist && artist.youtubeUrl}
            soundcloudUrl={artist && artist.soundcloudUrl}
            homepageUrl={artist && artist.homepageUrl}
            emailUrl={artist && artist.emailUrl}
            twitterUrl={artist && artist.twitterUrl}
            weverseUrl={artist && artist.weverseUrl}
            etcSocialUrl={artist && artist.etcSocialUrl}
            tiktokUrl={artist && artist.tiktokUrl}
            recordId={recordId}
            updateRecord={updateRecord}
            setIsShowLoginPopup={setIsShowLoginPopup}
            setShowLikePopup={setShowLikePopup}
            likeInfo={likeInfo}
            updateLike={updateLike}
            isWebPlamUser={artist && artist.isWebPlamUser}
            lang={lang}
          />
          <ArtistSmartLinkInfo updateRecord={updateRecord} />
          {/* {artist && artist.isInplamOpen && (
            <ArtistInPlamList
              uniqueName={artist && artist.uniqueName}
              artistId={artist && artist._id}
              fanSpotPoint={artist?.fanSpotPointInfo?.point}
            />
          )}
          {// 인플램 오픈요청
          !artist?.isInplamOpen && (
            <OpenInplamCard
              artistId={artist && artist._id}
              inplamOpenRequest={artist && artist.inplamOpenRequest}
              currentPath={currentPath}
              subdomainInfo={artist && artist.subdomainInfo}
              needAuth={() => setIsShowLoginPopup(true)}
              onClickFanSpot={() => {
                console.log('moonsae artist', artist)
                history.push(`/fanspot/${artist.uniqueName}`)
                pixelTrack(PIXEL_EVENTS?.아티스트?.팬스팟, {
                  artistId: artist?._id,
                  artistName: artist?.name,
                })
              }}
              uniqueName={artist?.uniqueName}
            />
          )} */}
          <StreamingList
            melon={artist && artist.melonUrl}
            genie={artist && artist.genieUrl}
            bugs={artist && artist.bugsUrl}
            flo={artist && artist.floUrl}
            vibe={artist && artist.vibeUrl}
            youtubeMusic={artist && artist.youtubeMusicUrl}
            appleMusic={artist && artist.appleMusicUrl}
            spotify={artist && artist.spotifyUrl}
            amazonMusic={artist && artist.amazonMusicUrl}
            etc={artist && artist.etcUrl}
            tidalUrl={artist?.tidalUrl}
            deezerUrl={artist?.deezerUrl}
            jooxUrl={artist?.jooxUrl}
            mymusicUrl={artist?.mymusicUrl}
            kkboxUrl={artist?.kkboxUrl}
            linejpUrl={artist?.linejpUrl}
            linetwUrl={artist?.linetwUrl}
            yandexUrl={artist?.yandexUrl}
            nctUrl={artist?.nctUrl}
            zingUrl={artist?.zingUrl}
            anghmiUrl={artist?.anghmiUrl}
            recordId={recordId}
            updateRecord={updateRecord}
            artistId={artist && artist._id}
            artistName={artist && artist.name}
            lang={lang}
          />
          {
            isLogined && currentUser?.type === 'company' ? (
              <ArtistSnsFollowerList
                followersInfo={followersInfo}
                listenerInfo={listenerInfo}
                uniqueName={artist?.uniqueName}
                isDisplayInstagramData={artist && artist.isDisplayInstagramData}
                isDisplayTwitterData={artist && artist.isDisplayTwitterData}
                isDisplayYoutubeData={artist && artist.isDisplayYoutubeData}
                isDisplaySoundcloudData={artist && artist.isDisplaySoundcloudData}
                artistFollowerInfo={artistFollowerInfo}
                instagramUrl={artist && artist.instagramUrl}
                soundcloudUrl={artist && artist.soundcloudUrl}
                twitterUrl={artist && artist.twitterUrl}
              />
            ) : (
              <></>
            )
          }
          <ArtistInfo lang={lang} />
        </Flex>
        {
          isDetailCover && (
            <DetailCoverImage
              setIsDetailCover={setIsDetailCover}
              imgSrc={artist && artist.imageUri && artist.imageUri}
            />
          )
        }
      </BodyWrapper>
    </>
  )
}

export default observer(ArtistTemplate)

import { observable } from 'mobx'

export default class BannerModel {
  @observable _id
  @observable title
  @observable link
  @observable startedAt
  @observable endedAt
  @observable isPublished
  @observable typeList
  @observable imageOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image256Path
  @observable image512Path
  @observable imageFilename
  @observable sortIndex
  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  constructor(stores, props) {
    if (props) {
      this._id = props._id
      this.title = props.title
      this.link = props.link
      this.startedAt = props.startedAt
      this.endedAt = props.endedAt
      this.isPublished = props.isPublished
      this.typeList = props.typeList
      this.imageOriginalPath = props.imageOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image256Path = props.image256Path
      this.image512Path = props.image512Path
      this.imageFilename = props.imageFilename
      this.sortIndex = props.sortIndex
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt
    }
  }
}

import {
  BodyWrapper,
  Caption1,
  Caption2,
  Flex,
  H6,
  Input,
} from '@components/atoms'
import {
  FanTest3ResultPopup,
  FanTestMelonPopup,
  FanTestOkPopup,
  FanTestPhonePopup,
  FanTestResultPopup,
} from '@components/organisms'
import React, { useState } from 'react'
import {
  default_profile_img,
  fantest_description,
  fantest_question_3_1,
  fantest_question_3_2,
  fantest_question_3_3,
  search_outline_24px,
} from '@images/index'
import { event_description, event_search } from '@images/'
import styled, { css } from 'styled-components'

import { Image } from '@components/molecules'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { STORAGE_URL } from '@consts'
import YouTube from 'react-youtube'
import { colors } from '@colors'
import { handleArtistImgError } from '@utils/handler'
import { pixelTrack } from '@utils/facebook/pixel'

const FanTestEvent3Template = ({ history, authStore }) => {
  const [inputValue, setInputValue] = useState('')
  const [isCorrect, setIsCorrect] = useState('')

  const ANSWER = '링클 레이어드'

  const opts = {
    playerVars: {
      autoplay: 0,
      controls: 1,
      autohide: 1,
      rel: 0,
      showinfo: 0,
      origin: document.domain,
    },
    host: 'https://www.youtube.com',
  }

  const {
    isShowing: openPopupIsShowing,
    toggle: openPopupToggle,
  } = FanTestMelonPopup.usePopup()

  const {
    isShowing: openResultPopupIsShowing,
    toggle: openResultPopupToggle,
  } = FanTest3ResultPopup.usePopup()

  const {
    isShowing: openPhonePopupIsShowing,
    toggle: openPhonePopupToggle,
  } = FanTestPhonePopup.usePopup()

  const requestEvent = async phone => {
    const result = await authStore.requestFanTestEvent({
      eventTitle: 'EVENT_JHW_001',
      phoneNumber: phone,
    })
  }

  return (
    <BodyWrapper style={{ padding: 0, minHeight: '100vh' }}>
      <Flex type="column">
        <Flex style={{ position: 'relative', width: '100%' }} type={'column'}>
          <TitleImage src={fantest_question_3_1} />

          <YoutubeWrapper key={`jhw`}>
            <YouTube
              videoId={'aa1MsNoIR7I'}
              opts={opts}
              containerClassName="youtube_wrapper"
              className="youtube_player"
            />
          </YoutubeWrapper>
        </Flex>
        <Flex style={{ position: 'relative', width: '100%' }} type={'column'}>
          <TitleImage src={fantest_question_3_2} />

          <SearchInplamInputContainer align={'center'} style={{}}>
            <SearchInplamInput
              placeholder="총 6글자를 입력해주세요."
              value={inputValue}
              onFocus={
                () => {
                  if (typeof window.twq === 'function') {
                    window.twq('event', 'tw-owc18-p7mpu')
                  }
                }
              }
              onChange={
                e => {
                  setInputValue(e.target.value)
                }
              }
            />
          </SearchInplamInputContainer>
        </Flex>

        <TitleImage src={fantest_question_3_3} />
      </Flex>
      <Flex
        style={
          {
            gap: 8,
            background: '#fff',
            padding: '10px 20px',
            position: 'sticky',
            bottom: 0,
          }
        }
      >
        <HintButton
          onClick={
            () => {
              pixelTrack(PIXEL_EVENTS.덕력고사3.오픈북)
              if (typeof window.twq === 'function') {
                window.twq('event', 'tw-owc18-p7mpt')
              }
              window.open(
                'https://www.musinsa.com/search/goods?keyword=%EB%B2%A0%ED%8B%B0%EB%B6%91&keywordType=keyword&gf=A',
              )
            }
          }
        >
          <H6>상품 찾기</H6>
        </HintButton>

        <SubmitButton
          disabled={inputValue?.length === 0}
          onClick={
            () => {
              pixelTrack(PIXEL_EVENTS.덕력고사3.제출)
              if (typeof window.twq === 'function') {
                window.twq('event', 'tw-owc18-p7mps')
              }
              if (inputValue.replace(/\s+/g, '') === ANSWER.replace(/\s+/g, '')) {
                setIsCorrect(true)
                setInputValue('')
                openPhonePopupToggle()
              }
              else {
                setIsCorrect(false)
                openResultPopupToggle()
              }
            }
          }
        >
          <H6 color={'#fafafa'}>정답 제출하기</H6>
        </SubmitButton>
      </Flex>

      <FanTestMelonPopup.View
        isShowing={openPopupIsShowing}
        toggle={openPopupToggle}
        onOk={
          async () => {
          // 멜론으로 이동
            window.open('https://kko.kakao.com/GkysjSwdr_')
          }
        }
        onCancel={
          () => {
          // 유튜브로 이동
            window.open('https://youtu.be/aa1MsNoIR7I?si=9kbG4ankw5GZGoxd')
          }
        }
      />
      <FanTest3ResultPopup.View
        isShowing={openResultPopupIsShowing}
        toggle={openResultPopupToggle}
        onOk={
          async () => {
            if (isCorrect) {
              pixelTrack(PIXEL_EVENTS.덕력고사3.정답확인)
              if (typeof window.twq === 'function') {
                window.twq('event', 'tw-owc18-p7mpi')
              }
              openPopupToggle()
            }
          }
        }
        isCorrect={isCorrect}
      />
      <FanTestPhonePopup.View
        isShowing={openPhonePopupIsShowing}
        toggle={openPhonePopupToggle}
        toggleComplte={openResultPopupToggle}
        requestOpen={requestEvent}
      />
    </BodyWrapper>
  )
}

export default FanTestEvent3Template

const TitleImage = styled.img`
  width: '100%';
  height: 'auto';
`

const Description = styled(Flex)`
  background-image: url(${fantest_description});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; /* 오버레이를 위해 필요 */

  padding: 28px 20px;
  gap: 3px;

  white-space: pre-line;
  /* 오버레이 추가 */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(211, 211, 211, 0.5);
    mix-blend-mode: multiply;
    pointer-events: none; /* 클릭 방해 방지 */
  }
`

const SearchImage = styled.img`
  width: 51.6vw; /* 186px */
  max-width: 216px;
  margin: 32px 0 27px 0;
  align-self: center;
  height: auto;
`
const SearchInplamInputContainer = styled(Flex)`
  width: calc(100% - 48px);
  height: 40px;

  border: none !important;
  border-bottom: 2px solid #242424 !important;
  background-color: transparent;

  padding: 10px 0px;

  position: absolute;
  bottom: 48px;

  margin: 0px 24px;

  box-sizing: border-box;
`
const SearchInplamInput = styled(Input)`
  flex: 1;
  font-family: Pretendard-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  border: none !important;
  background-color: transparent;
  padding: 0;
  ::placeholder {
    color: #ef9393;
  }
`

const HintButton = styled(Flex)`
  width: 96px;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid var(--grey-grey_80, #242424);
  box-sizing: border-box;
  cursor: pointer;
`

const SubmitButton = styled(Flex)`
  flex: 1;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--grey-grey_80, #242424);
  box-sizing: border-box;
  cursor: pointer;

  ${props =>
    props.disabled
    && css`
      pointer-events: none;
      opacity: 0.16;
    `}
`

const YoutubeWrapper = styled(Flex)`
  width: calc(100% - 40px);
  position: relative;
  margin: 0 20px;
  border-radius: 9.97px;
  overflow: hidden;
  position: absolute;
  bottom: 84.47px;
  box-sizing: border-box;
`

import React, { useEffect } from 'react'
import { Router, Route, Switch, useLocation, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { LastLocationProvider } from 'react-router-last-location'
import InitializePage from '@utils/InitializePage'

import { SUPPORTED_LANGUAGES } from '@consts'
import HomePage from './HomePage'
import RootPage from './RootPage'
import TrackPage from './TrackPage'
import AlbumPage from './AlbumPage'
import ArtistPage from './ArtistPage'
import SubdomainPage from './SubdomainPage'
import NotFoundPage from './NotFoundPage'
import SearchPage from './SearchPage'
import PrivacyPage from './PrivacyPage'
import LoginPage from './LoginPage'
import CommentPage from './CommentPage'
import InPlamArtistPage from './InPlamArtistPage'
import InPlamAddPage from './InPlamAddPage'
import InPlamArticlePage from './InPlamArticlePage'
import InplamNotFoundPage from './InplamNotFoundPage'
import ActivityArticlePage from './ActivityArticlePage'
import ActivityCommentPage from './ActivityCommentPage'
import InPlamTotalPage from './InPlamTotalPage'
import InPlamNoticePage from './InPlamNoticePage'
import EditProfilePage from './EditProfilePage'
import InPlamPage from './InPlamPage'
import MyIdolPage from './MyIdolPage'
import MyIdolResultPage from './MyIdolResultPage'
import ValuationPage from './ValuationPage'
import ArtistMelonPage from './ArtistMelonPage'
import ArtistYoutubePage from './ArtistYoutubePage'
import ArtistSnsListenDetailPage from './ArtistSnsListenDetailPage'
import TrackSnsListenDetailPage from './TrackSnsListenDetailPage'
import SecessionPage from './SecessionPage'
import AuthFindIdPage from './AuthFindIdPage'
import AuthFindPasswordPage from './AuthFindPasswordPage'
import AuthChangePasswordPage from './AuthChangePasswordPage'
import routeSubdomain from './routeSubdomain'
import AppDownloadPage from './AppDownloadPage'
import RewardSharePage from './RewardSharePage'
import NotificationPage from './NotificationPage'
import FanspotScorePage from './FanspotScorePage'
import FanSpotRequestEventPage from './FanSpotRequestEventPage'
import FanTestEventPage from './FanTestEventPage'
import FanTestEvent2Page from './FanTestEvent2Page'
import FanTestEvent2HintPage from './FanTestEvent2HintPage'
import FanTestEvent3Page from './FanTestEvent3Page'
import LanguageLayout from './LanguageLayout'
import routeNotSubdomain from './routeNotSubdomain'

const SwitchRouter = ({ history }) => {
  const location = useLocation()

  const lang =
    localStorage.getItem('language')
    || navigator?.language
    || navigator?.languages?.[0]
    || 'en'

  const isKorean = lang.startsWith('ko')
  const currentLanguage = isKorean ? 'ko' : 'en'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Switch>
      <Route path="/auth/secession" component={SecessionPage} />
      {/* 이벤트 페이지 */}
      <Route exact path="/event/fanspot" component={FanSpotRequestEventPage} />
      <Route exact path="/event/fantest" component={FanTestEventPage} />
      <Route exact path="/event/fantest2" component={FanTestEvent2Page} />
      <Route exact path="/event/fantest3" component={FanTestEvent3Page} />
      <Route
        exact
        path="/event/fantest2/hint"
        component={FanTestEvent2HintPage}
      />
      <Route exact path="/myidol" component={MyIdolPage} />
      <Route exact path="/myidol/:mbti" component={MyIdolResultPage} />
      <Route path="/search" component={SearchPage} />
      <Route
        exact
        path="/login/:platform"
        component={LoginPage}
        last={history}
      />

      <Route exact path="/login" component={LoginPage} />

      {/* 앱 다운로드 링크 공유 페이지 */}
      <Route exact path="/download" component={AppDownloadPage} />

      {/* 알림 리스트 페이지 */}
      <Route exact path="/notification" component={NotificationPage} />

      {/* 음감회 링크 공유 페이지 */}
      <Route exact path="/reward/:rewardId" component={RewardSharePage} />

      {/* 내가 쓴 글 */}
      <Route path="/activity/update" component={EditProfilePage} />
      <Route path="/activity/articles" component={ActivityArticlePage} />
      <Route path="/activity/comments" component={ActivityCommentPage} />

      {/* 댓글 페이지 */}
      <Route
        exact
        path="/comment/:dataCollectionName/:dataObjectId"
        component={CommentPage}
      />
      {/* 팬스팟 페이지 */}
      <Route exact path="/fanspot" component={InPlamPage} />
      <Route exact path="/fanspot/total" component={InPlamTotalPage} />
      <Route exact path="/fanspot/notice" component={InPlamNoticePage} />
      {/* <Route
        exact
        path="/fanspot/score/:uniqueName"
        component={FanspotScorePage}
      /> */}
      <Route exact path="/fanspot/:uniqueName" component={InPlamArtistPage} />
      <Route
        exact
        path="/fanspot/add/:uniqueName/:dataObjectId"
        component={InPlamAddPage}
      />
      <Route
        exact
        path="/fanspot/:uniqueName/:dataObjectId"
        component={InPlamArticlePage}
      />
      {/* 가치평가 예상 페이지 */}
      <Route exact path="/valuation" component={ValuationPage} />
      {/* 정책 페이지 */}
      <Route exact path="/privacy/:type" component={PrivacyPage} />

      {/* 다국어 라우트 그룹 */}
      <Route path={`/:lang(${SUPPORTED_LANGUAGES.join('|')})`}>
        <LanguageLayout>
          <Switch>
            <Route exact path="/:lang/" component={routeSubdomain(HomePage)} />
            {/* uniqueName(아티스트) || 서브도메인(트랙, 앨범) 페이지 */}
            <Route
              exact
              path="/:lang/:id"
              component={routeSubdomain(RootPage)}
            />
            {/* 서브도메인 리디렉션 페이지 */}
            {/* <Route exact path="/to/:lang/:subdomain" component={SubdomainPage} />
            <Route exact path="/to/:lang/:subdomain/:id" component={SubdomainPage} /> */}

            <Route exact path="/:lang/track/:id" component={TrackPage} />
            <Route exact path="/:lang/album/:id" component={AlbumPage} />
            <Route exact path="/:lang/artist/:id" component={ArtistPage} />
          </Switch>
        </LanguageLayout>
      </Route>

      <Route
        exact
        path="/"
        render={
          params =>
            routeNotSubdomain(
              <Redirect to={`/${currentLanguage}/`} />,
              <HomePage {...params} />,
            )
        }
      />
      {/* uniqueName(아티스트) || 서브도메인(트랙, 앨범) 페이지 */}
      <Route
        exact
        path="/:id"
        render={
          ({ match }) => (
            <Redirect to={`/${currentLanguage}/${match.params.id}`} />
          )
        }
      />
      {/* 서브도메인 리디렉션 페이지 */}

      {/* <Route exact path="/to/:subdomain" render={
          ({ match }) => (
            <Redirect to={`/${currentLanguage}/track/${match.params.id}`} />
          )
        } />
      <Route exact path="/to/:subdomain/:id" render={
          ({ match }) => (
            <Redirect to={`/${currentLanguage}/track/${match.params.id}`} />
          )
        } /> */}

      {/* 아티스트, 앨범, 트랙 기본 페이지 */}
      <Route
        exact
        path="/track/:id"
        render={
          ({ match }) => (
            <Redirect to={`/${currentLanguage}/track/${match.params.id}`} />
          )
        }
      />
      <Route
        exact
        path="/album/:id"
        render={
          ({ match }) => (
            <Redirect to={`/${currentLanguage}/album/${match.params.id}`} />
          )
        }
      />
      <Route
        exact
        path="/artist/:id"
        render={
          ({ match }) => (
            <Redirect to={`/${currentLanguage}/artist/${match.params.id}`} />
          )
        }
      />
      <Route
        exact
        path="/artist/:id/:sns/:type"
        component={ArtistSnsListenDetailPage}
      />
      <Route exact path="/artist/:id/melon" component={ArtistMelonPage} />
      <Route exact path="/artist/:id/youtube" component={ArtistYoutubePage} />
      <Route
        exact
        path="/track/:_id/:artistId/:snsType"
        component={TrackSnsListenDetailPage}
      />

      {/* 에러 페이지 */}
      <Route path="/error/fanspot/:uniqueName" component={InplamNotFoundPage} />

      <Route path="/auth/find-id" component={AuthFindIdPage} />
      <Route path="/auth/find-password" component={AuthFindPasswordPage} />
      <Route path="/auth/change-password" component={AuthChangePasswordPage} />

      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}

export default SwitchRouter

import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Flex, BodyWrapper, H6, H4, Input, Caption1 } from '@components/atoms'
import {
  move_down_gray_img,
  last_page_img,
  no_last_page_img,
  first_page_img,
  no_first_page_img,
  x_btn_gray_img,
} from '@images/'
import ReactLoading from 'react-loading'

import { BurgerMenu } from '@components/organisms'
import { colors } from '@colors/'
import { useObserver, Observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Pagination from 'react-js-pagination'
import {
  InplamArtistListItem,
  InplamArtistListItemSkeleton,
  TotalInPlamListItem,
} from '@components/molecules'
import { useStore } from '@utils/hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  fluent_arrow_24,
  fluent_megaphone_12_filled,
  white_pen_fill_24px,
} from '@images/index'

const InPlamTotalTemplate = ({
  offset,
  handlePagination,
  sortBy,
  handleChangeSortBy,
  handleChangeFilterBy,
  artistInfo,
  totalArticleList,
  totalArticleListCount,
  activeTab,
  setActiveTab,
  totalInplamList,
  currentUser,
  searchWord,
  handleChangeSearchWord,
  notice,
  needAuth,
  setIsFanSpotModalOpen,
}) => {
  const history = useHistory()

  const { handlerObj } = useStore()

  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const [inputValue, setInputValue] = useState(searchWord ? searchWord : '') // view 검색어

  const [isShowFloatingButton, setIsShowFloatingButton] = useState(1) // 배경색 투명도

  const parentRef = useRef(null)
  const [parentOffset, setParentOffset] = useState({ left: 0, width: 0 })
  const containerRef = useRef(null)

  useEffect(() => {
    const updateOffset = () => {
      if (parentRef.current) {
        const rect = parentRef.current.getBoundingClientRect()

        setParentOffset(rect)
      }
    }

    // 초기 위치 설정
    updateOffset()

    // 창 크기 변경 시 위치 업데이트
    window.addEventListener('resize', updateOffset)
    return () => {
      window.removeEventListener('resize', updateOffset)
    }
  }, [])

  const dataList =
    totalInplamList?.findIndex(val => val?.isInplamFavorite) > -1
      ? totalInplamList?.filter(val => val?.isInplamFavorite)
      : totalInplamList

  const handleMoveDetail = item => {
    if (!item) return
    history.push({
      pathname: `/fanspot/total/${item?._id}`,
      search: `?offset=${offset}&sortBy=${sortBy}`,
      state: { fromActivity: 'all' },
    })

    // if (item.isNotice) {
    //   history.push(`/fanspot/total/${item._id}?offset=0&sortBy=createdAt`)
    // }
    // else {
    //   history.push(
    //     `/fanspot/${item.uniqueName}/${item._id}?offset=0&sortBy=createdAt`,
    //   )
    // }
  }

  const handleInput = value => {
    setInputValue(value)
  }
  const handleSearch = () => {
    if (inputValue) {
      // setSearchWord(inputValue)
      handleChangeSearchWord(inputValue)
    }
    else {
      handleChangeSearchWord(null)
      // setSearchWord(null)
    }
  }

  const countOffset = () => {
    handlePagination(offset + 1)
  }

  return useObserver(() => (
    <div
      id="scroll-container"
      style={{ position: 'relative', overflow: 'auto', height: '100vh' }}
      ref={containerRef}
    >
      <Helmet>
        <title>{`팬스팟 전체글`}</title>
        <meta
          name="description"
          content="나의 소중한 아티스트와 소통하는 공간, 팬스팟"
        />
      </Helmet>

      <Flex type={'column'} style={{ position: 'sticky', top: 0, zIndex: 100 }}>
        <Header
          className={'header'}
          style={
            {
              backgroundColor: `#f4f4f4`,
            }
          }
          justify="space-between"
          align="center"
        >
          <Flex
            style={{ width: '44px', height: '44px', cursor: 'pointer' }}
            align="center"
            justify="center"
            onClick={
              () => {
                history.goBack()
              }
            }
          >
            <PrevButton src={fluent_arrow_24} />
          </Flex>
        </Header>

        <FilterContainer
          style={
            {
              background: '#f4f4f4',
              padding: '0 20px',
              paddingTop: '4px',
              paddingBottom: '8px',
            }
          }
        >
          <Flex style={{ gap: 16, height: 40, alignItems: 'flex-end' }}>
            <H4
              style={
                {
                  boxSizing: 'border-box',
                  height: 30,
                  whiteSpace: 'nowrap',

                  color: activeTab === 'popular' ? 'black' : '#bfbfbf',
                  cursor: 'pointer',
                }
              }
              onClick={
                () => {
                  window.scrollTo(0, 0)
                  handleChangeFilterBy('popular')
                }
              }
            >
              핫스팟
            </H4>
            <H4
              style={
                {
                  boxSizing: 'border-box',
                  height: 30,
                  whiteSpace: 'nowrap',

                  color: activeTab === 'free' ? 'black' : '#bfbfbf',
                  cursor: 'pointer',
                }
              }
              onClick={
                () => {
                  window.scrollTo(0, 0)
                  handleChangeFilterBy('free')
                }
              }
            >
              자유스팟
            </H4>
            <H4
              style={
                {
                  boxSizing: 'border-box',
                  height: 30,
                  whiteSpace: 'nowrap',

                  color: activeTab === 'info' ? 'black' : '#bfbfbf',
                  cursor: 'pointer',
                }
              }
              onClick={
                () => {
                  window.scrollTo(0, 0)
                  handleChangeFilterBy('info')
                }
              }
            >
              정보/소식
            </H4>
            <H4
              style={
                {
                  boxSizing: 'border-box',
                  height: 30,
                  whiteSpace: 'nowrap',

                  color: activeTab === 'notice' ? 'black' : '#bfbfbf',
                  cursor: 'pointer',
                }
              }
              onClick={
                () => {
                  window.scrollTo(0, 0)
                  handleChangeFilterBy('notice')
                }
              }
            >
              공지사항
            </H4>
          </Flex>
        </FilterContainer>
      </Flex>
      <BodyWrapper
        ref={parentRef}
        id="comment_template"
        style={
          {
            paddingTop: 0,
            paddingBottom: 64,
            minHeight: '100vh',
            background: '#f4f4f4',
            overflow: 'visible',
          }
        }
      >
        <InfiniteScroll
          dataLength={totalArticleList.length}
          scrollThreshold={0.8}
          next={countOffset}
          hasMore={totalArticleListCount > totalArticleList.length}
          loader={
            <ReactLoading
              type="spin"
              color="black"
              style={
                {
                  width: '50px',
                  height: '50px',
                  margin: '30px auto 50px auto',
                }
              }
            />
          }
          scrollableTarget="scroll-container"
          style={{ overflow: 'auto', paddingTop: '8px' }}
        >
          {
            activeTab !== 'notice' && !!notice?.[0] && (
              <Flex
                align={'center'}
                style={
                  {
                    padding: '8px',
                    borderRadius: '4px',
                    background: '#FEF0F1',
                    marginBottom: '8px',
                    cursor: 'pointer',
                  }
                }
                onClick={
                  () => {
                    history.push(
                      `/fanspot/total/${notice?.[0]?._id}?offset=0&sortBy=createdAt`,
                    )
                  }
                }
              >
                <img
                  src={fluent_megaphone_12_filled}
                  style={{ width: '12px', height: '12px', marginRight: '6px' }}
                />
                <Caption1
                  color={'#E13240'}
                  style={{ marginRight: '4px', minWidth: '27px' }}
                >
                공지
                </Caption1>
                <NoticeText type={'Regular'} style={{}}>
                  {notice?.[0]?.title}
                </NoticeText>
              </Flex>
            )
          }
          <Flex style={{ gap: '12px' }} type={'column'}>
            {
              totalArticleList
              && totalArticleList.map(item => (
                <TotalInPlamListItem
                  item={item}
                  key={item._id}
                  artistInfo={artistInfo}
                  onClick={handleMoveDetail}
                />
              ))
            }
            {
totalArticleList?.length === 0 && (
                <Caption1
                  type={'Regular'}
                  color={'#646464'}
                  style={{ marginTop: '104px' }}
                >
                게시글이 없습니다.
                </Caption1>
              )
            }
          </Flex>
        </InfiniteScroll>
        {
          <FloatingButton
            style={
              {
                right: parentOffset.x,
                bottom: '32px',
                pointerEvents: isShowFloatingButton ? 'auto' : 'none',
                cursor: 'pointer',
              }
            }
            onClick={
              () => {
                if (!(currentUser && currentUser._id)) {
                  needAuth()
                  return
                }

                setIsFanSpotModalOpen(true)
              }
            }
            isShowFloatingButton={isShowFloatingButton}
          >
            <FloatingImg src={white_pen_fill_24px} alt={'inplamAdd-float'} />
          </FloatingButton>
        }
      </BodyWrapper>
    </div>
  ))
}

export default InPlamTotalTemplate

const FilterContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  height: 40px;

  position: sticky;
  top: 0;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: solid 1px ${colors.whitef4};
`

const Select = styled.select`
  font-size: 12px;
  font-family: Pretendard-Medium;
  -webkit-appearance: none;
  border: none;
  color: ${colors.brown_grey};

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-position-x: 38px;
  background-size: 12px;
  background-repeat: no-repeat;
  width: 50px;
  display: block;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`

const PrevNextPage = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`

const HomeInplamArtistList = styled(Flex)`
  position: relative;
  margin-left: -8px;
  flex-wrap: wrap;
  div + div {
    margin: 0 9px 12px 9px;
  }
  div:first-child {
    margin: 0px 9px;
  }

  max-height: 132px;
  overflow: hidden;
`

const BottomGradient = styled.div`
  z-index: 3;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`

const SearchInplamContainer = styled(Flex)`
  width: 100%;
  height: 40px;
  /* padding: 6px 16px; */

  border: none !important;
  background-color: #f4f4f4;
  /* font-size: 16px; */
  border-radius: 8px;

  box-sizing: border-box;
`

const SearchInplamInput = styled(Input)`
  width: 100%;
  height: 40px;
  padding: 6px 16px;

  border: none !important;
  background-color: transparent;
  font-size: 12px;

  font-family: Pretendard-Regular;

  box-sizing: border-box;
`

const Header = styled(Flex)`
  height: 52px;
  padding-right: 8px;
  /* position: sticky; */
  box-sizing: border-box;
  /* top: 0; */
  /* left: 0; */
  z-index: 50;
  width: 100%;
  transition: background-color 0.3s ease; /* 배경색 부드럽게 전환 */
`

const NoticeText = styled(Caption1)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`
const PrevButton = styled.img`
  width: 32px;
  height: 32px;
`

const FloatingButton = styled(Flex)`
  position: fixed;
  z-index: 500;
  transform: translateX(-50%); /* 부모 기준 중앙 정렬 */
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: var(--color-black-solid, #000);
  box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.08);
  opacity: ${props => props.isShowFloatingButton};
  transition: opacity 0.3s ease;
`
const FloatingImg = styled.img`
  filter: invert(0);
  width: 24px;
  height: 24px;
`

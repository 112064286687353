import React from 'react'
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import './ArtistTabs.css'
import 'react-tabs/style/react-tabs.css'

const ArtistTabs = props => {
  const {
    menu,
    contents,
    customStyle,
    customList,
    customTab,
    selectedIndex,
    onSelect,
  } = props

  return (
    <Tabs
      className="artist_tab"
      selectedIndex={selectedIndex}
      onSelect={onSelect}
    >
      <TabList>
        {
          menu.map((list, index) => (
            <Tab
              style={
                {
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              }
              key={index}
            >
              {list}
            </Tab>
          ))
        }
      </TabList>
      {
        contents.map((list, index) => (
          <TabPanel key={index}>{list}</TabPanel>
        ))
      }
    </Tabs>
  )
}

export default ArtistTabs

import React, { useEffect, useState } from 'react'
import { BodyWrapper, Caption1, Flex, H5, H6 } from '@components/atoms'
import { plam_mbti_loading_gif } from '@images/'
import styled, { keyframes } from 'styled-components'
import { MbtiBtn } from '@components/molecules'
import { useHistory } from 'react-router-dom'
import {
  app_icon,
  apple_logo,
  download_qr,
  playstore_logo,
} from '@images/index'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { ConfirmPopup } from '@components/organisms'
import { ANDROID_STORE_URL, IOS_STORE_URL } from '../../resources'

const PopupInner = styled.div`
  position: absolute;
  width: calc(100% - 80px);
  /* height: 288px;*/
  padding: 24px 0px;
  left: 40px;
  /* top: 116px; */
  top: 20%;
  background: white;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const QrContainer = styled(Flex)`
  marginBottom: 32px;
  marginTop: 8px

  width: 112px;
  background: #eaeaea;
  border-radius: 8px;

  padding: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  `

const AppDownloadTemplate = ({}) => {
  const {
    isShowing: confirmShowing,
    toggle: confirmToggle,
  } = ConfirmPopup.usePopup()

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('android') > -1 || ua.indexOf('ios') > -1) {
      confirmToggle()
    }
  }, [])

  const onPressQRcode = () => {
    window.location.href = 'https://plamin.page.link/89eQ'
  }

  const onPressDownloadButton = ({ type = 'android' }) => {
    window.location.href =
      type === 'android' ? ANDROID_STORE_URL : IOS_STORE_URL
  }

  return (
    <BodyWrapper style={{ padding: 0, height: '100vh' }}>
      <Flex
        type="column"
        style={
          {
            height: '100%',
            alignItems: 'center',
            padding: '64px 24px',
          }
        }
      >
        <Link to={'/'}>
          <img
            src={app_icon}
            alt="app_icon"
            style={
              {
                width: 60,
                height: 60,
                marginBottom: '8px',
                alignSelf: 'center',
              }
            }
          />
        </Link>
        <H5
          style={
            {
              whiteSpace: 'pre-line',
            }
          }
        >
          {'플램 - 플레이 음감회, PLAM'}
        </H5>

        <Caption1
          color={'#646464'}
          type={'Regular'}
          style={{ whiteSpace: 'pre-wrap', marginTop: '12px' }}
        >
          {
            '음악을 감상하면 돈이 벌리는 음악 앱테크 리워드 서비스,\n플레이 음감회로 여러분을 초대합니다.\n지금 다운로드 받고, 새로운 음악을 발견하며 돈도 벌어보세요.'
          }
        </Caption1>

        <QrContainer
          style={
            {
              marginBottom: '24px',
              marginTop: '20px',
              cursor: 'pointer',
            }
          }
          type={'column'}
          onClick={onPressQRcode}
        >
          <img
            src={download_qr}
            alt="download_qr"
            style={
              {
                width: 110,
                height: 110,
                // margin: '8px 0px 32px 0px',
                alignSelf: 'center',
                borderRadius: '8px',
              }
            }
          />
        </QrContainer>

        <Caption1
          onClick={
            () => {
              onPressDownloadButton({ type: 'android' })
            }
          }
          color="#242424"
          style={
            {
              width: '160px',
              height: '40px',
              justifyContent: 'center',
              borderRadius: '4px',
              borderColor: '#eaeaea',
              borderWidth: '1px',
              borderStyle: 'solid',
              alignItems: 'center',
              display: 'flex',
              cursor: 'pointer',
              flexDirection: 'row',
              marginBottom: '12px',
            }
          }
        >
          <img
            src={playstore_logo}
            alt="playstore_down"
            style={
              {
                width: 16,
                height: 16,
                // margin: '8px 0px 32px 0px',
                alignSelf: 'center',
                marginRight: '4px',
              }
            }
          />
          구글 플레이 스토어
        </Caption1>

        <Caption1
          onClick={
            () => {
              onPressDownloadButton({ type: 'ios' })
            }
          }
          color="#242424"
          style={
            {
              width: '160px',
              height: '40px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              borderColor: '#eaeaea',
              borderStyle: 'solid',
              borderWidth: '1px',
              display: 'flex',
              cursor: 'pointer',
              flexDirection: 'row',
              marginBottom: '12px',
            }
          }
        >
          <img
            src={apple_logo}
            alt="apple_down"
            style={
              {
                width: 16,
                height: 16,
                // margin: '8px 0px 32px 0px',
                alignSelf: 'center',
                marginRight: '4px',
              }
            }
          />
          앱 스토어
        </Caption1>

        <Link to={'/'}>
          <Caption1
            color="#242424"
            style={
              {
                width: '160px',
                height: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
                borderColor: '#eaeaea',
                borderStyle: 'solid',
                borderWidth: '1px',
                display: 'flex',
                cursor: 'pointer',
                flexDirection: 'row',
                marginBottom: '24px',
              }
            }
          >
            홈으로 이동
          </Caption1>
        </Link>
      </Flex>

      <ConfirmPopup.View
        isShowing={confirmShowing}
        isShowCancel
        toggle={confirmToggle}
        onConfirm={onPressQRcode}
        title="PLAM 앱 또는 스토어로 이동합니다."
      />
    </BodyWrapper>
  )
}

export default AppDownloadTemplate

import { Flex, Typography } from '@components/atoms'
import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import Textarea from 'react-textarea-autosize'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import { TRACK_PAGE_TEXT } from '@consts'

const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: baseline;
  padding: 6px 0px;
  box-sizing: border-box;

  > span:first-child {
    white-space: nowrap;
    margin-right: 20px;
  }
  a {
    margin-top: -3px;
  }
`

const InfoList = styled.div`
  word-break: break-word;
`

const TrackInfo = ({
  lyrics,
  composer,
  lyricst,
  arranger,
  genre,
  track,
  lang,
}) => {
  const [more, setMore] = useState(false) // 더보기 유무

  return (
    <>
      {/* 음악 정보 */}
      <Flex type="column" style={{ padding: '20px 0px 0px 0px' }}>
        <Typography type="Medium" size="20px" style={{ paddingBottom: '6px' }}>
          {TRACK_PAGE_TEXT[lang]['lyrics']}
        </Typography>
        <Flex style={{ justifyContent: 'space-between' }}>
          {
            lyrics ? (
              <Flex type="column" style={{ width: '100%' }}>
                <Textarea
                  readOnly
                  value={lyrics}
                  className="textarea"
                  style={
                    {
                      width: '100%',
                      fontSize: '14px',
                      lineHeight: '28px',
                    }
                  }
                  minRows={1}
                  maxRows={more ? 1000 : 5}
                />
                {
                  !more && (
                    <Typography
                      onClick={
                        () => {
                          setMore(true)
                          pixelTrack(PIXEL_EVENTS.트랙.가사_더보기, {
                            trackId: track._id,
                            trackTitle: track.title,
                            artistId: track.artistId,
                            artistName: track.releaseArtistList?.[0]?.name,
                          })
                        }
                      }
                      type="Medium"
                      size="12px"
                      color="#949494"
                      style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
                    >
                      {TRACK_PAGE_TEXT[lang]['more']}
                    </Typography>
                  )
                }
              </Flex>
            ) : (
              <Typography type="Medium" size="12px">
                {TRACK_PAGE_TEXT[lang]['no_lyrics']}
              </Typography>
            )
          }
        </Flex>
      </Flex>
      <Flex type="column" style={{ padding: '20px 0px' }}>
        <Typography type="Medium" size="20px" style={{ paddingBottom: '6px' }}>
          {TRACK_PAGE_TEXT[lang]['credit']}
        </Typography>
        {
          lyricst || composer || arranger || genre ? (
            <>
              <Info>
                <Typography size="14px" color="#646464">
                  {TRACK_PAGE_TEXT[lang]['lyrics_by']}
                </Typography>
                <InfoList>
                  {
                    lyricst
                  && lyricst.map((list, index) =>
                    !list.isAuto ? (
                      <Link to={`/artist/${list.uniqueName}`} key={list._id}>
                        <Typography type="Bold" size="14px" color="#242424">
                          {list.name}
                          {!(index === lyricst.length - 1) && `,\u00A0`}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography size="14px" key={list._id}>
                        {list.name}
                        {!(index === lyricst.length - 1) && `,\u00A0`}
                      </Typography>
                    ),
                  )
                  }
                </InfoList>
              </Info>
              <Info>
                <Typography size="14px" color="#646464">
                  {TRACK_PAGE_TEXT[lang]['composed_by']}
                </Typography>
                <InfoList>
                  {
                    composer
                  && composer.map((list, index) =>
                    !list.isAuto ? (
                      <Link to={`/artist/${list.uniqueName}`} key={list._id}>
                        <Typography type="Bold" size="14px" color="#242424">
                          {list.name}
                          {!(index === composer.length - 1) && `,\u00A0`}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography size="14px" key={list._id}>
                        {list.name}
                        {!(index === composer.length - 1) && `,\u00A0`}
                      </Typography>
                    ),
                  )
                  }
                </InfoList>
              </Info>
              <Info>
                <Typography size="14px" color="#646464">
                  {TRACK_PAGE_TEXT[lang]['arranged_by']}
                </Typography>
                <InfoList>
                  {
                    arranger
                  && arranger.map((list, index) =>
                    !list.isAuto ? (
                      <Link to={`/artist/${list.uniqueName}`} key={list._id}>
                        <Typography type="Bold" size="14px" color="#242424">
                          {list.name}
                          {!(index === arranger.length - 1) && `,\u00A0`}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography size="14px" key={list._id}>
                        {list.name}
                        {!(index === arranger.length - 1) && `,\u00A0`}
                      </Typography>
                    ),
                  )
                  }
                </InfoList>
              </Info>
              <Info>
                <Typography size="14px" color="#646464">
                  {TRACK_PAGE_TEXT[lang]['genre']}
                </Typography>
                <Typography size="14px">{genre}</Typography>
              </Info>
            </>
          ) : (
            `${TRACK_PAGE_TEXT[lang]['no_info']}`
          )
        }
      </Flex>
    </>
  )
}

export default TrackInfo

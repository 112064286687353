import { Caption1, Caption2, Flex, H5, H6 } from '@components/atoms'
import React, { useState } from 'react'
import { arrow_down, check_img } from '@images/index'
import { check_empty_img, check_img_blue, open_alarm_talk_img } from '@images/'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import ReactDOM from 'react-dom'
import { colors } from '@colors/'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import { useSnackBar } from '..'

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle, onCancel, requestOpen, toggleComplte }) => {
  const [isShowPrivacy, setIsShowPrivacy] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [phoneVal, setPhoneVal] = useState('')
  const { toggleSnackBar, View: SnackBar } = useSnackBar()
  const {
    View: VallidationView,
    toggleSnackBar: handleVallidation,
  } = useSnackBar()

  const handleCancel = () => {
    pixelTrack(PIXEL_EVENTS.덕력고사.알림취소)
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  const validatePhoneNumber = value => {
    const phoneRegex = /^01[0-9]\d{7,8}$/
    return phoneRegex.test(value)
  }

  const handleOk = async () => {
    pixelTrack(PIXEL_EVENTS.덕력고사.알림)
    if (typeof window.twq === 'function') {
      window.twq('event', 'tw-owc18-p5w9j')
    }

    if (!isCheck) {
      toggleSnackBar()
      return
    }

    if (!validatePhoneNumber(phoneVal)) {
      handleVallidation()
      return
    }

    if (requestOpen) {
      const requestResult = await requestOpen(phoneVal)
      toggle()
      toggleComplte()
    }
  }

  return isShowing
    ? ReactDOM.createPortal(
        <>
          <SnackBar
            text={'개인정보 수집 이용에 동의해주세요'}
            backgroundColor="#ea4653"
          />
          <VallidationView
            text={'핸드폰번호 형식을 확인해주세요'}
            backgroundColor="#ea4653"
          />
          <ModalOverlay />
          <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <Modal>
              <MobdlBody>
                <Flex type={'column'} style={{ padding: '0px 20px' }}>
                  <H5 style={{ marginTop: 24 }}>연락처를 입력해주세요!</H5>
                  <img
                    src={open_alarm_talk_img}
                    alt="open_icon"
                    style={{
                      width: 48,
                      height: 48,
                      margin: '16px 0',
                      alignSelf: 'center',
                    }}
                  />
                  <Caption1 align="center" style={{ whiteSpace: 'pre-line' }}>
                    {
                      '정답을 맞힌 분들은 추첨을 통해\n전화번호로 개별 안내를 드립니다.'
                    }
                  </Caption1>
                  <Flex type="column" style={{ margin: '8px 0 16px 0' }}>
                    <Caption1
                      type="Regular"
                      align="center"
                      color={colors.brownish_grey}
                    >
                      당첨 소식을 놓치지 않도록 전화번호를 입력해주세요!
                    </Caption1>
                  </Flex>
                  <EmailInput
                    placeholder="전화번호를 입력해주세요 (' - ' 없이)"
                    type="phone"
                    style={{ marginBottom: 16 }}
                    value={phoneVal}
                    onChange={e => setPhoneVal(e.target.value)}
                    required
                  />
                </Flex>
                <Flex
                  align={'center'}
                  justify={'space-between'}
                  style={{ padding: '12px 20px' }}
                >
                  <Flex
                    onClick={() => setIsCheck(!isCheck)}
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                  >
                    <img
                      src={check_img}
                      alt="checkbox_icon"
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8,
                        opacity: isCheck ? 1 : 0.5,
                      }}
                    />
                    <Caption1>
                      <Caption1 type={'Regular'} color={'#949494'}>
                        (필수)
                      </Caption1>{' '}
                      개인정보 수집 이용에 동의합니다.
                    </Caption1>
                  </Flex>
                  <Flex
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsShowPrivacy(val => !val)
                    }}
                  >
                    <img
                      src={arrow_down}
                      alt={'arrow_img'}
                      style={{
                        width: '16px',
                        height: '16px',
                        rotate: isShowPrivacy ? '180deg' : '360deg',
                      }}
                    />
                  </Flex>
                </Flex>
                {isShowPrivacy && (
                  <Flex
                    type={'column'}
                    style={{ background: '#FAFAFA', padding: '16px 20px' }}
                  >
                    <Caption2 align="left" style={{ marginBottom: '6px' }}>
                      개인정보 수집 이용 동의
                    </Caption2>
                    <Caption2 align="left" type="Regular">
                      플램은 이용자가 신청하신 이벤트의 당첨 알림을 위해
                      <br />
                      ‘전화번호’을 수집 이용하며, 당첨 알림 후 파기됩니다.
                      <br />
                      개인정보 수집 이용 동의를 거부하실 수 있으며, 거부 시
                      <br />
                      이벤트 당첨 알림이 어렵습니다.
                    </Caption2>
                  </Flex>
                )}
                {/* footer */}
                <Flex style={{ justifyContent: 'flex-end', marginTop: '30px' }}>
                  <CancelBtn onClick={handleCancel}>
                    <H6>취소</H6>
                  </CancelBtn>
                  <Okbtn onClick={handleOk} disabled={!isCheck}>
                    <H6>참여하기</H6>
                  </Okbtn>
                </Flex>
              </MobdlBody>
            </Modal>
          </ModalWrapper>
        </>,
        document.body,
      )
    : null
}

export default {
  View,
  usePopup,
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background: white;
  overflow: auto;
  max-width: 380px;
  min-width: 280px;
  width: calc(100% - 40px);
  /* min-height: 474px; */
  height: fit-content;
  top: 50%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 20px; */
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const EmailInput = styled.input`
  /* width: calc(100% - 40px); */
  /* height: 48px; */
  font-size: 12px;
  font-family: Pretendard-Regular;
  border: solid 0.5px #d4d4d4;
  border-radius: 2px;

  background: #fff;
  padding: 11.5px 12px;
  margin: 0px;
`

const CancelBtn = styled.button`
  display: flex;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  font-size: 14px;
  font-family: Pretendard-Regular;
  border: none;
  background: var(--grey-grey_40, #d4d4d4);
  color: #646464;
  cursor: pointer;
`

const Okbtn = styled.button`
  display: flex;
  height: 44px;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  font-size: 14px;
  font-family: Pretendard-Regular;
  border: none;
  border-radius: 0px 0px 2px 0px;
  background: var(--grey-grey_80, #242424);
  cursor: pointer;
  color: var(--grey-grey_10, #fafafa);
  opacity: ${props => (props.disabled ? 0.16 : 1)};
`

import { observable } from 'mobx'
import { STORAGE_URL } from '@consts'
import { ArtistModel } from '.'
import AlbumModel from './AlbumModel'

export default class TrackModel {
  /* Database fields */
  @observable _id

  @observable isTitle
  @observable no
  @observable title
  @observable titleEng

  @observable audioOriginalPath
  @observable audioFilename

  @observable youtubeUrl
  @observable youtubeUrlList
  @observable ISRC
  @observable UCI

  @observable releaseArtistIdList
  @observable featuringArtistIdList
  @observable lyricstArtistIdList
  @observable composerArtistIdList
  @observable arrangerArtistIdList

  @observable lyrics
  @observable mainGenre
  @observable subGenre

  @observable spotifyUrl
  @observable youtubeMusicUrl
  @observable melonDeepLinkUrl
  @observable melonUrl
  @observable genieUrl
  @observable bugsUrl
  @observable vibeUrl
  @observable floUrl
  @observable appleMusicUrl
  @observable amazonMusicUrl
  @observable tidalUrl
  @observable deezerUrl
  @observable jooxUrl
  @observable mymusicUrl
  @observable kkboxUrl
  @observable linejpUrl
  @observable linetwUrl
  @observable yandexUrl
  @observable nctUrl
  @observable zingUrl
  @observable anghmiUrl

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable albumId
  @observable artistId
  @observable userId
  @observable likeCount
  /* Database fields end */

  @observable imageOriginalPath = null
  @observable image64Path = null
  @observable image128Path = null
  @observable image512Path = null
  @observable albumInfo = null
  @observable releasedAt = null

  @observable releaseArtistList = []
  @observable featuringArtistList = []
  @observable lyricstArtistList = []
  @observable composerArtistList = []
  @observable arrangerArtistList = []

  @observable customUrlInfo
  @observable subdomainInfo

  get releaseArtistName() {
    if (Array.isArray(this.releaseArtistList)) {
      return this.releaseArtistList.map(artist => artist['name']).join(', ')
    }
    return ''
  }

  get imageUri() {
    return `${STORAGE_URL}${this.imageOriginalPath}`
  }
  get image512Uri() {
    return `${STORAGE_URL}${this.image512Path || this.imageOriginalPath}`
  }
  get image128Uri() {
    return `${STORAGE_URL}${this.image128Path || this.imageOriginalPath}`
  }
  get image64Uri() {
    return `${STORAGE_URL}${this.image64Path || this.imageOriginalPath}`
  }
  get audioUri() {
    return `${STORAGE_URL}${this.audioOriginalPath}`
  }

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.isTitle = props.isTitle
      this.no = props.no
      this.title = props.title
      this.titleEng = props.titleEng

      this.audioOriginalPath = props.audioOriginalPath
      this.audioFilename = props.audioFilename

      this.youtubeUrl = props.youtubeUrl
      this.youtubeUrlList = props.youtubeUrlList
      this.ISRC = props.ISRC
      this.UCI = props.UCI

      this.releaseArtistIdList = props.releaseArtistIdList
      this.featuringArtistIdList = props.featuringArtistIdList
      this.lyricstArtistIdList = props.lyricstArtistIdList
      this.composerArtistIdList = props.composerArtistIdList
      this.arrangerArtistIdList = props.arrangerArtistIdList

      this.lyrics = props.lyrics
      this.mainGenre = props.mainGenre
      this.subGenre = props.subGenre

      this.spotifyUrl = props.spotifyUrl
      this.youtubeMusicUrl = props.youtubeMusicUrl
      this.melonDeepLinkUrl = props.melonDeepLinkUrl
      this.melonUrl = props.melonUrl
      this.genieUrl = props.genieUrl
      this.bugsUrl = props.bugsUrl
      this.vibeUrl = props.vibeUrl
      this.floUrl = props.floUrl
      this.appleMusicUrl = props.appleMusicUrl
      this.amazonMusicUrl = props.amazonMusicUrl
      this.tidalUrl = props.tidalUrl
      this.deezerUrl = props.deezerUrl
      this.jooxUrl = props.jooxUrl
      this.mymusicUrl = props.mymusicUrl
      this.kkboxUrl = props.kkboxUrl
      this.linejpUrl = props.linejpUrl
      this.linetwUrl = props.linetwUrl
      this.yandexUrl = props.yandexUrl
      this.nctUrl = props.nctUrl
      this.zingUrl = props.zingUrl
      this.anghmiUrl = props.anghmiUrl

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.albumId = props.albumId
      this.artistId = props.artistId
      this.userId = props.userId
      this.likeCount = props.likeCount

      this.imageOriginalPath = props.imageOriginalPath
      this.image512Path = props.image512Path
      this.image128Path = props.image128Path
      this.image64Path = props.image64Path
      this.albumInfo = new AlbumModel(stores, props.albumInfo)
      this.releasedAt = props.releasedAt

      this.customUrlInfo = props.customUrlInfo
      this.subdomainInfo = props.subdomainInfo

      // naame List 모델로 바뀌었음 추후 Model 수정
      this.releaseArtistList =
        (Array.isArray(props.releaseArtistList)
          && props.releaseArtistList.map(elem => new ArtistModel(stores, elem)))
        || []

      this.featuringArtistList =
        (Array.isArray(props.featuringArtistList)
          && props.featuringArtistList.map(
            elem => new ArtistModel(stores, elem),
          ))
        || []

      this.lyricstArtistList =
        (Array.isArray(props.lyricstArtistList)
          && props.lyricstArtistList.map(elem => new ArtistModel(stores, elem)))
        || []

      this.composerArtistList =
        (Array.isArray(props.composerArtistList)
          && props.composerArtistList.map(
            elem => new ArtistModel(stores, elem),
          ))
        || []

      this.arrangerArtistList =
        (Array.isArray(props.arrangerArtistList)
          && props.arrangerArtistList.map(
            elem => new ArtistModel(stores, elem),
          ))
        || []
    }
  }
}

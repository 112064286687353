import {
  FanSpotRequestEventTemplate,
  FanTestEventTemplate,
} from '@components/templates'
import { Flex, H6 } from '@components/atoms'
import React, { useEffect, useRef, useState } from 'react'
import { fluent_arrow_24, share_img_black } from '@images/index'

import CopyToClipboard from 'react-copy-to-clipboard'
import { DOMAIN_URL } from '@consts'
import FanTestEvent2Template from '@components/templates/FanTestEvent2Template'
import FanspotRequestListBottomModal from '@components/organisms/Popup/FanspotRequestListBottomModal'
import { Helmet } from 'react-helmet'
import { LinkCopiedPopup } from '@components/organisms'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import { useStore } from '@utils/hooks'
import FanTestEvent3Template from '@components/templates/FanTestEvent3Template'
import { getIsWebApp } from '@utils/postMessage'

const FanTestEvent3Page = ({ history }) => {
  const { authStore } = useStore()
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const isWebApp = getIsWebApp({ navigator })

  const linkCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
  }

  useEffect(() => {
    pixelTrack(PIXEL_EVENTS.덕력고사3.접속)
    if (typeof window.twq === 'function') {
      window.twq('event', 'tw-owc18-p7mpr')
    }
  }, [])

  return (
    <div
      style={
        {
          position: 'relative',
          overflow: 'auto',
          height: isWebApp ? '100vh' : 'calc(100vh - 44px)',
        }
      }
    >
      <Helmet>
        <title>{`장희원X베티붑 | 플램`}</title>
        <meta name="description" content={'덕력고사 이벤트'} />
        <meta
          name="keywords"
          content="팬스팟,부캐,아이돌,디스코그라피,음악,음반,앨범,음원유통,음원유통사,CD,아이돌,뮤지션,아티스트,프로필,music,album,linkfire,플램인,플램,plam,plamin"
        />
      </Helmet>
      <Header
        className={'header'}
        style={
          {
            backgroundColor: `rgba(255, 255, 255, 1)`,
          }
        }
        justify="space-between"
        align="center"
      >
        <Flex
          style={{ width: '44px', height: '44px', cursor: 'pointer' }}
          align="center"
          justify="center"
          onClick={
            () => {
              pixelTrack(PIXEL_EVENTS.덕력고사3.뒤로)
              history.push('/')
            }
          }
        >
          <PrevButton src={fluent_arrow_24} />
        </Flex>
        <HeaderTitle
          style={
            {
              width: '100%',
              justifyContent: 'flex-start',
            }
          }
          align={'left'}
        >
          <H6 style={{ alignSelf: 'center' }}>
            {'장희원X베티붑 콜라보 이벤트'}
          </H6>
        </HeaderTitle>

        <CopyToClipboard
          text={`${DOMAIN_URL}/event/fantest3`}
          onCopy={
            () => {
              pixelTrack(PIXEL_EVENTS.덕력고사3.공유)
              if (typeof window.twq === 'function') {
                window.twq('event', 'tw-owc18-p7mpq')
              }
              linkCopy()
            }
          }
        >
          <Flex
            style={{ width: '44px', height: '44px', cursor: 'pointer' }}
            justify={'center'}
            align="center"
          >
            <ShareButton src={share_img_black} alt="share_icon" />
          </Flex>
        </CopyToClipboard>
      </Header>
      <FanTestEvent3Template history={history} authStore={authStore} />

      {isCopied && <LinkCopiedPopup />}
    </div>
  )
}

export default FanTestEvent3Page

const Header = styled(Flex)`
  height: 52px;
  padding-right: 8px;
  position: sticky;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  transition: background-color 0.3s ease; /* 배경색 부드럽게 전환 */
`

const HeaderTitle = styled(Flex)`
  color: #000;
  width: '100%';
  z-index: -1;
`

const PrevButton = styled.img`
  width: 32px;
  height: 32px;
`

const ShareButton = styled.img`
  width: 24px;
  height: 24px;
  /* filter: ${props =>
    props.isDarkBackground && props.headerOpacity < 0.2
      ? `invert(1)`
      : `invert(0)`}; */
`

import React from 'react'
import styled from 'styled-components'

const MbtiBtn = ({ onClick, text, style }) => {
  return (
    <Btn onClick={onClick} style={style}>
      <Text>{text}</Text>
    </Btn>
  )
}

export default MbtiBtn

const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;

  width: 131px;
  height: 48px;
  transform: skew(348deg);
  background: #000001;

  cursor: pointer;
`

const Text = styled.p`
  transform: skew(12deg);
  /* font-family: Pretendard-Bold; */
  font-family: RixYeoljeongdoPR;
  font-style: italic;

  font-size: 16px;
  color: #ffffff;
  text-align: ${({ align }) => (align ? `${align}` : 'center')};
  margin: 0;
  padding: 0;
`

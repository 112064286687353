import { Flex, H6, Input } from '@components/atoms'
import React, { useEffect, useState } from 'react'
import { fluent_arrow_24, x_btn_gray_img } from '@images/index'
import {
  search_btn_img,
  search_btn_img_gray,
  search_outline_24px,
} from '@images'
import styled, { css, keyframes } from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'

import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { SearchListItem } from '@components/molecules'
import { pixelTrack } from '@utils/facebook/pixel'

const searchOn = keyframes`from {transform: translateX(100%);}to {transform: translateX(0);}`
const searchOff = keyframes`from {transform: translateX(-100%);}to {transform: translateX(0);}`

const SearchBox = styled(Flex)`
  height: 56px;
  align-items: center;

  ${props =>
    props.isSearchMode === 'on'
    || props.isSearchMode === 'ing'
    || props.isSearchMode === 'searched'
      ? css`
          /* box-shadow: 0 1px 2px 0 rgba(36, 36, 36, 0.16); */
          animation: ${searchOn} 300ms linear;
          width: 100%;
          position: absolute;
          background: #ffffff;
          z-index: 8900;
        `
      : props.isSearchMode === 'off'
        ? css`
          animation: ${searchOff} 300ms linear;
          width: initial;
        `
        : false}
`

const SearchList = styled(Flex)`
  position: absolute;
  background-color: #ffffff;
  top: 56px;
  width: 100%;
  height: calc(100vh - 56px);
`

const SearchListContent = styled(Flex)`
  flex-direction: column;
  padding: 20px;
  width: 100%;
  > div:first-child {
    padding-top: 0px;
  }
  > div:last-child {
    padding-bottom: 0px;
  }
  > div {
    padding: 12px 0;
    cursor: pointer;
  }
`

const SearchArea = ({
  isHome,
  isSearchMode,
  isSearched,
  searchKeyword,
  updateIsSearchMode,
  updateIsSearched,
  updateSearchKeyword,
  autocompleteKeyword,
  fetchSearchKeyword,
  fetchSearchAutoComplete,
  updateSearchCount,
  setBeforeSearchPage,
  beforeSearchPage,
}) => {
  const location = useLocation()
  const history = useHistory()

  const handleSearch = async _searchKeyword => {
    if (_searchKeyword === '' || !_searchKeyword) return
    // await fetchSearchKeyword(_searchKeyword)

    updateSearchKeyword(_searchKeyword)
    updateIsSearchMode('searched')
    updateIsSearched(true)

    // 첫 검색 시, 검색 전 페이지 저장
    if (!isSearched) {
      setBeforeSearchPage(location.pathname)
    }

    history.push(`/search?keyword=${encodeURIComponent(_searchKeyword)}`)
  }

  useEffect(() => {
    // 검색 상태 화면 분기 처리
    if (isSearchMode == 'on' || isSearchMode == 'ing') {
      if (searchKeyword !== '') {
        updateIsSearchMode('ing')
        // document.body.style.overflow = 'hidden'
      }
      else {
        updateIsSearchMode('on')
        // updateIsSearched(false)
      }
    }
    else if (isSearchMode !== 'searched') {
      updateIsSearchMode('off')
      // document.body.style.overflow = 'unset'
      updateIsSearched(false)
    }
  }, [searchKeyword, isSearchMode])

  useEffect(() => {
    updateIsSearchMode('off')
    updateIsSearched(false)
  }, [location])

  return (
    <>
      <SearchBox isSearchMode={isSearchMode}>
        {
          isSearchMode === 'on'
        || isSearchMode === 'ing'
        || isSearchMode === 'searched' ? (
              <img
                src={fluent_arrow_24}
                style={
                  {
                    widht: '32px',
                    height: '32px',
                    cursor: 'pointer',
                    padding: '6px',
                    margin: '0 4px',
                  }
                }
                onClick={
                  () => {
                    updateIsSearchMode('off')
                    updateSearchKeyword('')
                    if (isSearchMode === 'searched') {
                      history.push(beforeSearchPage)
                    }
                  }
                }
              />
            ) : (
              <img
                src={
                  isHome
                    ? search_outline_24px
                    : isSearchMode === 'on'
                  || isSearchMode === 'ing'
                  || isSearchMode === 'searched'
                      ? search_outline_24px
                      : search_btn_img
                }
                style={
                  {
                    widht: '24px',
                    height: '24px',
                    marginRight: '16px',
                    cursor: 'pointer',
                    marginLeft: isSearchMode ? '16px' : 0,
                  }
                }
                onClick={
                  () => {
                    updateIsSearchMode('on')
                    pixelTrack(PIXEL_EVENTS.홈.검색, {})
                  }
                }
              />
            )
        }
        {
          (isSearchMode === 'on'
          || isSearchMode === 'ing'
          || isSearchMode === 'searched') && (
            <>
              <SearchInplamContainer
                align="center"
                style={{ width: '100%', marginRight: '20px' }}
              >
                <SearchInplamInput
                  placeholder="통합 검색"
                  onChange={
                    e => {
                      updateSearchKeyword(e.target.value)
                      updateIsSearchMode('ing')
                      // updateIsSearched(false)
                      fetchSearchAutoComplete(e.target.value)
                    }
                  }
                  onKeyDown={e => e.keyCode === 13 && handleSearch(searchKeyword)}
                  value={searchKeyword}
                />
                {
                  searchKeyword !== '' && (
                    <Flex
                      justify="center"
                      align="center"
                      style={{ width: 40, height: 40 }}
                      onClick={
                        () => {
                          updateSearchKeyword('')
                        }
                      }
                    >
                      <img
                        src={x_btn_gray_img}
                        alt={'clear_btn'}
                        style={{ cursor: 'pointer' }}
                        width={16}
                        height={16}
                      />
                    </Flex>
                  )
                }
              </SearchInplamContainer>
            </>
          )
        }
      </SearchBox>
      {
        searchKeyword && isSearchMode === 'ing' && (
          <SearchList>
            <SearchListContent>
              {
                autocompleteKeyword.map((item, index) => (
                  <SearchListItem
                    _onClick={handleSearch}
                    updateSearchCount={updateSearchCount}
                    item={item}
                    key={index}
                  />
                ))
              }
            </SearchListContent>
          </SearchList>
        )
      }
    </>
  )
}

export default SearchArea

/* popular List end */
const SearchInplamContainer = styled(Flex)`
  width: 100%;
  height: 40px;
  /* padding: 6px 16px; */

  border: none !important;
  background-color: #f4f4f4;
  /* font-size: 16px; */
  border-radius: 8px;

  box-sizing: border-box;
`

const SearchInplamInput = styled(Input)`
  width: 100%;
  height: 40px;
  padding: 6px 16px;

  border: none !important;
  background-color: transparent;
  font-size: 12px;

  font-family: Pretendard-Regular;

  box-sizing: border-box;
`

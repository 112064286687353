import './youtubeInText.css'
import React, { useEffect } from 'react'
import qs from 'qs'

export function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  return (
    text
    && text.replace(urlRegex, url => {
      try {
        const parsedUrl = new URL(url)
        const query = qs.parse(parsedUrl.search, {
          ignoreQueryPrefix: true,
          // 문자열 맨 앞의 ?를 생력
        })

        /** ✅ YouTube 링크 처리 * */
        if (parsedUrl.hostname.includes('youtube.com')) {
          // 일반 YouTube 링크 (watch?v=VIDEO_ID)
          if (query.v) {
            return `<div class="youtube-in-text" style="margin-top:16px">
                      <iframe width="100%" height="100%"
                      src="https://www.youtube.com/embed/${query.v}?rel=0&modestbranding=1"
                      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
                    </div>`
          }
          // Shorts 영상 처리
          if (parsedUrl.pathname.startsWith('/shorts/')) {
            const videoId = parsedUrl.pathname.split('/')[2]
            return `<div class="youtube-in-text" style="margin-top:16px">
                      <iframe width="100%" height="100%"
                      src="https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1"
                      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
                    </div>`
          }
        }

        // 단축 URL (youtu.be/VIDEO_ID)
        if (parsedUrl.hostname.includes('youtu.be')) {
          const videoId = parsedUrl.pathname.split('/')[1]
          return `<div class="youtube-in-text" style="margin-top:16px">
                    <iframe width="100%" height="100%"
                    src="https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1"
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                  </div>`
        }

        /** ✅ Instagram 링크 처리 * */
        if (
          url.includes('instagram.com/p/')
          || url.includes('instagram.com/reel/')
        ) {
          return `
            <blockquote class="instagram-media" data-instgrm-permalink="${url}" data-instgrm-version="14"
              style="background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15);
              margin: 1px; max-width:540px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px);
              width:calc(100% - 2px);">
              <a href="${url}" target="_blank">Instagram에서 이 게시물 보기</a>
            </blockquote>
            <script async src="//www.instagram.com/embed.js"></script>`
        }

        /** ✅ X (구 Twitter) 링크 처리 * */
        if (url.includes('twitter.com') || url.includes('x.com')) {
          return `<blockquote class="twitter-tweet">
                    <a href="${url.replace('x.com', 'twitter.com')}"></a>
                  </blockquote>
                  <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`
        }
      }
      catch (e) {
        console.error('Invalid URL parsing:', e)
      }

      /** 🔗 기본적인 링크 변환 * */
      return url.replace(
        urlRegex,
        '<a style="color: -webkit-link" href="$1" target="_blank">$1</a>',
      )
    })
  )
}

const YoutubeInText = ({ text }) => {
  useEffect(() => {
    // 인스타그램 임베드 스크립트 로드
    if (window.instgrm) {
      window.instgrm.Embeds.process()
    }
    else {
      const script = document.createElement('script')
      script.async = true
      script.src = '//www.instagram.com/embed.js'
      document.body.appendChild(script)
    }

    try {
      // X 임베드 스크립트 로드
      if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load()
      }
      else {
        const script = document.createElement('script')
        script.async = true
        script.src = 'https://platform.twitter.com/widgets.js'
        script.onload = () => {
          // 스크립트 로드 후 위젯 초기화
          if (window.twttr && window.twttr.widgets) {
            window.twttr.widgets.load()
          }
        }
        script.onerror = () => {
          console.error('Failed to load Twitter widgets script.')
        }
        document.body.appendChild(script)
      }
    }
    catch (error) {
      console.error('Error loading Twitter widgets:', error)
    }
  }, [text])

  return (
    <div
      className="youtube-in-text-wrapper"
      dangerouslySetInnerHTML={
        {
          __html: urlify(text),
        }
      }
    />
  )
}

export default YoutubeInText

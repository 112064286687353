import React, { useEffect, useState } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import ReactLoading from 'react-loading'
import { pixelTrack } from '@utils/facebook/pixel'
import { ARTIST_PAGE_TEXT } from '@consts'
import { ArtistTrackItem } from '../../molecules'

import { Flex, Typography } from '../../atoms'

const ArtistSongList = ({
  list,
  sortBy,
  setSortBy,
  offset,
  setOffset,
  maxCount,
  lang,
}) => {
  const countOffset = () => {
    setOffset(offset + 1)
  }

  return (
    <Flex type="column">
      <div
        style={
          {
            alignSelf: 'flex-end',
            margin: '-17px 0 20px 0',
          }
        }
      >
        <Typography
          type="Medium"
          size="12px"
          color={sortBy === 'likeCount' ? '#242424' : '#c9c9c9'}
          style={{ paddingRight: '10px', cursor: 'pointer' }}
          onClick={
            () => {
              setSortBy('likeCount')
              pixelTrack(PIXEL_EVENTS.아티스트.트랙_인기순)
            }
          }
        >
          {ARTIST_PAGE_TEXT[lang]['order_popular']}
        </Typography>
        <Typography
          type="Medium"
          size="12px"
          color={sortBy === 'albumInfo.releasedAt' ? '#242424' : '#c9c9c9'}
          style={{ paddingRight: '10px', cursor: 'pointer' }}
          onClick={
            () => {
              setSortBy('albumInfo.releasedAt')
              pixelTrack(PIXEL_EVENTS.아티스트.트랙_최신순)
            }
          }
        >
          {ARTIST_PAGE_TEXT[lang]['order_recent']}
        </Typography>
        {/* <Typography
          type="Medium"
          size="12px"
          color={sortBy === 'title' ? '#242424' : '#c9c9c9'}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSortBy('title')
            pixelTrack(PIXEL_EVENTS.아티스트.트랙_이름순)
          }}
        >
          이름순
        </Typography> */}
      </div>
      <InfiniteScroll
        dataLength={list.length}
        scrollThreshold={0.8}
        next={countOffset}
        hasMore={maxCount > list.length}
        loader={
          <ReactLoading
            type="spin"
            color="black"
            style={
              {
                width: '50px',
                height: '50px',
                margin: '0 auto 50px auto',
              }
            }
          />
        }
        // className="scroll_div"
      >
        {
          list
          && list.map((item, index) => (
            <ArtistTrackItem
              item={item}
              index={index}
              key={index}
              lang={lang}
            />
          ))
        }
      </InfiniteScroll>
    </Flex>
  )
}

export default ArtistSongList

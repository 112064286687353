import React, { useEffect, useState } from 'react'
import {
  ArtistTemplate,
  NotFoundTemplate,
  AlbumTemplate,
  TrackTemplate,
} from '@components/templates'
import { inject, observer } from 'mobx-react'
import { useLastLocation } from 'react-router-last-location'
import qs from 'qs'
import { IS_DEV, DOMAIN_URL_LENGTH } from '@consts'
import { useParams } from 'react-router-dom'

const PAGE_TYPE = {
  ARTIST: 'artist',
  ALBUM: 'album',
  TRACK: 'track',
  NOT_FOUND: 'notFound',
}
const DATA_TYPE_TO_PAGE_TYPE = {
  artist: PAGE_TYPE['ARTIST'],
  album: PAGE_TYPE['ALBUM'],
  track: PAGE_TYPE['TRACK'],
  notFound: PAGE_TYPE['NOT_FOUND'],
}

interface SubdomainPageProps {
  recordStore: RecordStore;
  dataMapStore: DataMapStore;
  authStore: AuthStore;
  statsStore: StatsStore;
  commentStore: CommentStore;
  match: Any;
  history: Any;
  location: Any;
}

const SubdomainPage = ({
  match,
  history,
  location,
  recordStore,
  dataMapStore,
  authStore,
  statsStore,
  commentStore,
}: SubdomainPageProps) => {
  const { host } = window.location
  const shortId = match.params.id
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })
  const parts = host.split('.')
  const subdomain =
    (parts.length > DOMAIN_URL_LENGTH && parts[0])
    || (IS_DEV && query?.subdomain)
    || null

  const { lang } = useParams()

  const recordId = query?.rid ? query?.rid : null
  /* store */
  const enterRecord = recordStore.enterRecord || (() => {})
  const recorded = recordStore.recorded || null

  const socialLogin = authStore.socialLogin || (() => {})
  const logout = authStore.logout || (() => {})
  const currentUser = authStore.currentUser || null

  const fetchLike = authStore.fetchLike || (() => {})
  const updateLike = authStore.updateLike || (() => {})
  const { likeInfo } = authStore

  const commentList = commentStore.commentList || null
  const commentListCount = commentStore.commentListCount || null

  const artistFollowerInfo = statsStore.artistFollowerInfo || null
  /* store end */

  const lastLocation = useLastLocation()

  const pageType = DATA_TYPE_TO_PAGE_TYPE[dataMapStore.dataType]
  const data = dataMapStore.data || null

  const [hideRecordId, setHideRecordId] = useState(false)

  // put record
  const updateRecord = async (type, url) => {
    const params = {
      _id: sessionStorage.getItem('recordId'),
      clickType: type,
      clickUrl: url,
    }

    await recordStore.updateRecord(params)
  }

  useEffect(() => {
    if (recordId !== null) {
      sessionStorage.setItem('recordId', recordId)
    }

    // const subdomain = match.params.subdomain
    // const shortId = match.params.id

    if (shortId) {
      dataMapStore.fetchShortenUrlData(subdomain, shortId)
    }
    else {
      dataMapStore.fetchShortenUrlData(subdomain)
    }

    // post Record
    // const enterPage = {
    //   referrer: document.referrer,
    //   domain: document.domain,
    //   URL: document.URL,
    //   lastLocation: lastLocation,
    //   history: history,
    //   performance: performance,
    //   type: 'subdomain',
    //   pathId: shortId ? shortId : subdomain,
    // }
    // enterRecord(enterPage)

    if (!currentUser) {
      localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [match])

  useEffect(() => {
    // post Record
    const enterPage = {
      referrer: document.referrer,
      domain: document.domain,
      URL: document.URL,
      lastLocation,
      history,
      performance,
      type: 'subdomain',
      pathId: subdomain,
    }

    if (!recorded) {
      enterRecord(enterPage)
    }
  }, [match])

  if (pageType !== PAGE_TYPE['NOT_FOUND']) {
    return (
      <>
        {
          recordId !== null
        && sessionStorage.getItem('recordId')
        && !hideRecordId
            ? (setHideRecordId(true),
            window.history.replaceState(
              null,
              null,
              shortId ? shortId : subdomain,
            ))
            : false
        }
        {/* {recordId !== null && sessionStorage.getItem('recordId') && (
        <Redirect to={location.pathname} />
      )} */}
        {
          pageType === PAGE_TYPE['ARTIST'] ? (
            <ArtistTemplate
              artist={data}
              currentPath={host + match.url}
              updateRecord={updateRecord}
              currentUser={currentUser}
              socialLogin={socialLogin}
              logout={logout}
              fetchLike={fetchLike}
              likeInfo={likeInfo}
              updateLike={updateLike}
              artistFollowerInfo={artistFollowerInfo}
              lang={lang}
            />
          ) : pageType === PAGE_TYPE['ALBUM'] ? (
            <AlbumTemplate
              album={data}
              currentPath={host + match.url}
              updateRecord={updateRecord}
              currentUser={currentUser}
              socialLogin={socialLogin}
              logout={logout}
              fetchLike={fetchLike}
              likeInfo={likeInfo}
              updateLike={updateLike}
              commentList={commentList}
              commentListCount={commentListCount}
              lang={lang}
            />
          ) : (
            pageType === PAGE_TYPE['TRACK'] && (
              <TrackTemplate
                track={data}
                currentPath={host + match.url}
                updateRecord={updateRecord}
                currentUser={currentUser}
                socialLogin={socialLogin}
                logout={logout}
                fetchLike={fetchLike}
                likeInfo={likeInfo}
                updateLike={updateLike}
                commentList={commentList}
                commentListCount={commentListCount}
                lang={lang}
              />
            )
          )
        }
      </>
    )
  }
  return <NotFoundTemplate />
}

export default inject(
  'recordStore',
  'dataMapStore',
  'authStore',
  'statsStore',
  'commentStore',
)(observer(SubdomainPage))

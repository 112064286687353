// 주어진 배열에서 요소 1개를 랜덤하게 골라 반환하는 함수
export function randomItem(list) {
  return list[Math.floor(Math.random() * list.length)]
}

export const shuffleArray = arr => {
  for (let i = 0; i < arr.length; i++) {
    const j = Math.floor(Math.random() * (i + 1))
    // [arr[i], arr[j]] = [arr[j], arr[i]];
    const x = arr[i]
    arr[i] = arr[j]
    arr[j] = x
  }
  return arr
}

export function getRandomSubset(array, size) {
  const shuffled = [...array] // 원본 배열을 변경하지 않도록 복사
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)) // 0 ~ i 사이의 랜덤 인덱스 선택
    ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]] // swap
  }
  return shuffled.slice(0, size) // 처음 size개 추출
}

import React, { useEffect, useRef, useState } from 'react'

import { Caption2 } from '@components/atoms'
import HomeEventBannerListItem from '@components/molecules/box/HomeEventBannerListItem'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { pixelTrack } from '@utils/facebook/pixel'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  margin-left: -20px;
  position: relative;
`

const Label = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 20px;
  margin-top: 40px;
  margin-bottom: 24px;
`

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;

  /* padding-left: 20px; */
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scroll-snap-stop: always;
`

const SnapItem = styled.div`
  scroll-snap-align: start; /* Ensures each item snaps into place */
  scroll-snap-stop: always;
  padding-left: ${props => (props.$isFirst ? '18px' : '18px')};
`

export default function HomeEventBannerList({
  bannerList,
  setShowDownloadPopup,
}) {
  const history = useHistory()

  const scrollContainerRef = useRef(null)
  const currentIndexRef = useRef(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const intervalIdRef = useRef(null)

  const [isShowBanner, setIsShowBanner] = useState(true)

  const startAutoScroll = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }

    intervalIdRef.current = setInterval(() => {
      const scrollContainer = scrollContainerRef.current
      if (scrollContainer && bannerList.length > 0) {
        currentIndexRef.current =
          (currentIndexRef.current + 1) % bannerList.length

        const nextItem = scrollContainer.children[currentIndexRef.current]
        scrollContainer.scrollTo({
          left: nextItem.offsetLeft,
          behavior: 'smooth',
        })
      }
    }, 5000)
  }

  useEffect(() => {
    startAutoScroll()

    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    const handleUserScroll = () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
      }

      const children = Array.from(scrollContainer.children)
      const closestIndex = children.reduce((closestIdx, child, idx) => {
        const currentScroll = Math.abs(
          scrollContainer.scrollLeft - child.offsetLeft,
        )
        const closestScroll = Math.abs(
          scrollContainer.scrollLeft - children[closestIdx].offsetLeft,
        )
        return currentScroll < closestScroll ? idx : closestIdx
      }, 0)

      currentIndexRef.current = closestIndex
      if (currentIndex !== closestIndex) {
        setCurrentIndex(closestIndex)
      }
      startAutoScroll()
    }

    scrollContainer.addEventListener('scroll', handleUserScroll)

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
      }
      scrollContainer.removeEventListener('scroll', handleUserScroll)
    }
  }, [bannerList, currentIndex])

  function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    // 일반적인 모바일 기기
    if (/android/i.test(userAgent)) {
      return true
    }
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return true
    }
    return false
  }

  const handleClick = (index: number) => {
    try {
      const fullUrl = bannerList?.[index]?.[`link`]

      if (fullUrl?.includes('plamin.page.link') && !isMobile()) {
        setShowDownloadPopup(true)
      }
      else {
        window.location.href = fullUrl
      }
    }
    catch (e) {}
  }

  if (!isShowBanner) {
    return null
  }

  return (
    <Container>
      <ScrollContainer ref={scrollContainerRef}>
        {
          bannerList.map((event, index) => {
            return (
              <SnapItem key={`banner-${index}`} $isFirst={index === 0}>
                <HomeEventBannerListItem
                  event={event}
                  handleClick={
                    () => {
                      pixelTrack(PIXEL_EVENTS.홈.배너, {
                        bannerId: event._id,
                        bannerTitle: event.title,
                        bannerLink: event.link,
                      })
                      handleClick(index)
                    }
                  }
                />
              </SnapItem>
            )
          })
        }
      </ScrollContainer>
      {
bannerList?.length > 1 && (
          <div
            style={
              {
                position: 'absolute',
                bottom: '13px',
                right: '28px',
                display: 'flex',
                flexDirection: 'row',
                padding: '2px 6px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '999px',
                background: 'rgba(0, 0, 0, 0.60)',
              }
            }
          >
            <Caption2 color={'#fff'}>
              {`${currentIndex + 1} / ${bannerList?.length}`}
            </Caption2>
          </div>
        )
      }
    </Container>
  )
}

import { BodyWrapper, Caption1, Caption2, Flex, H5 } from '@components/atoms'
import React from 'react'
import styled from 'styled-components'
import {
  arrow_left_img,
  link_copy_img_gray,
  move_down_gray_img,
  youtube_follower_img,
} from '@images/index'
import { useHistory } from 'react-router-dom'
import { convertToPricingComma, dateYMDMM } from '@utils/format'
import {
  DailySnsTableChart,
  SnsLineChart,
  TrackSnsTableChart,
} from '@components/organisms'

const ArtistYoutubeTemplate = ({
  artist,
  artistTrend,
  periodType,
  setPeriodType,
}) => {
  const history = useHistory()

  const likeCountInfo = artistTrend?.likeCountInfo || null
  const listenerInfo = artistTrend?.listenerInfo || null
  const followersInfo = artistTrend?.followersInfo || null

  return (
    <>
      <Header>
        <img
          src={arrow_left_img}
          alt="back button"
          width={24}
          height={24}
          onClick={() => history.goBack()}
        />
        <H5 align="left">{artist?.name}</H5>
        {
artist?.youtubeUrl && (
            <Flex
              justify="center"
              align="center"
              style={
                {
                  width: 28,
                  height: 28,
                  borderRadius: '50%',
                  backgroundColor: '#f4f4f4',
                }
              }
              onClick={() => window.open(artist?.youtubeUrl)}
            >
              <img
                src={link_copy_img_gray}
                alt="copy button"
                width={20}
                height={20}
              />
            </Flex>
          )
        }
      </Header>
      <BodyWrapper
        style={{ paddingTop: 56 + 16, paddingBottom: 64, minHeight: '100vh' }}
      >
        <Flex
          align="center"
          style={
            {
              gap: 10,
              paddingBottom: 16,
              borderBottom: '1px solid #f0f0f0',
            }
          }
        >
          <img
            src={youtube_follower_img}
            width={32}
            height={32}
            alt="melon logo"
          />
          <H5 align="left" style={{ flex: 1 }} type="Bold">
            Youtube
          </H5>
          <Flex align="center" style={{ gap: 4 }}>
            <Select
              value={periodType.value}
              onChange={
                e => {
                  setPeriodType(e.currentTarget.value)
                }
              }
            >
              <option value={28}>전체</option>
              <option value={1}>지난 1일</option>
              <option value={7}>지난 7일</option>
              <option value={28}>지난 28일</option>
            </Select>
          </Flex>
        </Flex>
        <Flex type="column" style={{ gap: 12 }}>
          <Flex
            justify="space-between"
            align="flex-end"
            style={{ marginTop: 24 }}
          >
            <H5 type="Bold">Total daily</H5>
            <Caption2 color="#646464">
              {
                artist && artist.nowLikeCrawling
                  ? '정보를 업데이트 중 입니다.'
                  : artistTrend && artistTrend.trendDataAddedAt
                    ? `${artistTrend && dateYMDMM(artistTrend.trendDataAddedAt)}`
                    : '업데이트할 데이터가 없습니다.'
              }
            </Caption2>
          </Flex>
          <CountCard
            text={
              `${convertToPricingComma(
              listenerInfo?.today?.youtube || 0,
              )} Listen`
            }
            increaseValue={listenerInfo?.increaseValue?.youtube || 0}
          />
          <CountCard
            text={
              `${convertToPricingComma(
              likeCountInfo?.today?.youtube || 0,
              )} Like`
            }
            increaseValue={likeCountInfo?.increaseValue?.youtube || 0}
          />
          <CountCard
            text={
              `${convertToPricingComma(
              followersInfo?.today?.youtube || 0,
              )} Follower`
            }
            increaseValue={followersInfo?.increaseValue?.youtube || 0}
          />
          <SnsLineChart
            dailyListData={artistTrend?.totalDailyList || []}
            snsType="youtube"
          />
        </Flex>
        <Flex type="column" style={{ gap: 12 }}>
          <Flex
            justify="space-between"
            align="flex-end"
            style={{ marginTop: 24 }}
          >
            <H5 type="Bold">Daily</H5>
          </Flex>
          <DailySnsTableChart
            data={artistTrend?.totalDailyList || []}
            snsType="youtube"
          />
          <Button
            onClick={
              () =>
                history.push(`/artist/${artist.uniqueName}/youtube/daily`)
            }
          >
            <Caption1 color="#646464">모두 보기</Caption1>
          </Button>
          <div
            style={
              {
                marginTop: '24px',
                height: 6,
                backgroundColor: '#fafafa',
                width: '100vw',
                marginLeft: -16,
                borderTop: '1px solid #f4f4f4',
              }
            }
          />
        </Flex>
        <Flex type="column" style={{ gap: 12 }}>
          <Flex
            justify="space-between"
            align="flex-end"
            style={{ marginTop: 24 }}
          >
            <H5 type="Bold">Track</H5>
          </Flex>
          <TrackSnsTableChart snsType="youtube" />
          <Button
            onClick={
              () =>
                history.push(`/artist/${artist.uniqueName}/youtube/track`)
            }
          >
            <Caption1 color="#646464">모두 보기</Caption1>
          </Button>
        </Flex>
      </BodyWrapper>
    </>
  )
}

export default ArtistYoutubeTemplate

const Header = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  min-width: 320px;
  max-width: 420px;
  height: 56px;
  z-index: 2;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  gap: 8px;
  border-bottom: 1px solid #f4f4f4;

  > h5 {
    flex: 1;
  }

  img {
    cursor: pointer;
  }
`

const Select = styled.select`
  font-size: 12px;
  font-family: Pretendard-Medium;
  -webkit-appearance: none;
  border: none;

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-position-x: 50px;
  background-size: 12px;
  background-repeat: no-repeat;
  width: 62px;
  display: block;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`

const Up = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #66bc6a;

  margin-top: 2px;
`

const Down = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #ea4653;

  margin-top: 2px;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 10px;
  border-radius: 2px;
  background-color: #f8f8f8;
  border: none;
`

const CountCard = ({ text, increaseValue }) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      style={
        {
          height: 48,
          borderRadius: '4px',
          backgroundColor: '#f8f8f8',
          padding: 12,
          boxSizing: 'border-box',
        }
      }
    >
      <Caption1 type="Bold">{text}</Caption1>
      <Flex align="center" style={{ gap: 4 }}>
        {increaseValue > 0 ? <Up /> : increaseValue < 0 ? <Down /> : <></>}
        <Caption2 color="#949494">
          {
            increaseValue === 0
              ? '-'
              : convertToPricingComma(Math.abs(increaseValue))
          }
        </Caption2>
      </Flex>
    </Flex>
  )
}

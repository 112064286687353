import {
  BodyWrapper,
  Caption1,
  Caption2,
  Flex,
  H2,
  H4,
  H6,
  Input,
} from '@components/atoms'
import {
  BurgerMenu,
  CommentCard,
  FanspotScoreCard,
  InPlamList,
  LinkCopiedPopup,
  LoginPopup,
  OkCancelPopup,
} from '@components/organisms'
import { DOMAIN_URL, STORAGE_URL } from '@consts/'
import React, { useEffect, useRef, useState } from 'react'
import {
  albums_fill_24px,
  arrow_right_outline_24px,
  arrow_right_white_img,
  fluent_arrow_24,
  move_btn_img,
  pen_fill_24,
  search_outline_24px,
  star_fill_24,
  white_pen_fill_24px,
} from '@images/index'
import {
  comment_img,
  edit_img_black,
  share_img_black,
  x_btn_gray_img,
} from '@images/'
import { converseCommentUnitEng, convertToPricingComma } from '@utils/format'
import styled, { css } from 'styled-components'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Helmet } from 'react-helmet'
import { action } from 'mobx'
import { colors } from '@colors/'
import { useHistory } from 'react-router-dom'
import { useObserver } from 'mobx-react'
import { getIsWebApp } from '@utils/postMessage'
import { useStore } from '@utils/hooks'

interface CommmentTemplateProps {
  currentUser: any;
  isLike: boolean;
  uniqueName: string;
  artistInfo: object;
  offset: Number;
  setOffset: Function;
  sortBy: string;
  setSortBy: Function;
  isFromArtist: string;
  toggleFromArtist: Function;
  artistArticleList: Array<any>;
  popularArtistArticleList: Array<any>;
  artistArticleListCount: Number;
  createComment: Function;
  removeComment: Function;
  _fetchCommentList: Function;
  updateCommentLike: Function;
  updateLike: Function;
  showLoginPopup: boolean;
  setShowLoginPopup: Function;
  needAuth: Function;
  currentPath: any;
}

// 인기 글 컴포넌트
const PopularList = ({ popularArtistArticleList, history, uniqueName }) => {
  if (popularArtistArticleList && popularArtistArticleList.length > 0) {
    return (
      <>
        <ListBox>
          <Flex align="center" style={{ marginBottom: 10 }}>
            <H6 type="Bold" align="left">
              인기 글
            </H6>
          </Flex>
          <PopularInPlamList>
            <InPlamListItem
              onClick={
                () =>
                  history.push(
                    `/fanspot/${uniqueName}/${popularArtistArticleList[0]._id}`,
                  )
              }
            >
              <TitleText>{popularArtistArticleList[0].title}</TitleText>
              <CommentCountText>
                {
                  popularArtistArticleList[0].commentCount
                  && converseCommentUnitEng(
                    popularArtistArticleList[0].commentCount,
                  )
                }
              </CommentCountText>
            </InPlamListItem>
            <InPlamListItem
              onClick={
                () =>
                  history.push(
                    `/fanspot/${uniqueName}/${popularArtistArticleList[1]._id}`,
                  )
              }
            >
              <TitleText>{popularArtistArticleList[1].title}</TitleText>
              <CommentCountText>
                {
                  popularArtistArticleList[1].commentCount
                  && converseCommentUnitEng(
                    popularArtistArticleList[1].commentCount,
                  )
                }
              </CommentCountText>
            </InPlamListItem>
            <InPlamListItem
              onClick={
                () =>
                  history.push(
                    `/fanspot/${uniqueName}/${popularArtistArticleList[2]._id}`,
                  )
              }
            >
              <TitleText>{popularArtistArticleList[2].title}</TitleText>
              <CommentCountText>
                {
                  popularArtistArticleList[2].commentCount
                  && converseCommentUnitEng(
                    popularArtistArticleList[2].commentCount,
                  )
                }
              </CommentCountText>
            </InPlamListItem>
          </PopularInPlamList>
        </ListBox>
      </>
    )
  }
  return <></>
}

const InPlamArtistTemplate = ({
  currentUser,
  uniqueName,
  offset,
  handlePagination,
  sortBy,
  handleChangeSortBy,
  isFromArtist,
  toggleFromArtist,
  artistInfo,
  artistArticleList,
  popularArtistArticleList,
  artistArticleListCount,
  currentPath,
  artistFanSpotPointInfo,
  searchWord,
  handleChangeSearchWord,
  categoryList,
  category,
  handleChangeCategory,
  needAuth,
}: CommmentTemplateProps) => {
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false) // 햄버거
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무
  const { authStore } = useStore()

  const containerRef = useRef(null)

  const [imageHeight, setImageHeight] = useState(0)

  const [scrollY, setScrollY] = useState(0) // 현재 스크롤 위치 저장
  const [isShowFloatingButton, setIsShowFloatingButton] = useState(0) // 배경색 투명도
  const [headerOpacity, setHeaderOpacity] = useState(0) // 배경색 투명도
  const [titleOpacity, setTitleOpacity] = useState(0) // 제목 투명도

  const [isDarkBackground, setIsDarkBackground] = useState(false)
  const imgContainerRef = useRef(null)

  const [inputValue, setInputValue] = useState(searchWord ? searchWord : '') // view 검색어

  const isWebApp = getIsWebApp({ navigator })

  const linkCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
  }

  const handleMoveDetail = item => {
    if (!item?._id) return
    history.push(
      `/fanspot/${uniqueName}/${item?._id}?offset=${offset}&sortBy=${sortBy}&category=${item?.articleCategoryId}`,
    )
  }

  const handleInput = value => {
    setInputValue(value)
  }
  const handleSearch = () => {
    if (inputValue) {
      handleChangeSearchWord(inputValue)
    }
    else {
      handleChangeSearchWord(null)
    }
  }

  useEffect(() => {
    if (!artistInfo) return
    const container = imgContainerRef.current
    if (!container) return

    // 배경 이미지 URL 추출
    const imageUrl = container.src

    if (!imageUrl) return

    // 이미지를 로드한 후 밝기 계산
    const img = new Image()
    img.crossOrigin = 'Anonymous' // CORS 문제 해결
    img.src = imageUrl

    img.onload = () => {
      const calculateBrightness = () => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const headerHeight = 52 // 헤더 높이
        const width = container?.offsetWidth || 420

        canvas.width = width
        canvas.height = headerHeight

        // 캔버스에 배경 이미지의 상단 52px만 그리기
        context.drawImage(img, 0, 0, width, headerHeight)

        // 픽셀 데이터 가져오기
        const imageData = context.getImageData(0, 0, width, headerHeight)
        const { data } = imageData
        let r
        let g
        let b
        let avg
        let colorSum = 0

        for (let i = 0; i < data.length; i += 4) {
          r = data[i]
          g = data[i + 1]
          b = data[i + 2]
          avg = (r + g + b) / 3
          colorSum += avg
        }

        const brightness = colorSum / (data.length / 4) // 평균 밝기 계산

        setIsDarkBackground(brightness < 128) // 밝기 기준(128)으로 판단
      }

      calculateBrightness()
    }
  }, [artistInfo])

  useEffect(() => {
    const container = containerRef.current

    const handleScroll = () => {
      const currentScrollY = container.scrollTop
      setScrollY(currentScrollY)

      // 배경색 투명도 계산 (0 ~ 1)
      const maxScroll = 136 // 136px에서 완전히 흰색
      setHeaderOpacity(Math.min(currentScrollY / maxScroll, 1))

      // 제목 투명도 계산 (100px에서 시작, 136px에서 완전히 보임)
      const titleStart = 100
      const titleEnd = 136
      if (currentScrollY >= titleEnd) {
        setTitleOpacity(1)
      }
      else if (currentScrollY <= titleStart) {
        setTitleOpacity(0)
      }
      else {
        setTitleOpacity((currentScrollY - titleStart) / (titleEnd - titleStart))
      }

      if (currentScrollY >= 500) {
        setIsShowFloatingButton(1)
      }
      else if (currentScrollY < 500) {
        setIsShowFloatingButton(0)
      }
    }

    container.addEventListener('scroll', handleScroll)

    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const parentRef = useRef(null)
  const [parentOffset, setParentOffset] = useState({ left: 0, width: 0 })

  useEffect(() => {
    const updateOffset = () => {
      if (parentRef.current) {
        const rect = parentRef.current.getBoundingClientRect()

        setParentOffset(rect)
      }

      if (imgContainerRef.current) {
        setImageHeight(imgContainerRef.current.offsetHeight)
      }
    }

    // 초기 위치 설정
    updateOffset()

    // 창 크기 변경 시 위치 업데이트
    window.addEventListener('resize', updateOffset)
    return () => {
      window.removeEventListener('resize', updateOffset)
    }
  }, [])

  return useObserver(() => (
    <div
      style={{ position: 'relative', overflow: 'auto', height: '100vh' }}
      ref={containerRef}
    >
      <Helmet>
        {
artistInfo?.isDefaultFanSpot ? (
  <title>{`(자유) 팬스팟`}</title>
) : (
  <title>{`(${artistInfo && artistInfo.name}) 팬스팟`}</title>
)
        }
        <meta
          name="description"
          content="나의 소중한 아티스트와 소통하는 공간, 팬스팟"
        />
      </Helmet>
      <Header
        className={'header'}
        style={
          {
            backgroundColor: `rgba(255, 255, 255, ${headerOpacity})`,
          }
        }
        justify="space-between"
        align="center"
      >
        <Flex
          style={{ width: '44px', height: '44px', cursor: 'pointer' }}
          align="center"
          justify="center"
          onClick={
            () => {
              history.goBack()
            }
          }
        >
          <PrevButton
            src={fluent_arrow_24}
            isDarkBackground={isDarkBackground}
            headerOpacity={headerOpacity}
          />
        </Flex>
        <HeaderTitle
          style={{ flex: 1, opacity: titleOpacity }}
          align={'center'}
        >
          <H6>{artistInfo?.name}</H6>
          {
artistInfo?.isInplamFavorite && (
              <img
                src={star_fill_24}
                style={{ width: '20px', height: '20px', marginLeft: '2px' }}
                alt="move_icon"
              />
            )
          }
        </HeaderTitle>

        <CopyToClipboard
          text={
            artistInfo && artistInfo.subdomain
              ? `${artistInfo.subdomain}.${DOMAIN_URL}/fanspot`
              : currentPath
          }
          onCopy={
            () => {
              linkCopy()
            }
          }
        >
          <Flex
            style={{ width: '44px', height: '44px', cursor: 'pointer' }}
            justify={'center'}
            align="center"
          >
            <ShareButton
              src={share_img_black}
              alt="share_icon"
              isDarkBackground={isDarkBackground}
              headerOpacity={headerOpacity}
            />
          </Flex>
        </CopyToClipboard>
      </Header>

      <BodyWrapper
        ref={parentRef}
        id="inplam_template"
        style={
          {
            padding: 0,
            paddingBottom: 64,
            minHeight: '100vh',
            marginTop: '-52px',
          }
        }
      >
        {
          !artistInfo?.isDefaultFanSpot ? (
            <Flex
              type={'column'}
              style={
                {
                  height: imageHeight || '420px',
                  position: 'relative',
                  width: '100%',
                  overflow: 'hidden',
                }
              }
              justify={'flex-end'}
            >
              <img
                src={`${STORAGE_URL}${artistInfo?.image512Path}`}
                ref={imgContainerRef}
                alt="background"
                style={
                  {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: 'auto',
                    zIndex: 0,
                  }
                }
                onLoad={
                  e => {
                    setImageHeight(e.currentTarget.offsetHeight)
                  }
                }
              />
              <Flex
                type={'column'}
                style={
                  {
                    height: '100%',
                    justifyContent: 'flex-end',
                    background:
                  'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 1.1%, rgba(255, 255, 255, 0.08) 55.34%, rgba(255, 255, 255, 0.80) 81.37%, #FFF 100%)',
                    zIndex: 0,
                  }
                }
              >
                {
artistInfo?.isReleasedNewTrack && (
                    <NewTrackContainer
                      onClick={
                        () => {
                          history.push(
                            `/album/${artistInfo?.trackInfo?.albumInfo?._id}`,
                          )
                        }
                      }
                    >
                      <img
                        src={albums_fill_24px}
                        style={
                          {
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                          }
                        }
                        alt="album"
                      />
                      <Caption1 type={'Regular'} color={'#fff'}>
                    새 음악 발매
                      </Caption1>
                      <img
                        src={arrow_right_white_img}
                        style={{ width: '16px', height: '16px' }}
                        alt="move_icon"
                      />
                    </NewTrackContainer>
                  )
                }
                <ArtistInfoContainer>
                  {
artistFanSpotPointInfo?.artistFanSpotPointInfo?.rank && (
                      <H4>
                        {
                          `${
                      artistFanSpotPointInfo?.artistFanSpotPointInfo?.rank
                        ? convertToPricingComma(
                            artistFanSpotPointInfo?.artistFanSpotPointInfo
                              ?.rank,
                        )
                        : '-'
                          }위`
                        }
                      </H4>
                    )
                  }
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ width: '100%' }}
                  >
                    <Flex
                      align={'center'}
                      style={
                        {
                          gap: '4px',
                          marginBottom: '10px',
                          cursor: !artistInfo?.isDefaultFanSpot
                            ? 'pointer'
                            : 'auto',
                        }
                      }
                      onClick={
                        () => {
                          if (!artistInfo?.isDefaultFanSpot) {
                            history.push(`/artist/${artistInfo?.uniqueName}`)
                          }
                        }
                      }
                    >
                      <ArtistName
                        style={{ fontSize: '28px', lineHeight: '42px' }}
                      >
                        {artistInfo?.name}
                      </ArtistName>
                      {
                        !artistInfo?.isDefaultFanSpot && (
                          <img
                            src={arrow_right_outline_24px}
                            style={{ width: '24px', height: '24px' }}
                            alt="move_icon"
                          />
                        )
                      }
                    </Flex>
                    {
artistInfo?.isInplamFavorite && (
                        <img
                          src={star_fill_24}
                          style={{ width: '32px', height: '32px' }}
                          alt="move_icon"
                        />
                      )
                    }
                  </Flex>
                  {
                    !artistInfo?.isDefaultFanSpot && (
                      <Flex style={{ gap: '8px' }}>
                        <Flex type="column" style={{ gap: 2, width: '81px' }}>
                          <Caption1 type="Regular" align="left">
                        팬스팟 점수
                          </Caption1>
                          <H6 align="left" type="Medium">
                            {
artistFanSpotPointInfo?.artistFanSpotPointInfo?.point
  ? convertToPricingComma(
                              artistFanSpotPointInfo?.artistFanSpotPointInfo
                                ?.point,
  )
  : '-'
                            }
                          </H6>
                        </Flex>

                        <Flex type="column" style={{ gap: 2, width: '81px' }}>
                          <Caption1 type="Regular" align="left">
                        나의 점수
                          </Caption1>
                          <H6 align="left" type="Medium">
                            {
artistFanSpotPointInfo?.artistUserFanSpotPointInfo
  ?.point
  ? convertToPricingComma(
                              artistFanSpotPointInfo?.artistUserFanSpotPointInfo
                                ?.point,
  )
  : '-'
                            }
                          </H6>
                        </Flex>
                      </Flex>
                    )
                  }
                </ArtistInfoContainer>
              </Flex>
            </Flex>
          ) : (
            <Flex
              type={'column'}
              style={
                {
                  // height: imageHeight || '420px',
                  position: 'relative',
                  width: '100%',
                  overflow: 'hidden',
                  paddingTop: '52px',
                }
              }
              justify={'flex-end'}
            >
              <ArtistInfoContainer
                style={
                  {
                    paddingTop: 0,
                    paddingBottom: 0,
                  }
                }
              >
                <Flex
                  justify={'space-between'}
                  align={'center'}
                  style={{ width: '100%' }}
                >
                  <Flex align={'center'} style={{}}>
                    <ArtistName style={{ fontSize: '28px', lineHeight: '42px' }}>
                      {artistInfo?.name}
                    </ArtistName>
                  </Flex>
                </Flex>
              </ArtistInfoContainer>
            </Flex>
          )
        }

        <Flex
          align={'center'}
          style={
            {
              marginTop: artistInfo?.isDefaultFanSpot ? 16 : 32,
              gap: 8,
              marginBottom: 8,
              padding: '0px 20px',
            }
          }
        >
          <SearchInplamContainer align="center">
            <SearchInplamInput
              placeholder="게시글 검색"
              onChange={e => handleInput(e.target.value)}
              value={inputValue}
              onKeyDown={
                event => {
                  if (event.key === 'Enter') {
                    handleSearch()
                  }
                }
              }
            />
            {
              <Flex
                justify="center"
                align="center"
                style={{ width: 40, height: 40 }}
                onClick={
                  () => {
                    if (inputValue !== '') {
                      setInputValue('')
                    }
                  }
                }
              >
                {
                  inputValue !== '' && (
                    <img
                      src={x_btn_gray_img}
                      alt={'clear_btn'}
                      style={{ cursor: 'pointer' }}
                      width={16}
                      height={16}
                    />
                  )
                }
              </Flex>
            }
            <Flex
              justify="center"
              align="center"
              style={{ width: 40, height: 40 }}
              onClick={handleSearch}
            >
              <img
                src={search_outline_24px}
                alt={'search_btn'}
                style={{ cursor: 'pointer' }}
                width={20}
                height={20}
              />
            </Flex>
          </SearchInplamContainer>
        </Flex>

        <InPlamList
          offset={offset}
          handlePagination={handlePagination}
          artistArticleList={artistArticleList}
          artistArticleListCount={artistArticleListCount}
          sortBy={sortBy}
          handleChangeSortBy={handleChangeSortBy}
          artistInfo={artistInfo}
          handleMoveDetail={handleMoveDetail}
          history={history}
          categoryList={categoryList}
          category={category}
          handleChangeCategory={handleChangeCategory}
          needAuth={needAuth}
          currentUser={currentUser}
        />
        {isCopied && <LinkCopiedPopup />}

        {
          <FloatingButton
            style={
              {
                right: parentOffset.x,
                bottom: isWebApp
                  ? authStore?.isAppPlaybarShowing
                    ? '58px'
                    : '16px'
                  : '60px',
                pointerEvents: 'auto',
                cursor: 'pointer',
              }
            }
            onClick={
              () => {
                if (!(currentUser && currentUser._id)) {
                  needAuth()
                  return
                }

                history.push(
                  `/fanspot/add/${artistInfo?.uniqueName}/${artistInfo
                  && artistInfo._id}`,
                )
              }
            }
            isShowFloatingButton
          >
            <FloatingImg src={white_pen_fill_24px} alt={'inplamAdd-float'} />
          </FloatingButton>
        }
      </BodyWrapper>
    </div>
  ))
}

export default InPlamArtistTemplate

const Header = styled(Flex)`
  height: 52px;
  padding-right: 8px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  transition: background-color 0.3s ease; /* 배경색 부드럽게 전환 */
`

const HeaderTitle = styled(Flex)`
  color: #000;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(0);
`

const ArtistInfoContainer = styled(Flex)`
  display: flex;
  padding: 20px 20px 16px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`

const FromArtist = styled(Flex)`
  font-size: 12px;
  align-items: center;
  padding: 7px 10px;
  background-color: ${colors.whitef4};
  color: ${colors.light_pink};
  cursor: pointer;
  width: 88px;
  box-sizing: border-box;
  height: 32px;

  ${props =>
    props.isFromArtist === 'artist'
    && css`
      background-color: ${colors.black};
      color: ${colors.white};
    `}
`

/* popular List */
const ListBox = styled(Flex)`
  margin: 10px 0px;
  flex-direction: column;
  padding: 14px 16px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
`

const PopularInPlamList = styled(Flex)`
  flex-direction: column;
  /* height: 90px; */
  height: fit-content;
  max-height: 90px;
  overflow: auto;
`

const InPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  min-height: 30px;
  align-items: center;
  box-sizing: border-box;
`

const TitleText = styled(Caption1)`
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 100%;
`
const ArtistName = styled(H2)`
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  width: 100%;
`

const CommentCountText = styled(Caption1)`
  font-family: Pretendard-Regular;
  margin-left: 5px;
  min-width: 48px;
  max-width: 48px;
  padding: 3px 9px;
  border: solid 1px ${colors.light_pink};
  border-radius: 12px;
  height: 24px;
  box-sizing: border-box;
  margin-left: 7px;
  line-height: 1.35;
`

/* popular List end */
const SearchInplamContainer = styled(Flex)`
  width: 100%;
  height: 40px;
  /* padding: 6px 16px; */

  border: none !important;
  background-color: #f4f4f4;
  /* font-size: 16px; */
  border-radius: 8px;
  padding: 6px 0px 6px 16px;

  box-sizing: border-box;
`

const SearchInplamInput = styled(Input)`
  display: flex;
  /* width: 100%; */
  flex: 1;
  height: 40px;

  border: none !important;
  background-color: transparent;
  font-size: 12px;
  padding: 0;
  font-family: Pretendard-Regular;

  box-sizing: border-box;
`

const PrevButton = styled.img`
  width: 32px;
  height: 32px;

  filter: ${props =>
    props.isDarkBackground && props.headerOpacity < 0.2
      ? `invert(1)`
      : `invert(0)`};
`

const NewTrackContainer = styled.div`
  display: flex;
  width: fit-content;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(
    --New-Music,
    linear-gradient(90deg, #ff2c2c 0%, #ff5722 100%)
  );
  margin-left: 20px;
  cursor: pointer;
`
const ShareButton = styled.img`
  width: 24px;
  height: 24px;
  filter: ${props =>
    props.isDarkBackground && props.headerOpacity < 0.2
      ? `invert(1)`
      : `invert(0)`};
`
const FloatingButton = styled(Flex)`
  position: fixed;
  z-index: 500;
  transform: translateX(-50%); /* 부모 기준 중앙 정렬 */
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: var(--color-black-solid, #ff5722);
  box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.08);
  opacity: ${props => props.isShowFloatingButton};
  transition: opacity 0.3s ease;
`
const FloatingImg = styled.img`
  filter: invert(0);
  width: 24px;
  height: 24px;
`

import {
  AppDownloadPopup,
  BurgerMenu,
  FanspotListBottomModal,
  HomeEventBannerList,
  LoginPopup,
  NoticePopup,
  ProgressingRewardList,
} from '@components/organisms'
import { BodyWrapper, Caption1, Flex, Input } from '@components/atoms'

import { Observer, observer } from 'mobx-react'
import { getIsWebApp } from '@utils/postMessage'
import React, { useEffect, useRef, useState } from 'react'

import LikeArtistList from '@components/organisms/List/LikeArtistList'
import { useScrollRestoration, useStore } from '@utils/hooks'
import HomeArticleList from '@components/organisms/List/HomeArticleList'
import styled from 'styled-components'
import {
  fluent_megaphone_12_filled,
  search_outline_24px,
  white_pen_fill_24px,
  x_btn_gray_img,
} from '@images/index'
import HomeInPlamListItem from '@components/molecules/box/HomeInPlamListItem'
import { pixelTrack } from '@utils/facebook/pixel'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { SegmentedControl, TabControl } from '@components/molecules'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'

const HomeTemplate = ({
  noticePopupList,
  currentUser,
  rewardList,
  fetchRewardList,
  articleList,
  articleListCount,
  myFanSpotList,
  history,
  bannerList,
  notice,
  handlePagination,
  offset,
  handleChangeSortBy,
  sortBy,
  handleChangeActiveTap,
  activeTab,
  contentRef,
  fetchTotalInplamList,
  isFinish,
  searchWord,
  handleChangeSearchWord,
}) => {
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false) // 로그인 상태
  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const [showDownloadPopup, setShowDownloadPopup] = useState(false) // 앱 다운로드 팝업
  const [showNoticePopup, setShowNoticePopup] = useState(false) // 공지사항 팝업
  const { authStore, loadingStore, articleStore } = useStore()
  const { isInitialLoading, isLoading } = loadingStore

  const [isFanSpotModalOpen, setIsFanSpotModalOpen] = useState(false)
  const [isHaveEventBanner, setIsHaveEventBanner] = useState(false)

  const parentRef = useRef(null)
  const [parentOffset, setParentOffset] = useState({ left: 0, width: 0 })

  const [inputValue, setInputValue] = useState(searchWord ? searchWord : '') // view 검색어

  const isWebApp = getIsWebApp({ navigator })

  useEffect(() => {
    const updateOffset = () => {
      if (parentRef.current) {
        const rect = parentRef.current.getBoundingClientRect()

        setParentOffset(rect)
      }
    }

    // 초기 위치 설정
    updateOffset()

    // 창 크기 변경 시 위치 업데이트
    window.addEventListener('resize', updateOffset)
    return () => {
      window.removeEventListener('resize', updateOffset)
    }
  }, [])

  const handleMoveDetail = item => {
    if (!item?._id) return

    history.push({
      pathname: `/fanspot/${item?.isNotice ? 'free-spot' : item?.uniqueName}/${
        item?._id
      }`,
      search: `?offset=${offset}&sortBy=${sortBy}&category=${item?.articleCategoryId}`,
      state: { fromActivity: 'all' },
    })
  }

  useEffect(() => {
    if (noticePopupList?.length > 0 && !isWebApp) {
      setShowNoticePopup(true)
    }
  }, [noticePopupList])

  const countOffset = () => {
    handlePagination(offset + 1)
  }

  const categoryData = [
    {
      label: '핫스팟',
      value: 'popular',
      ref: useRef(),
    },
    {
      label: '팬스팟',
      value: 'free',
      ref: useRef(),
    },
    // {
    //   label: '정보/소식',
    //   value: 'info',
    //   ref: useRef(),
    // },
    {
      label: '공지사항',
      value: 'notice',
      ref: useRef(),
    },
  ]

  const sortByData = [
    {
      label: '최신',
      value: 'createdAt',
      ref: useRef(),
    },
    {
      label: '인기',
      value: 'popularity',
      ref: useRef(),
    },
  ]

  useScrollRestoration(contentRef)

  useEffect(() => {
    const handleScroll = async () => {
      if (
        contentRef.current
        && contentRef.current.scrollTop + contentRef.current.clientHeight
          >= contentRef.current.scrollHeight - 200
        && !isLoading
        && !isFinish
      ) {
        countOffset()
      }
    }

    const scroller = contentRef.current
    if (scroller) {
      scroller.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scroller) {
        scroller.removeEventListener('scroll', handleScroll)
      }
    }
  }, [isLoading, isFinish])

  useEffect(() => {
    const result =
      bannerList?.findIndex(val => val?.typeList?.includes('EVENT_BANNER')) > -1

    setIsHaveEventBanner(result)
  }, [bannerList])

  const handleInput = value => {
    setInputValue(value)
  }
  const handleSearch = () => {
    if (inputValue) {
      handleChangeSearchWord(inputValue)
    }
    else {
      handleChangeSearchWord(null)
    }
  }

  return (
    <div ref={contentRef} style={{ height: '100vh', overflow: 'auto' }}>
      <BurgerMenu isHome isOpen={isOpen} setIsOpen={setIsOpen} />
      <BodyWrapper
        ref={parentRef}
        style={
          {
            background: '#fff',
            padding: '56px 0',
            overflow: 'visible',
          }
        }
      >
        {/* {
          <Observer>
            {
              () => {
                const isMyFanspotList =
                myFanSpotList?.findIndex(val => val?.isInplamFavorite) > -1

                const dataList = myFanSpotList

                if (myFanSpotList?.length > 0 || isInitialLoading) {
                  return (
                    <LikeArtistList
                      isMyFanspotList={isMyFanspotList}
                      list={dataList}
                      isLoading={isInitialLoading}
                    />
                  )
                }
                return null
              }
            }
          </Observer>
        } */}
        <Flex
          type={'column'}
          style={{ position: 'sticky', top: '0px', zIndex: 50 }}
        >
          <Flex
            type={'column'}
            style={
              {
                marginBottom: '12px',
              }
            }
          >
            <TabControl
              name="category"
              callback={
                val => {
                  handleChangeActiveTap(val)
                }
              }
              defaultIndex={
                categoryData?.findIndex(val => val.value === activeTab) === -1
                  ? 0
                  : categoryData?.findIndex(val => val.value === activeTab)
              }
              segments={categoryData}
              isNoFlex
            />
          </Flex>
        </Flex>
        {/* {
          activeTab !== 'popular' && activeTab !== 'notice' && (
            <Flex
              type={'column'}
              style={{ padding: '0 20px', marginBottom: '16px' }}
            >
              <SegmentedControl
                name="sortBy"
                callback={
                  val => {
                    handleChangeSortBy(val)
                  }
                }
                defaultIndex={sortByData?.findIndex(val => val.value === sortBy)}
                segments={sortByData}
              />
            </Flex>
          )
        } */}
        {
          activeTab === 'free' && (
            <Flex
              align={'center'}
              style={
                {
                  gap: 8,
                  marginBottom: 15,
                  padding: '0px 20px',
                }
              }
            >
              <SearchInplamContainer align="center">
                <SearchInplamInput
                  placeholder="게시글 검색"
                  onChange={e => handleInput(e.target.value)}
                  value={inputValue}
                  onKeyDown={
                    event => {
                      if (event.key === 'Enter') {
                        handleSearch()
                      }
                    }
                  }
                />
                {
                  <Flex
                    justify="center"
                    align="center"
                    style={{ width: 40, height: 40 }}
                    onClick={
                      () => {
                        if (inputValue !== '') {
                          setInputValue('')
                        }
                      }
                    }
                  >
                    {
                      inputValue !== '' && (
                        <img
                          src={x_btn_gray_img}
                          alt={'clear_btn'}
                          style={{ cursor: 'pointer' }}
                          width={16}
                          height={16}
                        />
                      )
                    }
                  </Flex>
                }
                <Flex
                  justify="center"
                  align="center"
                  style={{ width: 40, height: 40 }}
                  onClick={handleSearch}
                >
                  <img
                    src={search_outline_24px}
                    alt={'search_btn'}
                    style={{ cursor: 'pointer' }}
                    width={20}
                    height={20}
                  />
                </Flex>
              </SearchInplamContainer>
            </Flex>
          )
        }
        {
          (activeTab === 'popular' || activeTab === 'free') && !!notice?.[0] && (
            <Flex
              align={'center'}
              style={
                {
                  padding: '10px 8px',
                  borderRadius: '4px',
                  background: '#f7f7f7',
                  cursor: 'pointer',
                  margin: '0 20px',
                  marginBottom: '18px',
                }
              }
              onClick={
                () => {
                  pixelTrack(PIXEL_EVENTS.홈.공지, {})
                  history.push(
                    `/fanspot/free-spot/${notice?.[0]?._id}?offset=0&sortBy=createdAt&category=${notice?.[0]?.articleCategoryId}`,
                  )
                }
              }
            >
              <img
                src={fluent_megaphone_12_filled}
                style={{ width: '12px', height: '12px', marginRight: '6px' }}
              />

              <NoticeText type={'Regular'} style={{}}>
                {notice?.[0]?.title}
              </NoticeText>
            </Flex>
          )
        }
        {
          isHaveEventBanner && bannerList?.length > 0 && (
            <Flex
              type={'column'}
              style={{ padding: '0 0px 0 20px', margin: '-10px 0 16px 0' }}
            >
              <HomeEventBannerList
                bannerList={bannerList}
                setShowDownloadPopup={setShowDownloadPopup}
              />
            </Flex>
          )
        }
        <Flex type={'column'} style={{ gap: '24px', padding: '0 20px' }}>
          {
            articleList
            && articleList.map((item, index) => {
              return (
                <>
                  <HomeInPlamListItem
                    item={item}
                    key={item._id}
                    onClick={handleMoveDetail}
                    category={activeTab}
                  />
                  {
                    ((articleListCount < 3
                    && index === articleList?.length - 1)
                    || index === 1
                    || index % 10 === 1)
                    && rewardList?.length > 0 && (
                      <ProgressingRewardList
                        progressingRewardList={rewardList}
                        fetchRewardList={fetchRewardList}
                        setShowDownloadPopup={setShowDownloadPopup}
                        showIndex={index / 10}
                      />
                    )
                  }

                  {
                    !isHaveEventBanner
                    && ((articleListCount < 7
                      && index === articleList?.length - 1)
                      || index === 6)
                    && bannerList?.length > 0 && (
                      <HomeEventBannerList
                        bannerList={bannerList}
                        setShowDownloadPopup={setShowDownloadPopup}
                      />
                    )
                  }
                </>
              )
            })
          }
        </Flex>
      </BodyWrapper>

      {
        isShowLoginPopup && (
          <LoginPopup handleClose={() => setIsShowLoginPopup(false)} />
        )
      }
      {
        showDownloadPopup && (
          <AppDownloadPopup handleClose={() => setShowDownloadPopup(false)} />
        )
      }
      {
        showNoticePopup && (
          <NoticePopup
            noticePopupList={noticePopupList}
            handleClose={() => setShowNoticePopup(false)}
            type={'notice'}
          />
        )
      }
      <FanspotListBottomModal
        isModalOpen={isFanSpotModalOpen}
        setIsFanSpotModalOpen={setIsFanSpotModalOpen}
        history={history}
        isHome
      />

      {
        <FloatingButton
          style={
            {
              right: parentOffset.x,
              bottom: isWebApp
                ? authStore?.isAppPlaybarShowing
                  ? '58px'
                  : '16px'
                : '60px',
              pointerEvents: 'auto',
              cursor: 'pointer',
            }
          }
          onClick={
            async () => {
              if (!(currentUser && currentUser._id)) {
                setIsShowLoginPopup(true)
                return
              }

              const result = await fetchTotalInplamList({
                offset: 0,
                isDefaultFanSpot: 1,
                noNeedStore: true,
              })

              history.push(
                `/fanspot/add/${result?.[0]?.uniqueName}/${result?.[0]?._id}`,
              )

            // setIsFanSpotModalOpen(true)
            }
          }
          isShowFloatingButton
        >
          <FloatingImg src={white_pen_fill_24px} alt={'inplamAdd-float'} />
        </FloatingButton>
      }
    </div>
  )
}

export default observer(HomeTemplate)

const FloatingButton = styled(Flex)`
  position: fixed;
  z-index: 500;
  transform: translateX(-50%); /* 부모 기준 중앙 정렬 */
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: var(--color-black-solid, #ff5722);
  box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.08);
  opacity: ${props => props.isShowFloatingButton};
  transition: opacity 0.3s ease;
`
const FloatingImg = styled.img`
  filter: invert(0);
  width: 24px;
  height: 24px;
`
const NoticeText = styled(Caption1)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`
const SearchInplamContainer = styled(Flex)`
  width: 100%;
  height: 40px;
  /* padding: 6px 16px; */

  border: none !important;
  background-color: #f4f4f4;
  /* font-size: 16px; */
  border-radius: 8px;
  padding: 6px 0px 6px 16px;

  box-sizing: border-box;
`

const SearchInplamInput = styled(Input)`
  display: flex;
  /* width: 100%; */
  flex: 1;
  height: 40px;

  border: none !important;
  background-color: transparent;
  font-size: 12px;
  padding: 0;
  font-family: Pretendard-Regular;

  box-sizing: border-box;
`

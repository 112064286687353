import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import {
  Flex,
  BodyWrapper,
  H6,
  Caption1,
  Caption2,
  H5,
} from '@components/atoms'
import { comment_img, edit_img_black, share_img_black } from '@images/'
import {
  BurgerMenu,
  CommentCard,
  FanspotScoreCard,
  FanspotScoreRankingList,
  InPlamList,
  LinkCopiedPopup,
  LoginPopup,
  OkCancelPopup,
} from '@components/organisms'
import { colors } from '@colors/'
import { useObserver } from 'mobx-react'
import { converseCommentUnitEng, convertToPricingComma } from '@utils/format'
import { useHistory } from 'react-router-dom'
import { action } from 'mobx'
import { Helmet } from 'react-helmet'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { DOMAIN_URL } from '@consts/'
import { back_black, goback_img, no_next_page_img } from '@images/index'

interface FanspotScoreTemplateProps {
  currentUser: any;
  isLike: boolean;
  uniqueName: string;
  artistInfo: object;
  offset: Number;
  setOffset: Function;
  sortBy: string;
  setSortBy: Function;
  isFromArtist: string;
  toggleFromArtist: Function;
  artistArticleList: Array<any>;
  popularArtistArticleList: Array<any>;
  artistArticleListCount: Number;
  createComment: Function;
  removeComment: Function;
  _fetchCommentList: Function;
  updateCommentLike: Function;
  updateLike: Function;
  showLoginPopup: boolean;
  setShowLoginPopup: Function;
  currentPath: any;
}

const FanspotScoreTemplate = ({
  uniqueName,
  offset,
  handlePagination,
  sortBy,
  handleChangeSortBy,
  artistInfo,
  artistArticleList,
  artistArticleListCount,
  artistFanSpotPointInfo,
  artistFanSpotPointRankList,
}: FanspotScoreTemplateProps) => {
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false) // 햄버거
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const linkCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
  }

  const handleMoveDetail = id => {
    if (!id) return
    history.push(
      `/fanspot/${uniqueName}/${id}?offset=${offset}&sortBy=${sortBy}`,
    )
  }

  return useObserver(() => (
    <>
      <Helmet>
        <title>{`(${artistInfo && artistInfo.name}) 팬스팟`}</title>
        <meta
          name="description"
          content="나의 소중한 아티스트와 소통하는 공간, 팬스팟"
        />
      </Helmet>

      <Header>
        <Flex style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <img
            src={back_black}
            alt="go_back"
            style={
              {
                width: '24px',
                height: '24px',
              }
            }
          />
        </Flex>
        <H5 style={{ marginLeft: '8px' }}>스코어 전체 순위</H5>
      </Header>
      <BodyWrapper
        id="inplam_template"
        style={
          {
            paddingTop: '66px',
            paddingBottom: 64,
            minHeight: '100vh',
          }
        }
      >
        <FanspotScoreCard
          artistFanspotScore={
            artistFanSpotPointInfo?.artistFanSpotPointInfo?.point
          }
          artistRank={artistFanSpotPointInfo?.artistFanSpotPointInfo?.rank}
          myFanspotScore={
            artistFanSpotPointInfo?.artistUserFanSpotPointInfo?.point
          }
          myRank={artistFanSpotPointInfo?.artistUserFanSpotPointInfo?.rank}
          isDetailPage
        />

        <FanspotScoreRankingList
          offset={offset}
          handlePagination={handlePagination}
          artistArticleList={artistArticleList}
          artistArticleListCount={artistArticleListCount}
          sortBy={sortBy}
          handleChangeSortBy={handleChangeSortBy}
          artistInfo={artistInfo}
          handleMoveDetail={handleMoveDetail}
          artistFanSpotPointRankList={artistFanSpotPointRankList}
        />
      </BodyWrapper>
    </>
  ))
}

export default FanspotScoreTemplate

const Header = styled(Flex)`
  height: 56px;
  width: 100%;
  background-color: white;
  align-items: center;
  padding: 0 16px;
  position: fixed;
  top: 0;
  z-index: 100;
`

const FromArtist = styled(Flex)`
  font-size: 12px;
  align-items: center;
  padding: 7px 10px;
  background-color: ${colors.whitef4};
  color: ${colors.light_pink};
  cursor: pointer;
  width: 88px;
  box-sizing: border-box;
  height: 32px;

  ${props =>
    props.isFromArtist === 'artist'
    && css`
      background-color: ${colors.black};
      color: ${colors.white};
    `}
`

/* popular List */
const ListBox = styled(Flex)`
  margin: 10px 0px;
  flex-direction: column;
  padding: 14px 16px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
`

const PopularInPlamList = styled(Flex)`
  flex-direction: column;
  /* height: 90px; */
  height: fit-content;
  max-height: 90px;
  overflow: auto;
`

const InPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  min-height: 30px;
  align-items: center;
  box-sizing: border-box;
`

const TitleText = styled(Caption1)`
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 100%;
`

const CommentCountText = styled(Caption1)`
  font-family: Pretendard-Regular;
  margin-left: 5px;
  min-width: 48px;
  max-width: 48px;
  padding: 3px 9px;
  border: solid 1px ${colors.light_pink};
  border-radius: 12px;
  height: 24px;
  box-sizing: border-box;
  margin-left: 7px;
  line-height: 1.35;
`

/* popular List end */

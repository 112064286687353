import { Flex } from '@components/atoms'
import { Link } from 'react-router-dom'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import React from 'react'
import { handleAlbumImgError } from '@utils/handler'
import { pixelTrack } from '@utils/facebook/pixel'
import { Typography } from '../../atoms'

const onelineStyle = {
  // display:'inline-block',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '148px',
}

const ArtistAlbumItem = ({ item, index, lang }) => {
  return (
    // <Link to={'/album/' + item._id}>
    <Link
      to={
        `/album/${
          item.customUrlInfo ? item.customUrlInfo.autoUrl : item._id
        }`
      }
      onClick={
        () => {
          pixelTrack(PIXEL_EVENTS.아티스트.앨범_아이템, {
            albumId: item._id,
            albumTitle: item.title,
          })
        }
      }
    >
      <Flex type="column" style={{ paddingBottom: '20px' }}>
        <img
          src={item && item.image512Uri}
          alt="album_img"
          style={
            {
              width: '150px',
              height: '150px',
              paddingBottom: '4px',
              borderRadius: '6px',
            }
          }
          onError={handleAlbumImgError}
        />
        <Typography type="Medium" style={onelineStyle}>
          {lang === 'ko' ? item && item.title : item?.titleEng || item?.title}
        </Typography>
        <Typography
          type="Medium"
          size="12px"
          color="#949494"
          style={onelineStyle}
        >
          {item && item.releaseArtistName && item.releaseArtistName}
        </Typography>
      </Flex>
    </Link>
  )
}

export default ArtistAlbumItem
